<template>
    <div class="login-view">
        <div class="login-container" style="height:300px">
            <div class="login-box">
                <div>
                    <h3><chillText value="delete_account" /></h3>
                    <h6><chillText value="make_a_request_to_delete_the_account" /></h6>
                </div>
                <div class="login-fields">
                    <div class="field"> 
                        <chillText value="email" />
                        <input class="field-text" v-model="email" selenium="loginEmail" type="email" edit="false" showTitle="email" />
                    </div>
                </div>
                <div class="button-box">
                    <chillButton value="request_deletion" type="primary inline" selenium="loginBtn" @on-click="request_deletion()" />
                </div>
                <div class="lang-box">
                    <div class="lang-box-content">
                        <chillText value="select_language"  />
                        <chillSelect :value="language" type="fixed" selenium="loginLanguage" :options="['en', 'de', 'fr', 'sv', 'es', 'it', 'nl']" @update="changeLang" />
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DeleteAccount',
        data() {
            return {
                email: "",
                language: localStorage.getItem('language_web')
            }
        },
        computed: {

        },
        methods: {
            async request_deletion(){
                await this.request_account_delete(this.email)
                this.$toasted.show(this.translate("delete_request_sent"), {position:"bottom-right", duration:3000, type:"success"})
            },
            changeLang(language) { this.$store.commit('changeLang', language) }
        }
    }
</script>