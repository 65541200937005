<template>
    <div :class="edit ? 'input-group-edit' : 'input-group'">
        <chillText v-if="title" class="title" :edit="edit" :value="title" type="title"  />
        
        <chillTextField v-if="edit && type === 'text'" :edit="edit" :value="value" :type="type" @update="update" />
        <chillTextField v-else-if="edit && type === 'number'" :edit="edit" :value="value" :type="type" @update="update" />
        <chillSelect v-else-if="edit && type === 'select'" :edit="edit" :value="value" :type="type" :options="options" @update="update" />
        <chillSelect v-else-if="edit && type === 'boolean'" :edit="edit" :value="value" :type="type" :options="[true, false]" @update="update" />
        <chillIconSelect v-else-if="type === 'icon_select'" :edit="edit" :value="value" :type="type" :category="category" :title="title" :options="options" @modal="modal" @update="update" />

        <chillSlider v-else-if="type === 'slider'" :edit="edit" :value="value" :type="type" :facadeItem="facadeItem" :title="title" @update="update" />

        <!-- select below will be updated later, can't handle select_multiple for the moment -->
        <chillSelect v-else-if="edit && type === 'select_multiple'" :edit="edit" :value="value" :type="type" :options="options" @update="update" />

        <chillImage v-else-if="type === 'street_view_image'" :edit="edit" :value="value" :type="type" @modal="modal" :inputKey="getImageKey()" />
        <chillImage v-else-if="type === 'image'" :edit="edit" :value="value" :type="type"  @modal="modal" :inputKey="getImageKey()"/>
        <chillImage v-else-if="type === 'image_pins_overview'" :edit="edit" :value="value" :type="type"  @modal="modal" />

        <!-- <chillText v-else-if="!edit && type === 'boolean'" :value="value" type="'boolean'"/> -->
        <chillText v-else :value="value" type="keyValue" />

    </div>
</template>

<script>
    export default {
        name: 'inputGroup',
        props: ["value", "type", "title", "edit", "options", "category", "keyLocation", "roofId", "wallId", "facadeId", "windowId", "balconyId", "facadeItem"],
        methods: {
            getImageKey(){
                if(this.keyLocation === "general" || this.keyLocation === "additional" || this.keyLocation === 'hvac') return this.keyLocation + "." + this.title
                
                // else if(this.keyLocation === "facades") return [this.title, "facades", this.wallId, this.facadeId]
                else if (this.keyLocation === "roof") return [this.title, 'roof', this.roofId]
                else if(this.keyLocation === "facades") return [this.title, "facades", this.wallId, this.facadeId]
                else if(this.keyLocation === "windows") return [this.title, "windows", this.wallId, this.facadeId, this.windowId]
                else if(this.keyLocation === "balconies") return [this.title, "balconies", this.wallId, this.facadeId, this.balconyId]
                // else if(this.keyLocation === "facades") return "walls/"+this.wallId+"/facades/"+this.facadeId+"."+this.title
                // else if(this.keyLocation === "facades") return [this.title, this.wallId, this.facadeId]
                // if(this.keyLocation) return this.keyLocation + "." + this.title
                // else if(this.roofId)
            },
            modal(image, index, component, iconType, type) {
                if(this.windowId) {
                    this.$emit("modal", image, index, component, iconType, undefined, this.wallId, this.facadeId, this.windowId, undefined)
                }
                else if(this.balconyId) {
                    this.$emit("modal", image, index, component, iconType, undefined, this.wallId, this.facadeId, undefined, this.balconyId)
                }
                else if(this.facadeId) {
                    this.$emit("modal", image, index, component, iconType, this.wallId, this.facadeId)
                }
                else if(this.roofId) {
                    this.$emit("modal", image, index, component, iconType, this.roofId)
                }
                else
                    this.$emit("modal", image, index, component, iconType, undefined, undefined, undefined, undefined, undefined, undefined, type ? type : undefined)
            },
            
            update(newValue) {
                if(this.windowId)
                    this.$emit("update", newValue, this.title, this.wallId, this.facadeId, this.windowId)
                else if(this.balconyId)
                    this.$emit("update", newValue, this.title, this.wallId, this.facadeId, this.balconyId)
                else if(this.facadeId)
                    this.$emit("update", newValue, this.title, this.wallId, this.facadeId)
                else if(this.roofId)
                    this.$emit("update", newValue, this.title, this.roofId)
                else
                    this.$emit("update", newValue, this.keyLocation, this.title)
            }
        }
    }
</script>