<template>
    <div class="all-container">
        <div class="recalculate-div">
            <button @click="start_deducting_data()">
                <chillText value="function_firebase" type="whiteText" shorten="false" />
            </button> 
        </div>
        <div v-if="fsLoadingUpdate" class="loader-row">
            <div class="loader"></div>
        </div>
        <div v-if="!fsLoadingUpdate">
            <div class="chart-container">
                <div id="chart" ref="chart"></div>
            </div>
            <div class="chart-container">
                <div id="chart_pie" ref="chart_pie"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'embodiedCarbonChart',
    props: ['series_data'],
    watch: {
        series(){
            if (this.chart) this.createChart()
        }
    },
    data(){
        return {
            allColors: {
                "glass": "#0F3A57",
                "glass_windows": "#0F3A57",
                "aluminium": "#10002B",
                "aluminium_window_frames": "#10002B",
                "pvc": "#FF930F",
                "pvc_window_frames": "#FF930F",
                "wood": "#432818",
                "wood_window_frames": "#432818",
                "wood_roof_structure": "#59341D",
                "concrete": "#2F3E46",
                "concrete_foundation": "#2F3E46",
                "concrete_floors": "#354F52",
                "concrete_screeds": "#446461",
                "concrete_columns": "#52796F",
                "concrete_beams": "#6B917E",
                "concrete_facades": "#84A98C",
                "steel": "#505050",
                "steel_foundation": "#505050",
                "steel_floors": "#808080",
                "steel_screeds": "#909090",
                "steel_columns": "#A0A0A0",
                "steel_beams": "#B0B0B0",
                "steel_facades": "#C0C0C0",
                "brick": "#842020",
                "brick_facades": "#842020",
                "brick_internal_walls": "#9E3333",
                "copper": "#7E3A06",
                "copper_electric_installations": "#7E3A06",
                "copper_piping": "#AC4F00"
            },
            chart: undefined,
            chart_pie:undefined,
            option: {
                tooltip: {
                        trigger: "item",
                        formatter: function (params) {
                            if(params.percent){
                                return `${params.name} : ${params.value} kg CO₂e ( ${params.percent}% )`

                            }else{
                                return `${params.seriesName} : ${params.value} kg CO₂e`
                            }
                        }
                    },
                legend: {
                    orient: 'horizontal',
                    top: 420,
                },
                grid: {
                    left: '3%',
                    right: '20%',
                    bottom: '30%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [this.translate("embodied_carbon")]
                    }
                ],
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} kg CO₂e'
                    }
                },
                series: [
                ]
            },
            option_pie: {
                tooltip: {
                        trigger: "item",
                        formatter: function (params) {
                            if(params.percent){
                                return `${params.name} : ${params.value} kg CO₂e ( ${params.percent}% )`

                            }else{
                                return `${params.seriesName} : ${params.value} kg CO₂e`
                            }
                        }
                    },
                legend: {
                    orient: 'vertical',
                    right: 40,
                    top: "center"
                },
                grid: {
                    left: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                series: [
                    {
                        name: "embodied_carbon",
                        type: "pie",
                        radius: "50%",
                        data: [
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ]
            }
        }
    },
    methods: {
        createChart(){
            console.log('create data')
            var chartDom = this.$refs.chart
            var chartDomPie = this.$refs.chart_pie
            if(this.chart) {this.chart.dispose(); this.chart = undefined}
            if(this.chart_pie) {this.chart_pie.dispose(); this.chart_pie = undefined}
            this.chart = echarts.init(chartDom)
            this.chart_pie = echarts.init(chartDomPie)

            if(this.$props.series_data.length === 0){
                this.option && this.chart.setOption(this.option);
                return
            }

            let vm = this;
            let pie_data_obj = {};
            let pie_data = [];
            this.option.series = [];
            this.$props.series_data.forEach(obj => {
                let cat = obj.name.split("_")[0]
                if(pie_data_obj[cat]){
                    pie_data_obj[cat] += obj.value
                }else{
                    pie_data_obj[cat] = obj.value
                }
            })
            pie_data = Object.entries(pie_data_obj).map(([name, value]) => ({name,value}));
            // pie_data = pie_data.map(a => {let b = a; b.itemStyle = vm.get_item_style(b.name); b.name=vm.translate(b.name+"_embodied_carbon"); return b})
            pie_data = pie_data.filter(o => o.value > 0).map(a => {let b = a; b.itemStyle = vm.get_item_style(b.name); b.name=vm.translate(b.name+"_embodied_carbon"); return b})

            this.option_pie.series[0].data = JSON.parse(JSON.stringify(pie_data));

            for(let i = 0; i< this.$props.series_data.length; i++){
                if(this.$props.series_data[i].value > 0){
                    this.option.series.push({
                        itemStyle: vm.get_item_style(this.$props.series_data[i].name),
                        name: this.translate(this.$props.series_data[i].name+"_embodied_carbon"),
                        type: 'bar',
                        stack: 'x',
                        data: [this.$props.series_data[i].value],
                        barWidth: '50%',
                    })
                }
            }

            this.option && this.chart.setOption(this.option);
            this.option_pie && this.chart_pie.setOption(this.option_pie)
        },
        start_deducting_data() {
            this.deduct_data_emodied(this.$route.params.id)
                .then(() => {
                    this.createChart();
                })
                .catch((error) => {
                console.error('Error deducting data:', error);
            });
        },
         get_item_style(name){
            let color = this.allColors[name] ?? "#fff000";
            return {color: color}
        },
        translate(str){
            let language = this.$store.state.lang
                if (typeof str == "number") {
                    return toString(str)
                }
                if(!language){
                    return str
                }
                else if(language === "dev"){
                    return str
                }
                else {
                    let strLower = str.toLowerCase()
                    if ( this.locales[language]['ba_web'][strLower] && this.locales[language][strLower] !== "" )
                        str = this.locales[language]['ba_web'][strLower]
                }
                return str
        }
    },
    mounted(){
        this.createChart()
    },
}
</script>

<style lang="scss" scoped>
#chart {
    width:100%;
    height:100%;
}

#chart_pie {
    width:100%;
    height:100%;
}

.all-container {
    margin-top:2rem;
    // margin-left: 2rem;
    width:100%;
    height:1100px;
    display: flex;
    flex-direction: column;
}

.chart-container {
    // border-style: dashed;
    // margin-top:2rem;
    // width:100%;
    height:550px;
    position:relative;
    // .info-container {
    //     position:absolute;
    //     top:15%;
    //     left:15%;
    //     height:100px;
    // }
}

.recalculate-div {
    display: flex;
    justify-content: end;
    align-items: end;
}

button {
    background-color:#F6911B;
    border-radius: 4px;
    border: 1px solid #F6911B;
    color: white;
    font-size: 16px;
    letter-spacing: 0.7px;
    margin: -10px 20px;
    padding: 4px 10px;
    text-align: center;
    width: 25%;
}

button:hover {
    cursor: pointer;
}

button:active {
    transform: scale(0.98);
}
</style>