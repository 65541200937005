<template>
    <div class="sidebar-right">
        <div class="border-bar">
            <div class="sidebar-button" :id="selenium" @click="$emit('toogle-edit')">
                <chillButton icon="edit" :value="edit ? 'edit_mode' : 'edit'" type="icon" class="button right-side" />
            </div>
        </div>
        <div v-if="$route.name === 'buildings'" class="buildings-help-text">
            <chillText value="info_text_buildings_list" />
        </div>
        <!-- <div v-if="$route.name === 'building' && activeTab ==='embodied_carbon'" class="buildings-help-text">
            <chillText value="info_text_building_embodied_carbon" />
        </div> -->
        <!-- <div class="sidebar-content-right" v-if="activeTab ==='walls_title'">
            <div class="sidebar-information-top">
                <span>maintance</span>
                <div class="information-top-text"></div>
            </div>
            <div class="sidebar-information-middle">
                <span>history</span>
            </div>
            <div class="sidebar-information-bottom">
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: 'sidebarRight',
        props: ["edit", "selenium", "activeTab"],
        data() {
            return {
            }
        }
    }
</script>