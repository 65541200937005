<template>
<div class="image-container">
	<div class="img-round" v-if="type === 'header-or-breadcrums'">
		<div class="img-round-content" v-if="value && type === 'header-or-breadcrums'">
            <img class="round-small" :src="imageUrlList[0]" alt="front_image">
		</div>
		<div class="no-img" v-else >
			<chillText value="no_image" />
		</div>
	</div>
    <div v-else-if="type === 'icon_select'" class="img-normal-icon_select">
        <div class="img-normal-content" v-if="value">
            <img class="full-square" :src="this.value" alt="image" >
		</div>
		<div class="no-img" v-else >
			<chillText value="no_image" type="title" />
		</div>
    </div>
    <div class="img-normal" v-else-if="type === 'thumbnail'" :key="imageUrlList?.[0] ?? 'no_image'" >
        <div class="img-small-content" v-if="value">
            <img 
                class="small-rect"
                :src="imageUrlList[0]" alt="image"
            >
		</div>
		<div class="img-small-content" v-else >
            <img 
                class="small-rect-scale-down"
                :src="require(`@/assets/roof/open-gableHQ.png`)" alt="image"
            >
		</div>
    </div>
    <div class="img-normal" v-else-if="type === 'image' || type === 'street_view_image'" >
        <div class="img-normal-content" style="display: flex; flex-direction: column;" v-if="value">
            <img 
                class="full-square"
                :src="imageUrlList[0]" alt="image"
                v-on:click="$emit('modal', imageUrlList, 0, 'viewerImages', edit)"
            >
            <chillRemoveButton v-if="edit" 
                :key="image+i" 
                value="delete_filled"
                class="delete-button"
                :validation="true"
                @on-click="edit ? openRemoveImageModal(0) : ''"  
            />
		</div>
        <div class="upload-image"  v-if="edit">
            <input ref="inputFile" style="margin: 5px 0px;" type="file" name="Upload" accept="image/*" multiple @change="storeFile($event)">
                <chillButton
                    v-if="fsProgress === null"
                    type="primary"
                    :validation="fileToUpload ? true : false"
                    value="upload_file"
                    @on-click="fileToUpload ? uploadFile() : ''"
                />
            <!-- <span v-if="fsProgress !== null" class="progress-procent">
                <chillText value="uploaded" /> <chillText :value="Math.floor(fsProgress)+'%'" />
            </span> -->
        </div>
        <div v-if="value"></div>
        <div class="no-img" v-else >
            <chillText value="no_image" type="title" />
        </div>
        <chillText type="title" v-if="imageUrlList.length > 1" value="more_photos" />
        <div class="img-small-content" v-if="imageUrlList.length > 1">
            <div v-for="(image, i) of imageUrlList.slice(1)" :key="image+i" class="single-image-div">
                <img 
                    :class="'small-square-'+i"
                    class="small-square"
                    :key="image+i" :src="image" alt="image"
                    v-on:click="$emit('modal', imageUrlList, i+1, 'viewerImages')"
                >

                <chillRemoveButton
                    v-if="edit" 
                    :key="image + i" 
                    value="delete_filled"
                    class="delete-button"
                    :validation="true"
                    @on-click="edit ? openRemoveImageModal(i + 1) : ''"  
                />
            </div>
        </div>
    </div>
    <div v-for="(parent, i) in parentImages" :key="i" class="img-overview-pins-box">
        <div class="img-overview">
            <img 
                class="full-square"
                :src="parent.data.uri" alt="image"
                v-on:click="$emit('modal', getFacadeImages(parent.parentId), 0, 'viewerImagePins', fspinsData)"
            >
        </div>
        <div class="img-pins" v-if="pinImages(parent).length > 0">
            <div
                class="test-pins"
                v-for="(pin, k) in pinImages(parent).slice(0, 3)"
                :key="k"
            >
                <chillText class="pin-number" :class="'pin-number-'+k" :value="k+1" />
                <img
                    :class="'small-square-'+k"
                    class="small-square"
                    :src="pin.uri"
                    alt="no image"
                >
                    <!-- v-on:click="$emit('modal', pinImageList, k+1, 'viewerImages')" -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "chillImage",
        props: ["type", "edit","value", "inputKey"],
        data() {
            return {
                imageUrlList: [],
                uriList: [],
                pinImageList: [],
                pinimageLocation: [],
                fileToUpload: null
            }
        },
        computed: {
            parentImages() {
                if(this.type === 'image_pins_overview')
                    return this.pinImageList.parent
                else
                    return false
            },
        },
        watch: {
            value: async function() {
                // console.log(newValue, oldValue)
                // // if(!newValue && oldValue){
                // //     this.value = oldValue
                // // }
                if(this.value && this.type !== 'icon_select') { this.getImageUrlList() }

                // if(!oldValue || ( (oldValue && newValue) ) ) {
                //     if(this.value && this.type !== 'icon_select') { this.getImageUrlList() }
                // }
            },
            uriList() {
                const uriFilter = this.uriList.filter(x => !this.imageUrlList.includes(x) ? x : null )
                this.imageUrlList = [...JSON.parse(JSON.stringify(this.imageUrlList)), ...JSON.parse(JSON.stringify(uriFilter))]
            }
        },
        methods: {
            openRemoveImageModal(index) {
                const dataToDelete = {
                    imageUrlList: this.imageUrlList,
                    toDeleteID: this.value?.[index],
                    index: index,
                    inputKey: this.inputKey}

                this.$emit('modal', dataToDelete, 'image', 'viewerDelete', '', 'small')
            },
            storeFile(event) {
                this.fileToUpload = Array.from(event.target.files);
            },
            async uploadFile() {
                for (let i = 0; i < this.fileToUpload.length; i++) {
                    console.log(this.value)
                    await this.uploadImage(this.fileToUpload[i], this.inputKey, this.value);
                }
                // this.fileToUpload = null; 
                this.$refs.inputFile.value = ''; 
            },
            getFacadeImages(facadeImageId) {
                let imageUri = {parent: [], pins: []}
                this.pinImageList.parent.forEach(images => {
                    if(images.parentId === facadeImageId) {
                        imageUri.parent.push(images.data)                            
                    }
                })
                this.pinImageList.pins.forEach(pin => {
                    if(pin.parent === facadeImageId) {
                        imageUri.pins.push(pin)
                    }
                })
                return imageUri
            },
            pinImages(parentId) {
                if(this.type === 'image_pins_overview') {
                    return this.pinImageList.pins.filter(pin => {
                        return pin.parent === parentId.parentId ? pin : null
                    })
                }
                else
                    return false
            },
            async getImageUrlList() {
                this.uriList = await this.requestImages(this.value)
            },
            async getImagePins() {
                this.pinImageList = await this.requestPinImages(this.value)
            },
            async removeImage(index) {
                const idToRemove = this.value?.[index]
                await this.removeImageFromDB(idToRemove, this.inputKey)
                this.imageUrlList.splice(index, 1)
            }
       },
        async mounted() {
            if(this.value && this.type === 'image_pins_overview') { this.getImagePins() }
            else if(this.value && this.type !== 'icon_select') { await this.getImageUrlList() }
        }
    }
</script>

<style>
    .single-image-div{
        position: relative;
    }

    .delete-button{
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 0;
        z-index: 1;
        width: 22px;
        height: 22px;
        color: red;
        font-size: 22px;
        background-color: white;
        border-radius: 100%;
    }
</style>