<template>
<div class="viewer-delete">
    <div class="body">
        <div class="" >
            <chillText value="confirm_to_delete" />
            <chillButton type="primary inline" value="confirm_delete" @on-click="deleteDocument()" />
        </div>
    </div>
    <div class="footer"></div>
</div>
</template>

<script>
    export default {
        name: "viewerDelete",
        props: [ "value" , "viewerIndex"],
        data() {
            return { }
        },
        methods: {
            async deleteDocument() {
                if(this.viewerIndex === 'roof') {
                    this.removeRoof(this.$route.params.id, this.value.roofId)
                }
                else if(this.viewerIndex === 'document') {
                    this.removeDocument(this.$route.params.id, this.value.documentId)
                }
                else if(this.viewerIndex === 'wall') {
                    this.removeFacade(this.$route.params.id, this.value.wallId, this.value.facadeId )
                }
                else if(this.viewerIndex === 'window') {
                    this.removeWindow(this.$route.params.id, this.value.wallId, this.value.facadeId, this.value.windowId )
                }
                else if(this.viewerIndex === 'balcony') {
                    this.removeBalcony(this.$route.params.id, this.value.wallId, this.value.facadeId, this.value.balconyId )
                } else if (this.viewerIndex === 'image') {
                    await this.removeImageFromDB(this.value?.toDeleteID, this.value?.inputKey)
                    this.value?.imageUrlList.splice(this.value?.index, 1)
                }
                this.$emit("close")
            },
        },
        mounted() { }
    }
</script>