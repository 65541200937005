import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export const readBuilding = {

	data() {
		return {
            client: localStorage.getItem('client'),
            fsbuildingData: {},
            fsbuildingRoofs: [],
            fsbuildingDocuments: [],
            fsbuildingDataWalls: [],
            fsbuildingDataFacades: [],
            fsbuildingsData: [],
            fsrecentBuildings: [],
            fsfacadeWindows: [],
            fsBalconies: [],
            fsIcons: [],
            fspinsData: [],
            reqistredBuildings: 0,
            fsLoadingRead: false,
            // snapshotListener
            recentDataListener: null,
            numberBuildingsListener: null,
            buildingListener: false,
            // snapshotListener that not get closed
            buildingWallsListener: null,
            buildingsFacadeListener: null,
            facadeWindowsListener: null,
            balconiesListener: null,
            requestBuildingListener: null
		}
    },
	methods: {
        closeSnapshotHome() {
            // this.recentDataListener()
            this.numberBuildingsListener()
        },
        closeSnapshotBuildings() {
            this.filterListener()
            this.buildingListener()
        },
        numberOfBuildings() {
            if(!this.client) this.client = localStorage.getItem('client')
            this.numberBuildingsListener = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").onSnapshot(snapshot => {
                this.reqistredBuilding = 0
                this.reqistredBuildings = snapshot.size
                // snapshot.forEach(() => {
                //        this.reqistredBuildings = this.reqistredBuildings + 1
                // })
            },
            error => {console.log("numberOfBuildings ERROR: ",error)})
        },
        async requestRecentData(limit) {
            let vm = this
            // this.recentDataListener = 
            firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").limit(limit).get().then(snapshot => {
                vm.fsrecentBuildings = []
                let temp = []
                snapshot.forEach(b => {
                    temp.push({ data: b.data(), id: b.id, street_view_image: b.data().general.street_view_image })
                })
                vm.fsrecentBuildings = temp
            },
            error => {console.log("requestRecentData ERROR: ",error)})

        },
        tabSortData(limit, order, key) {
            console.log(key)
            let vm = this
            // ToDo better, include none-entered values in orderBy()
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings")
            database.orderBy(key, order).limit(limit).onSnapshot(snapshot => {
                this.fsbuildingsData = []
                snapshot.forEach((building) => {
                    vm.fsbuildingsData.push({data: building.data(), id: building.id})
                })
            })
        },
        //requestData(limit, filter = ["additional.building_structure","==","brick"]) {
        requestData(limit, filter = false) {
            this.fsbuildingsData = []
            if(this.buildingListener){
                // I would argue this line is needed
                //firebase.removeEventListener(this.buildingListener)
                this.buildingListener = false
            }
            if(filter){
                this.requestDataWithFilter(limit,filter)
            }else{
                this.requestDataWithoutFilter(limit)
            }
        },
        requestDataWithoutFilter(limit){
            let vm = this
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings")
            let listener = database.limit(limit).onSnapshot(snapshot => {
                this.fsbuildingsData = []
                snapshot.forEach((building) => {
                    vm.fsbuildingsData.push({data: building.data(), id: building.id, street_view_image:building.data().general.street_view_image})
                })
            },error => {console.log("requestData ERROR: ",error)})
            this.buildingListener = listener
        },
        requestDataWithFilter(limit, filter) {
            let filterKeys = filter.filter
            let search = filter.search
            let columns = filter.columns
            let vm = this
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings")
            filterKeys.forEach(function(f){
                database = database.where(f[0],f[1],f[2])
            })
            let listener = database.onSnapshot(snapshot => {
                this.fsbuildingsData = []
                snapshot.forEach((building) => {
                    let save = true
                    if (search != ''){
                        save = false
                        columns.forEach(function(col){
                            let data = "not_entered_yet"
                            if(building.data()[col.split(".")[0]]){
                                data = building.data()[col.split(".")[0]][col.split(".")[1]] || "not_entered_yet"
                            }
                            if(vm.searchText(search, data)){
                                save=true
                            }
                        })
                    }
                    if(save) vm.fsbuildingsData.push({data: building.data(), id: building.id, street_view_image:building.data().general.street_view_image})
                })
            },error => {console.log("requestData ERROR: ",error)})
            this.buildingListener = listener
        },
        searchText(searchWord, value){
            return this.format(value).includes(this.format(searchWord))
        },
        requestBuilding(buildingId) {
            let vm = this
            this.requestBuildingListener = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).onSnapshot(snapshot => {
                vm.fsbuildingData = snapshot.data()
                console.log('Building Data:', vm.fsbuildingData);
            },error => {console.log("requestBuilding ERROR: ",error)})
        },
        requestBuildingWalls(buildingId) {
            let vm = this
            this.buildingWallsListener = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection("walls").orderBy("title").onSnapshot(snapshot => {
                this.fsbuildingDataWalls = []
                snapshot.forEach((wall) => {
                    vm.fsbuildingDataWalls.push({wall: wall.data(), wallId: wall.id})
                })
            },error => {console.log("requestBuildingWalls ERROR: ",error)})
        },
        requestRoofs(buildingId) {
            let vm = this
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection("roofs")
            database.orderBy("title").onSnapshot(snapshot => {
                this.fsbuildingRoofs = []
                snapshot.forEach((roof) => {
                    vm.fsbuildingRoofs.push({roof: roof.data(), roofId: roof.id})
                })
            },error => {console.log("requestBuildingRoof ERROR: ",error)})
        },
        requestDocuments(buildingId) {
            let vm = this
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection("documents")
            database.onSnapshot(snapshot => {
                this.fsbuildingDocuments = []
                snapshot.forEach((doc) => {
                    vm.fsbuildingDocuments.push({document: doc.data(), documentId: doc.id})
                })
            },error => {console.log("requestBuildingDocument ERROR: ",error)})
        },
        requestBuildingFacades(buildingId, wallId) {
            let vm = this
            this.buildingsFacadeListener = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection("walls").doc(wallId).collection("facades").onSnapshot(snapshot => {
                snapshot.docChanges().forEach(function(facade) {
                    if (facade.type === "added" || facade.type === "modified") {
                        let cleanedList = vm.fsbuildingDataFacades.filter(f => f.facadeId != facade.doc.id)
                        cleanedList.push({facade: facade.doc.data(), facadeId: facade.doc.id, wallId: wallId})
                        
                        let sorted = cleanedList.sort((a, b) => {
                            let aString = a.facade.title.replace(/\W/g, ''), bString = b.facade.title.replace(/\W/g, '')
                            let aNumber = a.facade.title.replace(/\D/g,''), bNumber = b.facade.title.replace(/\D/g,'')
                            if(aString === bString)
                                return aNumber < bNumber ? -1 : 1
                            else
                                return aString < bString ? -1 : 1                            
                        })
                        vm.fsbuildingDataFacades = sorted
                    }
                    if (facade.type === "removed") { 
                        { vm.fsbuildingDataFacades  = [...vm.fsbuildingDataFacades.filter(f => f.facadeId != facade.doc.id)] }
                        console.log("Removed: ", facade.doc.data());
                    }
                })
            },
            error => {console.log("requestBuildingFacades ERROR: ",error)})
        },
        requestFacadeWindows(buildingId, wallId, facadeId) {
            let vm = this
            this.facadeWindowsListener = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection("walls").doc(wallId).collection("facades").doc(facadeId).collection("windows").onSnapshot(snapshot => {
                snapshot.docChanges().forEach((window) => {
                    if (window.type === "added" || window.type === "modified") {
                        let cleanedList = vm.fsfacadeWindows.filter(w => w.windowId != window.doc.id)
                        cleanedList.push({windows: window.doc.data(), facadeId: facadeId, wallId: wallId, windowId: window.doc.id})

                        let sorted = cleanedList.sort((a, b) => a.windows.title < b.windows.title ? -1 : 1 )
                        vm.fsfacadeWindows = sorted
                    }
                    if (window.type === "removed") { vm.fsfacadeWindows  = [...vm.fsfacadeWindows.filter(w => w.windowId != window.doc.id)] }
                })
            },error => {console.log("requestFacadeWindows ERROR: ",error)})
        },
        requestBalconies(buildingId, wallId, facadeId) {
            let vm = this
            this.balconiesListener = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection("walls").doc(wallId).collection("facades").doc(facadeId).collection("balconies").onSnapshot(snapshot => {
                snapshot.docChanges().forEach((balcony) => {
                    if (balcony.type === "added" || balcony.type === "modified") {
                        let cleanedList = vm.fsBalconies.filter(w => w.balconyId != balcony.doc.id)
                        cleanedList.push({balconies: balcony.doc.data(), facadeId: facadeId, wallId: wallId, balconyId: balcony.doc.id})

                        let sorted = cleanedList.sort((a, b) => a.balconies.title < b.balconies.title ? -1 : 1 )
                        vm.fsBalconies = sorted
                    }
                    if (balcony.type === "removed") {
                        vm.fsBalconies  = [...vm.fsBalconies.filter(w => w.balconyId != balcony.doc.id)]
                    }
                })
            },error => {console.log("requestBalconies ERROR: ",error)})
        },
        async requestImage(imageId) {
            var user = firebase.auth().currentUser;
            let database = await firebase.firestore().collection("buildingapp").doc("core").collection("users").doc(user.uid).get().then(userObj => {
                return userObj.data().client
            }).then(cl => {return firebase.firestore().collection("buildingapp").doc('client-'+cl).collection("media").doc(imageId)})
            return await database.get().then((imgRef) => imgRef.data().uri )
        },
        async requestImages(imagesId) {
            let returnList = []
            imagesId.forEach( async (id) => {
                let db = await firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("media").doc(id).get();
                returnList.push(db.data().uri)
                
            })
            return returnList

            // let database = ''
            // imagesId.forEach( id => {
            //     database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("media").doc(id);
            //     database.onSnapshot(url => {
            //         if(url.data() && url.data().uri) {
            //             console.log("fetch: ", url.data().uri.endsWith("557") ? "h: " + id : "a: " + id)
            //             returnList.push(url.data().uri )
            //         }
            //     },error => {
            //         console.log("requestImages ERROR: ",error)
            //     })
            // })
            // return returnList
        },
        requestPinImages(imagesId) {
            let returnList = {parent: [], pins: []}
            let database = '';
            imagesId.forEach( id => {
                database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("media").doc(id);
                database.onSnapshot(url => {
                    if(url.data() && url.data().parent ) {
                        returnList.pins.push(url.data() )
                        this.requestPinCoord(url.data())
                    } else if(url.data() && url.data().parent === null ) {
                        returnList.parent.push({data: url.data(), parentId: id} )
                    }
                },error => {
                    console.log("requestImages ERROR: ",error)
                })
            })
            return returnList
        },
        requestPinCoord(pins) {
            if(pins) {
                let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("pins").doc(pins.pinId);
                database.onSnapshot(obj => {
                    if(obj.data()) {
                        this.fspinsData.push(obj.data() )
                    }
                },error => {
                    console.log("requestImages ERROR: ",error)
                })
            }
        },
        format(str) {
            let language = localStorage.getItem('language_web') ? localStorage.getItem('language_web') : 'en'
            if (typeof str == "number") {
                return toString(str)
            } 
            else {
                if (str === undefined) str = "not_entered_yet"
                if (str === false) str = "false"
                if (str === true) str = "true"
                if (typeof str === 'object') str = "undefined"
                let strLower = str.toLowerCase()
                if(language === "dev"){
                    return str.toLowerCase()
                }
                if ( this.locales[language]['firebase_options'][strLower] && this.locales[language][strLower] !== "" )
                    str = this.locales[language]['firebase_options'][strLower]
            }
            return str.toLowerCase()
        },
        callCloudFunctionApi(endpoint, buildingId) {
            return new Promise((resolve, reject) => {
                console.log('in readBuilding: ', endpoint)
                fetch(`https://europe-west3-buildingapp-be04d.cloudfunctions.net/${endpoint}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'data': {
                            'client': `client-${this.client}`,
                            'building_id': buildingId,
                            'write': true
                        }
                    })
                })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok ' + res.statusText);
                    }
                    return res.json();
                })
                .then((data) => {
                    console.log('in here? ', data)
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        fetchEnergyBalanceData(buildingId) {
            return new Promise((resolve, reject) => {
                try {
                    console.log('in function in readBuilding.js: fetchEnergyBalanceData')

                    fetch('https://europe-west3-buildingapp-be04d.cloudfunctions.net/generateEnergyCalculationInput', {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            'data': {
                                'client': `client-${this.client}`,
                                'building_id': buildingId,
                                'write': true
                            }
                        })
                    }).then(response => {
                        console.log('response: ', response)
                        return response.json()
                    }
                    ).then(data => {
                        console.log('data: ', data)
                        resolve(data)
                    })
                } catch (error) {
                    console.error("Error fetching energy balance data: ", error);
                    reject(error);
                }
            })
        },
    }
}