<template>
    <div class="roofs-view">
        <div class="roof-list-icon-container">
            <div class="roof-view">
                <div :class="!roofExcist ? 'roof-not-excist' : 'roof-excist'">
                    <chillText class="title-slider" type="title" value="roof_assessible" />
                    <div class="roof-slider">
                        <chillText value="no" />
                        <vue-slider
                            class="slider-bool"
                            v-model="roofBool"
                            :disabled="roofExcist ? true : false"
                            :enable-cross="true"
                            :min="0" :max="1"
                            tooltip="none"
                            @click.native="!roofExcist ? updateBuildingKey($route.params.id, Boolean(Number(roofBool)), 'none', 'roof_assessible') : ''"
                            @drag-end="!roofExcist ? updateBuildingKey($route.params.id, Boolean(Number(roofBool)), 'none', 'roof_assessible') : ''"
                            >
                        </vue-slider>
                        <chillText value="yes" />
                    </div>
                </div>
                <div class="roof-help-text" v-if="roofBool && (!roofExcist && !edit)"><chillText value="help_text_no_roof" /> </div>
                <div class="roof-help-text" v-if="roofBool && ((roofExcist && !edit) && !activeRoofObj)"><chillText value="help_text_no_active_roof" /> </div>
                <div v-if="activeRoofObj.length > 0 && !edit" class="roof-view-box">
                    <div class="active-roof-info" >
                        <!-- <p>here?</p> -->
                        <img :src="getImgUrl(activeRoofObj[0].roof.roof_type)" :alt="translate('no_roof_type')" />
                        <div class="text-active-roof-row">
                            <span> <chillText type="title" value="roof_title" /> <chillText :value="activeRoofObj[0].roof.title" /> </span>
                            <span> <chillText type="title" value="orientation" /> <chillText :value="activeRoofObj[0].roof.roof_orientation" /> </span>
                        </div>                        
                    </div>
                </div>
                <div v-if="roofBool && edit" class="roof-view-box">
                    <div class="roof-icon-image">
                        <img
                            :src="roofIconType ? getImgUrl(roofIconType) : require(`@/assets/${category}/${imageName}.png`)"
                            :class="!roofIconType ? 'roof-icon-holder' : '' "
                        />
                        <chillButton
                            type="primary inline"
                            :validation="edit ? true : false"
                            value="+"
                            selenium="choose_roof_type"
                            @on-click="$emit('modal', imageName, category,  'viewerIconPicker', iconType)"
                        />
                    </div>
                    <div class="icon-image-button">
                        <chillButton
                            type="primary inline"
                            value="add_new_roof"
                            :validation="edit && roofIconType ? true : false"
                            @on-click="createRoof()"
                            selenium="add_new_roof"
                        />
                    </div>
                </div>
            </div>
            <div class="roof-list">
                <div v-if="loadingUpdate" class="loader-row">
                    <div class="loader"></div>
                </div>
                <div class="row-deducted-data deducted-data-roofs" v-for="(key, p) in deductedTab('roof_all_title')" :key="p" >
                    <div class="row-deducted-data-line">
                        <inputGroup
                            type="text"
                            :title="key"
                            :value="getDeductedData(key)"
                            :edit="false"
                        />
                    </div>
                </div>
                <div v-for="x in roofsList" :key="x.roofId" @click="activeRoof = x.roofId" :class="activeRoof === x.roofId ? 'active-roof' : ''" id="roof_item_row" class="roof-item-row" >
                    <div class="roof_item">
                        <chillText type="keyValue" value="roof_title" /> <chillText type="keyValue" :value="x.roof.title" />
                    </div>
                    <div v-if="edit" class="roof-delete" @click="openModal(x.roofId)"> <chillText type="warning" value="delete" /> </div>
                </div>
            </div>
        </div>
        <div class="roof-content">
            <div class="roof-box-container" v-for="roof of activeRoofSide" :key="roof.roofId">
                <div class="roof-content-box">
                    <div class="roof-section" v-for="(content, i) in roofSection" :key="i">
                        <div class="column-roof-title">
                            <span class="column-roof-title-text"><chillText type="grayText" :value="i == 0 ? 'photos' : 'primary_data'"/></span>
                        </div>
                        <div :class="'roof-container-'+i" class="roof-container">
                            <div class="roof-first-second-column" v-for="(key, ii) of wallsStatementCheck(content, roof.roofId)" :key="key+ii">
                                <div class="roof-row">
                                    <inputGroup
                                        v-if="content_keys[key.split('.')[1]].type !== 'subcategory'"
                                        :type="content_keys[key.split('.')[1]].type"
                                        :value="wallsInputGroup(key, roof)"
                                        :title="key.split('.')[1]"
                                        :options="content_keys[key.split('.')[1]].options"
                                        :keyLocation="key.split('.')[0]"
                                        :edit="edit"
                                        :category="content_keys[key.split('.')[1]].category ?? 'roof' "
                                        :roofId="roof.roofId"
                                        @update="updateKey"
                                        @modal="modal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="york-data-third-column-roof">
                        <div class="column-roof-title">
                            <span class="column-roof-title-text"><chillText type="grayText" value="deducted_data"/></span>
                        </div>
                        <chillButton v-if="edit && !loadingUpdate" class="fbfunctionbutton" icon="edit" value="function_firebase" type="primary long inline" @on-click="$emit('deduct')"  />
                        <div v-if="loadingUpdate" class="loader-row">
                            <div class="loader"></div>
                        </div>
                        <div class="row-deducted-data" v-for="(key, p) in deductedTab('roof_title')" :key="p" >
                            <div class="row-deducted-data-line">
                                <inputGroup
                                    type="text"
                                    :title="key"
                                    :value="getDeductedData(key, roof.roofId)"
                                    :edit="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <chillModal
            v-if="showModal"
            component="viewerDelete"
            :value="modalDeleteId"
            modalIndex="roof"
            type="small"
            @close="showModal = false"
        />
    </div>
</template>

<script>
    import building_keys from '../../assets/json/ba_key_structure.json'
    import roofs_structure from '../../assets/json/roofs_structure.json'
    import deducted_keys_CM2 from '../../assets/json/deducted_keys_AP'
    import deducted_structure_CM2 from '../../assets/json/deducted_structure_AP'

    import vueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'

    export default {
        name: 'roofs',
        components: {
            vueSlider
        },
        props: [ "edit", "roofIconType", "buildingData", "loadingUpdate" ],
        data() {
            return {
                content_keys: building_keys,
                roofs_structure: roofs_structure,
                deducted_keys: deducted_keys_CM2,
                deducted_structure: deducted_structure_CM2,
                category: "roof",
                imageName: "box-gableHQ",
                iconType: "roof_type_add",
                activeRoof: "",
                activeRoofObj: "",
                showModal: false,
                modalDeleteId: '',
                roofBool: false

            }
        },
        computed: {
            roofsList() {
                return this.fsbuildingRoofs.filter(x =>  x.roofId ? x : null)
            },
            roofExcist() {
                return this.fsbuildingRoofs.length > 0 ? true : false
            },
            activeRoofSide() {
                return this.fsbuildingRoofs.filter(x => x.roofId === this.activeRoof ? x : null)
            },
            roofSection() {
                return this.roofs_structure.sections.map(section => section.content)
            },
        },
        watch: {
            buildingData: function() {
                this.roofBool = this.buildingData.roof_assessible ? Number(this.buildingData.roof_assessible) : 0
            },
            activeRoof: function() {
                this.activeRoofObj = this.fsbuildingRoofs.filter(x =>  x.roofId === this.activeRoof ? x : null)
            },
            edit: function() {
                this.activeRoofObj = this.fsbuildingRoofs.filter(x =>  x.roofId === this.activeRoof ? x : null)
            }
        },
        methods: {
            deductedTab(key) {
                const deductedData = this.deducted_structure.tabs.filter(x =>  x.title == key ? x : null )
                const deductedKeys = deductedData.flatMap(x => x.sections.flatMap(z => z.content ) )
                return deductedKeys.filter(x => this.getDeductedData(x) > 0 ? x : null )
            },
            getDeductedData(key, roofId) {
                let d = this.buildingData?.deducted_data_v2?.roofs?.[roofId] || {}
                // console.log(d)
                console.log("key", key, roofId, d)
                if(!roofId && key === "roof_area"){
                    if(typeof this.buildingData?.deducted_data_v2?.building_totals?.roof !== "number") return "--"
                    return this.buildingData?.deducted_data_v2?.building_totals?.roof
                }else{
                    // ROOF IS SPECIAL, ONLY HAVE AREA ATM
                    if(d && typeof d === "number"){
                        return d
                    }
                    return "--"
                }
                // if(this.buildingData.deducted_data && this.buildingData.deducted_data.roof_area) {
                //     if(typeof this.buildingData.deducted_data.roof_area[key] !== 'number') { return '--' }
                //     else{ return this.buildingData.deducted_data.roof_area[key].toFixed(1) }
                // }
            },
            createRoof() {
                this.createNewRoof(this.$route.params.id, this.fsbuildingRoofs.length+1, this.roofIconType)
                this.$emit('clear-icon')
            },
            wallsInputGroup(key, roofObj) {
                return roofObj ? roofObj.roof[key.split('.')[1]] : undefined
            },
            statementDeep(expr, roofId) {
                const roof = [...this.fsbuildingRoofs].filter(x => x.roofId === roofId ? x : null )
                return roof[0]['roof'][expr] && roof[0]['roof'][expr] !== 'false' ? true : false
            },
            statement(expr, roofId) {
                if(expr === undefined) {return false}
                else if(expr === true) {return true}
                else if(this.statementDeep(expr, roofId)) {return true}

                return false
            },
            wallsStatementCheck(content, roofId) {
                return content.filter(key => {                    
                    if(!this.content_keys[key.split('.')[1]].statements) { return key.split('.')[1] }
                    else { return this.statement(this.content_keys[key.split('.')[1]].statements[0].key, roofId) ? key.split('.')[1] : null }
                })
                
            },
            expandCheck(roofId) {
                return roofId === this.activeRoof ? true : false
            },
            getImgUrl(imageName) {
                if(imageName)
                    return require(`@/assets/${this.category}/${imageName}HQ.png`)
                else
                    return undefined
            },
            updateKey(newValue, keyName, roofId) {
                this.updateRoofKey(this.$route.params.id, newValue, keyName, roofId)
            },
            openModal(roofId) {
                this.modalDeleteId = {roofId: roofId}
                this.showModal = true
            },
            modal(image, index, component, iconType, roofId) {
                this.$emit("modal", image, index, component, iconType, roofId)
            },
        },
        mounted() {
            this.requestRoofs(this.$route.params.id)
            this.roofBool = this.buildingData.roof_assessible ? Number(this.buildingData.roof_assessible) : 0
        }
    }
</script>