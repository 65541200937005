<template>
    <div v-if="ifPinImage" class="viewer-images">
        <div id="modal_inner_div" class="main-image">
            <canvas ref="canvas" id="canvas" :class="imageIndex !== 0 ? 'hideCanvas': '' " v-on:click="eventChecker($event)"></canvas>
            <img ref="paintOnImage" :class="imageIndex === 0 ? 'hideCanvas': '' " :src="imageIndex === 0 ? value.parent[imageIndex] : value.pins[imageIndex - 1].uri" alt="image" >
        </div>
        <div class="image-carousel-box" >
            <span class="carousel-btn" @click="minus()"><chillIcon value="arrow_left" /></span>
            <div class="image-carousel" v-for="(val, i) of objectValue" :key="val">
                <chillText v-if="i !== 0" class="pin-number" :value="i" />
                <img :class="imageIndex === i ? 'active-image' : ''" :src="val" alt="image" @click="imageIndex = i" >
            </div>
            <div ref="pointer"></div>
            <span class="carousel-btn" @click="plus()"><chillIcon value="arrow_right" /></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "viewerImagePins",
        props: ["value", "viewerIndex", "modalIconType", "modalFacadeTitle" ],
        data() {
            return {
                imageIndex: 0,
                pinsIndex: 0,
                pins: [],
                ifPinImage: false,
                pinsList: [],
                filteredPins: [],
                parentImageLocal: ''
            }
        },
        watch: {
            pinsList: function() {
                if(this.value.pins.length > 0 && this.value.pins[0].parent) {
                    this.filteredPins = [...this.pinsList].filter(x => this.value.pins[0].parent === x.parent_image_id )
                }
                this.drawCanvas()
            }
        },
        computed: {
            objectValue() {
                let tempList = []
                tempList.push(this.value.parent[0].uri)
                this.value.pins.forEach((x) => {
                    tempList.push(x.uri)
                })
                return tempList
            }
        },
        methods: {
            drawCanvas() {
                setTimeout(() => {
                    let canvas = this.$refs.canvas
                    let ctx = canvas.getContext("2d")                    
                    ctx.canvas.width = this.value.parent[0].width
                    ctx.canvas.height = this.value.parent[0].height

                    let bg = new Image()
                    ctx.beginPath()

                    bg.src = this.value.parent[0].uri
                    bg.width = this.value.parent[0].width
                    bg.height = this.value.parent[0].height

                    bg.onload = () => {
                        ctx.drawImage(bg, 0 ,0, bg.width, bg.height)
                        this.loadCanvas(ctx)
                    }

                },1000)
            },
            loadCanvas(ctx) {
                let circleAdjustY = 13
                let textAdjustY = 9
                let textAdjustX = 5
                ctx.font='16px Yantramanav'
                ctx.color="white"
                ctx.lineWidth = 3
                if(this.pinsList.length > 0) {
                    for( let i = 0; i < this.filteredPins.length; i ++) {
                        ctx.fillStyle = "#000";
                        ctx.beginPath();
                        ctx.arc((this.filteredPins[i].position.x * ctx.canvas.width), (this.filteredPins[i].position.y * ctx.canvas.height) - circleAdjustY, 11, 0, 2 * Math.PI);
                        ctx.stroke();
                        ctx.fill();
                        
                        ctx.fillStyle = "#fff";
                        ctx.fillText( i+1, (this.filteredPins[i].position.x * ctx.canvas.width) - textAdjustX, (this.filteredPins[i].position.y * ctx.canvas.height) - textAdjustY )
                    }
                }
            },
            plus() {
                if(this.ifPinImage && this.imageIndex >= (this.pinsIndex - 1) )
                    this.imageIndex = 0 
                else if(this.imageIndex >= (this.value.length - 1)  )
                   this.imageIndex = 0 
                else
                    this.imageIndex = this.imageIndex + 1
            },
            minus() {
                if(this.ifPinImage && this.imageIndex <= 0)
                     this.imageIndex = (this.pinsIndex - 1)
                else if(this.imageIndex <= 0  )
                   this.imageIndex = (this.value.length - 1)
                else
                    this.imageIndex = this.imageIndex - 1
            }
        },
        mounted() {
            this.imageIndex = this.viewerIndex
            if(Object.keys(this.value).length > 0 && this.value.parent) {
                this.pinsIndex = (this.value.parent?.length + this.value.pins?.length)
                this.ifPinImage = true
            } else {
                this.ifPinImage = false
            }
            this.pinsList = this.modalIconType
            let parentImageFacadeTitle = this.modalFacadeTitle.charAt(0).toLowerCase()
            this.parentImageLocal = require(`@/assets/img/hermes_pictures/${parentImageFacadeTitle}.jpeg`)
        }
    }
</script>

<style scoped>
#canvas {
    max-width: 100%;
    max-height: 100%;
}
.modal_inner_div {
    max-width: 100%;
    max-height: 100%;
}
.paintOnImage {
    position: relative;
}
.hideCanvas {
    display: none;
}
.image-carousel {
    position: relative;
}
.pin-number {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black;
    border-radius: 35% 0%;
    color:white;
    padding: 0px 4px;
}
</style>