<template>
    <div class="hard-energy-data-view">
        <div v-for="local in localImgUrl" v-bind:key="local" class="enegry-data-energy-balance">
            <img class="hard-coded-img" :src="local" alt="" >
        </div>
    </div>
</template>

<script>
    export default {
        name: 'hardEnergyData',
        props: [ "activeTab", "localImgUrl" ],
        data() {
            return {

            }
        },
    }
</script>
   
<style scoped>
    .enegry-data-energy-generation {margin-top:24px;}
    .enegry-data-energy-balance {margin-top:24px;}
    
    .hard-coded-img {
        max-width: 96%;
        display: flex;
        margin: auto;
    }

</style>