<template>
    <div class="viewer-user">
        <h3 class="header"> <chillText value="edit_dedacted_data" /></h3>
        <div class="body">
                <div class="input-fields">
                <label>{{ translate('input_field_1') }}</label>
                <input v-model="inputField1" @input="updateField('input_field_1', inputField1)" />

                <label>{{ translate('input_field_2') }}</label>
                <input v-model="inputField2" @input="updateField('input_field_2', inputField2)" />
                <chillTextField />
            </div>
        </div>
        <div class="footer">
            <button @click="saveData">Save</button>
        </div>
    </div>
</template>
    
    <script>
        export default {
            name: "viewerDataModal",
            props: [ "value" ],
            data() {
                return {
            inputField1: '',
            inputField2: ''
        }
            },
            computed: {
            },
            methods: {
                updateKey(keyName, newValue) {
                    this.updateUser(this.value.id, keyName, newValue)
                },
                updateField(fieldName, newValue) {
                    this.$set(this.value, fieldName, newValue);
                },
                saveData() {
                    this.updateUser(this.value.id, 'input_field_1', this.inputField1);
                    this.updateUser(this.value.id, 'input_field_2', this.inputField2);
                },
                format(string) {
                    if(string === 'firstName' || string === 'lastName')
                        return string.replace(/[\w]([A-Z])/g, (str) => str[0] + "_" + str[1] ).toLowerCase();
                    else
                        return string
                }
            },
            mounted() { 
                this.inputField1 = this.value.input_field_1 || '';
                this.inputField2 = this.value.input_field_2 || '';
            }
        }
    </script>

<style scoped>
    .viewer-user {
        padding: 20px;
    }

    .header {
        margin-bottom: 10px;
    }

    .body {
        display: flex;
        flex-direction: column;
    }

    .input-fields {
        margin-top: 20px;
    }

    .input-fields label {
        margin-top: 10px;
    }

    .footer {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }

    button {
        background-color: #F6911B;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
    }

    button:hover {
        background-color: #e6801a;
    }
</style>