<template>
	<div class="divider">
		<div class="line" />
		<span>
			<chillText :value="text" />
		</span>
	</div>
</template>

<script>
    export default {
        name: "chillDivider",
        props: ["text"],
    }
</script>