import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const createBuilding = {
	data() {
		return {
            client: localStorage.getItem('client'),
            fsLoadingCreate: false
		}
	},
	methods: {
        createNewBuilding(abb, address, zip, city, country) {
            console.log("createNewBuilding")
            var add_building = firebase.functions().httpsCallable('add_building');
            add_building({ data: {
                client: "client-" + this.client,
                abb: abb,
                address: address,
                zip: zip,
                city: city,
                country: country} })
            .then((result) => {
                console.log("result: ",  result)
            });
        },
        createNewRoof(buildingId, title, iconType ) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('roofs')
            database.add({
                title: title,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                roof_type: iconType,
            })
        },
        createNewWall(buildingId, title, start, end) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls')
            database.add({
                title: title,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                startCoordinate: start,
                endCoordinate: end
            })
        },
        createNewFacade(buildingId, wallId, title) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades')
            database.add({
                title: title,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
        },
        createNewWindow(buildingId, wallId, facadeId, title) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId).collection('windows')
            database.add({
                title: title,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
        },
        createNewBalcony(buildingId, wallId, facadeId, title, balconyExcist) {
            let databaseFacade = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId)
            if(!balconyExcist) {
                databaseFacade.update({ 
                    balcony_exist: true
                })
            }
            let databaseBalcony = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId).collection('balconies')
            databaseBalcony.add({
                title: title,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
        }
    }
}