<template>
    <div v-if="windowType" class="icon-image-container">
        <div v-for="option of options" :key="option" class="icon-image-window-box" @click="update(option)">
            <chillImage :value="getWindowIcon(option)" :type="type" :class="value === option ? 'window-active-option' : '' " />
            <!-- <span>::{{getHelpText(option)}}</span> -->
            <chillText :value="getHelpText(option)" :type="value === option ? 'title' : 'title grayText'" />
        </div>
    </div>
    <div v-else class="icon-image-container">
        <chillImage :value="getImgPath(value)" :type="type" />
        <chillButton 
            v-if="edit"
            class="add-icon-btn"
            @on-click="$emit('modal', value, category, 'viewerIconPicker', iconType)" 
            :value="value ? 'change_'+category+'_typ' : 'add_'+category+'_type'"
            :selenium="category+'_typ_change'"
            type="primary inline"
            validation="true"
        />
    </div>
</template>

<script>
    import {windowKeys} from '@/assets/windows/index'
    import {balconyKeys} from '@/assets/balconies/index'
    export default {
        name: "chillIconSelect",
        props: ["edit", "value", "type", "category", "title", "options" ],
        data () {
            return {
                iconType: '',
                windowKeys: windowKeys,
                balconyKeys: balconyKeys,
                iconKeys: [],
                iconPath: ''
            }
        },
        computed: {
            windowType() {
                if(this.title === 'window_category_opening' || this.title === 'window_left_proximity' || this.title === 'window_right_proximity')
                    return true
                else if(this.title === 'balcony_position' || this.title === 'balcony_construction' || this.title === 'balcony_fixation')
                    return true
                else 
                    return false
            }
        },
        methods: {
            getImgPath(imageName) {
                if(imageName){
                    console.log("imageName: ", imageName, this.category)
                    return require(`@/assets/${this.category}/${imageName}HQ.png`)
                }
                else
                    return undefined
            },
            getWindowIcon(option) {
                for(let i = 0; i < this.iconKeys.length; i++ ) {
                    if(this.iconPath === 'windows' && this.iconKeys[i].key === option)
                        return require(`@/assets/windows/${this.iconKeys[i].source}`)
                    else if(this.iconPath === 'balconies' && this.iconKeys[i].key === option)
                        return require(`@/assets/balconies/${this.iconKeys[i].source}`)
                } return undefined
            },
            getHelpText(option) {
                for(let i = 0; i < this.iconKeys.length; i++ ) {
                    if(this.iconKeys[i].key === option) {
                        return this.iconKeys[i].helpText
                    }
                } return undefined
            },
            update(newValue) {
                if(this.edit && newValue !== this.value)
                    this.$emit('update', newValue)
            }
        },
        mounted() {
            this.iconType = this.title
            if(this.title === 'window_category_opening' || this.title === 'window_left_proximity' || this.title === 'window_right_proximity') {this.iconKeys = this.windowKeys, this.iconPath = 'windows'}
            else if(this.title === 'balcony_position' || this.title === 'balcony_construction' || this.title === 'balcony_fixation') {this.iconKeys = this.balconyKeys, this.iconPath = 'balconies'}
        }
    }
</script>