<template>
<div class="viewer-add-building">
    <h3 class="header"> <chillText value="add_new_building" /></h3>
    <chillText value="map_click_set_address" />
    <div class="body">
        <div class="modal-map-box">
            <div ref="modal_map_canvas" id="modal_map_canvas" ></div>
        </div>
        <div>
            <div class="text-title-field">
                <chillText type="title" value="address" />
                <input v-model="buildingAddress.address"/>
            </div>
            <div class="text-title-field">
                <chillText type="title" value="zip" />
                <input v-model="buildingAddress.zip"/>
            </div>
            <div class="text-title-field">
                <chillText type="title" value="city" />
                <input v-model="buildingAddress.city"/>
            </div>
            <div class="text-title-field">
                <chillText type="title" value="country" />
                <input v-model="buildingAddress.country"/>
            </div>
        </div>
    </div>
    <div class="footer-add-building">
        <chillButton type="primary inline" value="create_new_building" @on-click="addnewBuilding()" />
    </div>
</div>
</template>

<script>
    export default {
        name: "viewerAddBuilding",
        props: [ "value" ],
        data() {
            return {
                map: null,
                geocoder: null,
                marker: null,
                buildingAddress: {
                    address: '',
                    zip: '',
                    city: '',
                    country: ''
                }
            }
        },
        methods: {
            initMap() {
                // center on map is always set to London
                this.map = new window.google.maps.Map(this.$refs['modal_map_canvas'], {
                    center: new window.google.maps.LatLng(51.4918849, -0.1117498,12),
                    mapTypeId: 'satellite',
                    tilt: 0,
                    zoom: 17,
                })
                this.geocoder = new window.google.maps.Geocoder();
                this.map.addListener("click", (coordinates) => {
                    if(this.marker !== null ) { this.removeMarker() }
                    let coordinateString = JSON.stringify(coordinates.latLng)
                    this.clickAddress(coordinateString)
                })
            },
            addnewBuilding() {
                this.createNewBuilding(this.buildingAddress.address[0], this.buildingAddress.address, this.buildingAddress.zip, this.buildingAddress.city, this.buildingAddress.country) 
                this.$emit("close")
            },
            clickAddress(coordinates) {
                let nCord = JSON.parse(coordinates)
                const latlng = {
                    lat: nCord.lat,
                    lng: nCord.lng,
                }
                this.geocoder.geocode({ location: latlng }, (results, status) => {
                    if (status === "OK") {
                        if (results[0]) {
                            // this.map.setZoom(18);
                            this.marker = new window.google.maps.Marker({
                                position: latlng,
                                map: this.map,
                            });
                            this.collectBuildingInfo(results[0])
                        } 
                        else { window.alert("No results found"); }
                    } else { window.alert("Geocoder failed due to: " + status); }
                })
            },
            removeMarker() {
                this.marker.setMap(null)
                this.marker = null
            },
            collectBuildingInfo(addressInfo) {
                addressInfo.address_components.forEach(x => {
                    if(x.types[0] === 'route') {this.buildingAddress.address = x.long_name}
                    else if(x.types[0] === 'postal_town') {this.buildingAddress.city = x.long_name}
                    else if(x.types[0] === 'country') {this.buildingAddress.country = x.long_name}
                    else if(x.types[0] === 'postal_code') {this.buildingAddress.zip = x.long_name}
                })
            }
        },
        mounted() {
            window.google.maps.event.addDomListener(window, "load", this.initMap())
        }
    }
</script>

<style scoped>
    #modal_map_canvas {
        min-height: 370px;
        color: gray;
        border-radius: 8px;
    }
    .viewer-add-building {
        width: 80%;
    }
    .modal-map-box {
        width: 100%;
    }
    .text-title-field {
        display: flex;
        justify-content: space-between;
        margin: 8px 0px;
    }
    .footer-add-building {
        text-align: right;
    }
</style>