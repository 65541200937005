<template>
    <div class="building-view">
        <div class="buillding-container">
            <sidebarLeft selenium="buildingDetailsBack" value="back_to_list" icon="arrow_left" :showMap="true" v-on:go-back="$router.go(-1)" :coordinates="buildingsCoordinates" :buildingData="fsbuildingData" :buildingDataWalls="fsbuildingDataWalls" :edit="edit" :activeTab="activeTab" />
            <div class="buillding-content-container">
                <div class="building-header">
                    <div class="header-content" v-if="buildingDataExcist">
                        <div class="header-information header-box-1">
                            <div class="information">
                                <chillText value="building_type" type="header-title grayText" />
                                <chillText :value="fsbuildingData.additional ? fsbuildingData.additional.building_type : '' " type="header-text" />
                            </div>
                            <div class="information">
                                <chillText value="use" type="header-title grayText" />
                                <chillText :value="fsbuildingData.additional ? fsbuildingData.additional.use : '' " type="header-text" />
                            </div>
                        </div>
                            <div class="header-information header-box-2">
                                <div class="information">
                                    <chillText value="building_year" type="header-title grayText" />
                                    <chillText :value="fsbuildingData.additional ? fsbuildingData.additional.building_year : '' " type="header-text" />
                                </div>
                                <div class="information" v-if="fsbuildingData.additional">
                                    <chillText value="belong_to_group" type="header-title grayText" />
                                    <chillText :value="fsbuildingData.additional ? fsbuildingData.additional.belong_to_group : '' " type="header-text" />
                                </div>
                            </div>
                        <div class="header-information header-box-3">
                            <chillImage :value="fsbuildingData.general.street_view_image" type="header-or-breadcrums"  />
                        </div>
                    </div>
                </div>
                <div class="building-tabs-body-footer">
                    <div class="tabs-row-building" >
                        <span v-for="(tab, k) of content_structure.tabs" :key="tab+k" @click="activeTab = tab.title" :id="'building_tab_'+tab.title" :class="activeTab === tab.title ? 'active-tab' : ''" class="tab-building">
                            <chillIcon :value="tab.icon" class="header-icon" />
                            <chillText :value="tab.title" class="tooltiptext" />
                            <!-- <span class="tooltiptext">Tooltip text</span> -->
                            <!-- <chillText :value="tab.title" /> -->
                        </span>
                    </div>
                    <div class="building-body">
                        <div class="building-content-box">
                            <div class="building-section" v-for="(content, i) in tabsSection" :key="i">
                                <div v-if="activeTab === 'general' || activeTab === 'hvac'" class="column-building-title">
                                    <span class="column-building-title-text column-building-photos"><chillText type="grayText" :value="i == 0 ? 'photos' : 'primary_data'"/></span>
                                </div>
                                <div :class="'building-container-'+i" class="building-container">
                                    <div class="data-first-second-column" v-for="(key, ii) of statementCheck(content)" :key="key+ii">
                                        <div class="building-content-row">
                                                <inputGroup
                                                    :type="content_keys[key.split('.')[1]].type"
                                                    :title="key.split('.')[1]"
                                                    :options="content_keys[key.split('.')[1]].options"
                                                    :category="content_keys[key.split('.')[1]].category"
                                                    :value="valueInputGroup(key)"
                                                    :edit="edit"
                                                    :keyLocation="key.split('.')[0]"
                                                    @modal="openModal"
                                                    @update="updateKey"
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <roofs v-if="activeTab === 'roof_title'"
                                :edit="edit"
                                :roofIconType="roofIconType"
                                :buildingData="fsbuildingData"
                                @modal="openModal"
                                @clear-icon="roofIconType = ''"
                                @deduct="start_deducting_data"
                                :loadingUpdate="fsLoadingUpdate"
                            />
                            <walls v-if="activeTab === 'walls_title'" 
                                :buildingData="fsbuildingData"
                                :buildingDataWalls="fsbuildingDataWalls"
                                :buildingDataFacades="fsbuildingDataFacades"
                                :facadeWindows="fsfacadeWindows"
                                :balconyData="fsBalconies"
                                :edit="edit"
                                @modal="openModal"
                                @deduct="start_deducting_data"
                                :loadingUpdate="fsLoadingUpdate"
                            />
                            <documents
                                v-if="activeTab === 'documents'"
                                :edit="edit"
                                :buildingDocuments="fsbuildingDocuments"
                            />
                            <!-- below hardEnergyData are created only for Yorks webinar  -->
                            <!-- churchill square -->
                            <hardEnergyData
                                v-if="$route.params.id === 'XuA1qstSV16nFD5w1XCO' && activeTab === 'energy_generation'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/york_energy_generation.jpeg`)]"
                            />
                            <hardEnergyData
                                v-if="$route.params.id === 'XuA1qstSV16nFD5w1XCO' && activeTab === 'energy_balance'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/hermes_churchillSquare_energy_balance.png`)]"
                            />
                            <!-- ottenstrasse -->
                            <!-- energy_generation -->
                            <hardEnergyData
                                v-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_generation' && userLanguage === 'de'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/energy_gen_DE.png`)]"
                            />
                            <hardEnergyData
                                v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_generation' && userLanguage === 'fr'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/energy_gen_FR.png`)]"
                            />
                            <hardEnergyData
                                v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_generation'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/energy_gen_EN.png`)]"
                            />

                            <!-- Demo Chart -->
                            <!-- <lineBarChart v-else-if="activeTab === 'energy_generation'"
                                :series="fsbuildingData?.charts_v2?.energy_generation ?? []"
                            /> -->

                            <workInProgress v-else-if="activeTab === 'energy_generation'" />



                            <!-- IF YORK WANTS HARD CODED ENERGY DATA THIS IS IT UNDER!-->
                            <!-- energy_balance -->
                            <!-- <hardEnergyData
                                v-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_balance' && userLanguage === 'de'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/energy_balance_DE.png`)]"
                            />
                            <hardEnergyData
                                v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_balance' && userLanguage === 'fr'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/energy_balance_FR.png`)]"
                            />
                            <hardEnergyData
                                v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_balance'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/energy_balance_EN.png`)]"
                            /> -->

                            <!-- IF YORK WANTS HARD CODED ENERGY DATA THIS IS IT ABOVE!-->



                            <!-- Demo Chart -->
                            <!-- <lineBarChart v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'energy_balance'"
                                :series="demo_chart_data.balance"
                            /> -->
                            <!-- <lineBarChart v-else-if="activeTab === 'energy_balance'"
                                :series="demo_chart_data.balance ?? []"
                            /> -->
                            <div v-if="activeTab === 'energy_balance' && !fsbuildingData?.charts_v2?.energy_balance" style="width: 100%;"> 
                                <chillButton v-if="!fsLoadingUpdate" class="fbfunctionbutton" icon="edit" value="function_firebase" type="primary long inline" @on-click="deduct_data_energy_balance($route.params.id); loading = true" />
                                <div v-else-if="fsLoadingUpdate" style="justify-content: center; display: flex; align-items: center; width:100%">
                                    <div style="margin-top: 40px;" class="loader"></div>
                                </div>
                            </div>
                            <!-- <lineBarChart v-else-if="activeTab === 'energy_balance' && fsbuildingData?.charts_v2?.energy_balance &&  (fsUserObjekt?.id  === '38bFCicYvshnLx2NLXv0NtmlOI93' || fsUserObjekt?.id === 'YwNbc2cPTwRXFa43ewLpZMInkNp2')" -->
                                <div class="full-page-bar-chart" v-else-if="activeTab === 'energy_balance' && fsbuildingData?.charts_v2?.energy_balance">
                                        <!-- <div class="absolute-title-energy">
                                            <chillText value="energy_balance" type="default-bold-text grayText" />
                                        </div> -->

                                        <div v-if="isGenerateDataLoading" class="loader-row loading-text">
                                            <div class="loader"></div>
                                            <chillText :value="loadingMessage" type="greyText" />
                                        </div>

                                        <lineBarChart v-else style="width: 97%; height:100%; "
                                            :series="fsbuildingData?.charts_v2?.energy_balance ?? []"
                                            :energyBalanceGeneratedInput="fsbuildingData?.energy_calculation_generated_input ?? {}"
                                            :energyBalanceUserInput="fsbuildingData?.energy_calculation_user_input ?? {}"
                                            :energyBalanceAnnual="fsbuildingData?.energy_balance_annual ?? {}"
                                            :edit="edit"
                                            @update-edit="edit = $event"
                                            @save-energy-balance-input="saveEnergyBalanceInput"
                                            @recalculate-energy-balance="recalculateEnergyBalance"
                                            @start-generation-loading="isGenerateDataLoading = true"
                                            @update-loading-message="loadingMessage = $event"
                                            @loading-start="loading = true" 
                                            @loading-end="loading = false"
                                        />

                                    <!-- <div class="info-under-graf">
                                        <div  class="same-line-energybalance">
                                            <chillText value="calculated_q_rad" type="default-bold-text" />
                                            <p class="default-bold-text">: </p>
                                            <div class="same-line-energybalance">
                                            <p class="default-bold-text"> </p>
                                                <chillText :value="loading ? '-- ' :fsbuildingData?.energy_balance_annual?.calculated_q_rad" type="default-text" />
                                                <p class="default-text"> kWh</p>
                                            </div>
                                        </div>
                                        <div class="same-line-energybalance">
                                            <chillText value="calculated_per_m2_net_q_rad" type="default-bold-text" />
                                            <p class="default-bold-text">: </p>
                                            <div class="same-line-energybalance">
                                            <p class="default-bold-text"> </p>
                                                <chillText :value="loading ? '-- ' : fsbuildingData?.energy_balance_annual?.calculated_per_m2_net_q_rad" type="default-text" />
                                                <p class="default-text"> kWh/m&sup2;</p>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            <!-- <workInProgress v-else-if="activeTab === 'energy_balance'" /> -->
                            <!-- analytics -->
                            <hardEnergyData
                                v-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'analytics' && userLanguage === 'de'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/analytics1_DE.png`),require(`@/assets/img/analyses_static/analytics2_DE.png`),require(`@/assets/img/analyses_static/analytics3_DE.png`)]"
                            />
                            <hardEnergyData
                                v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'analytics' && userLanguage === 'fr'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/analytics1_FR.png`),require(`@/assets/img/analyses_static/analytics2_FR.png`),require(`@/assets/img/analyses_static/analytics3_FR.png`)]"
                            />
                            <hardEnergyData
                                v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'analytics'"
                                :activeTab="activeTab"
                                :edit="edit"
                                :localImgUrl="[require(`@/assets/img/analyses_static/analytics1_EN.png`),require(`@/assets/img/analyses_static/analytics2_EN.png`),require(`@/assets/img/analyses_static/analytics3_EN.png`)]"
                            />
                            <workInProgress v-else-if="activeTab === 'analytics'" />
                            
                            <!-- Demo Chart -->
                            <!-- <div style="width: 100%; height:100%;" v-else-if="$route.params.id === 'lPc1NjaizloSrWWYFx69' && activeTab === 'analytics'">
                                <dottedLineChart
                                    :series="demo_chart_data.decarb_strategy"
                                    id="1"
                                    label="Decarbonisation strategy"
                                />
                                <dottedLineChart
                                    :series="demo_chart_data.decarb_roadmap"
                                    id="2"
                                    label="Decarbonisation roadmap"
                                />
                                <dottedLineChart
                                    :series="demo_chart_data.heating_costs"
                                    id="3"
                                    label="Decarbonisation roadmap"
                                />
                            </div> -->
                            <!-- embodied_carbon -->
                            <div v-if="activeTab === 'embodied_carbon' && (!fsbuildingData?.charts_v2?.embodied_carbon || fsbuildingData?.charts_v2?.embodied_carbon.filter(a => a.value  > 0).length === 0)" style="width: 100%;"> 
                                <chillButton v-if="!fsLoadingUpdate" class="fbfunctionbutton" icon="edit" value="function_firebase" type="primary long inline" @on-click="deduct_data_emodied($route.params.id);loading = true"  />
                                <div v-else-if="fsLoadingUpdate" style="justify-content: center; display: flex; align-items: center; width:100%">
                                    <div style="margin-top: 40px;" class="loader"></div>
                                </div>
                            </div>
                            <EmbodiedCarbonChart v-else-if="activeTab === 'embodied_carbon'" :series_data="fsbuildingData?.charts_v2?.embodied_carbon ?? []"/>


                            
                            <!-- MAINTENCEACE-->

                            <workInProgress v-if="activeTab === 'maintenance'" />

                            
                            <div v-if="(activeTab === 'general' || activeTab === 'hvac' )" class="york-data-third-column">
                                <div class="column-building-title">
                                    <span class="column-building-title-text column-building-deducted-data"><chillText type="grayText" value="deducted_data"/></span>
                                </div>
                                <chillButton  v-if="edit && !fsLoadingUpdate" class="fbfunctionbutton" icon="edit" value="function_firebase" type="primary long inline" @on-click="start_deducting_data()" />
                                <div v-if="fsLoadingUpdate" class="loader-row">
                                    <div class="loader"></div>
                                </div>
                                <div v-if="!fsLoadingUpdate">
                                    <div class="row-deducted-data" v-for="(key, p) in deductedTab" :key="p" >
                                        <div class="row-deducted-data-line">
                                            <inputGroup
                                                type="text"
                                                :title="key"
                                                :value="getDeductedData(key)"
                                                :edit="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    
                    </div>
                    <chillFooter class="chill-footer-tag" />
                </div>
            </div>
            <sidebarRight :selenium="!edit ? 'buldingActivateEditMode' : 'buldingDeactivateEditMode' " :edit="edit" @toogle-edit="edit = !edit" :activeTab="activeTab" />
        </div>
        <chillModal v-if="showModal"
            :component="component" 
            :value="valueModal"
            :modalIndex="modalIndex" 
            :type="modalType"
            :modalIconType="modalIconType"
            :buildingIds="buildingIds"
            :modalFacadeTitle="modalFacadeTitle"
            @close="showModal = false"
            @icon-type="roofIconType = $event"
            :edit="edit"
        />
    </div>
</template>

<script>
    import building_keys from '../assets/json/ba_key_structure.json'
    import building_structure from '../assets/json/building_structure.json'

    import deducted_keys_CM2 from '../assets/json/deducted_keys_AP'
    import deducted_structure_CM2 from '../assets/json/deducted_structure_AP'

    //demo chart data
    import {chart_data} from '../assets/demoChartData.js'
    import EmbodiedCarbonChart from '../components/sections/embodiedCarbonChart.vue'

    export default {
    name: 'Building',
    data() {
        return {
            demo_chart_data: chart_data,
            content_keys: building_keys,
            content_structure: building_structure,
            deducted_keys: deducted_keys_CM2,
            deducted_structure: deducted_structure_CM2,
            activeTab: 'general',
            edit: false,
            limit: 10,
            showModal: false,
            componentModal: null,
            valueModal: '',
            modalIndex: 0,
            modalIconType: '',
            buildingIds: {},
            buildingsCoordinates: null,
            roofIconType: '',
            modalFacadeTitle: '',
            loading: this.fsLoadingUpdate,
            modalType: '',
            isGenerateDataLoading: false,
            loadingMessage: 'Loading, please wait...'
        };
    },
    watch: {
        activeTab: function () { 
            if(this.activeTab === 'energy_balance') {
                this.loadingMessage = 'Step 1/3'
                this.isGenerateDataLoading = true;

                this.callCloudFunctionApi('deductedDataV2', this.$route.params.id)
                .then(deductedData => {
                    console.log('deductedData', deductedData)
                    this.$set(this.fsbuildingData, 'deducted_data_v2', deductedData)
                    this.generateEnergyBalandeData()
                })
                .catch((e) => {
                    console.log('error', e)
                })
            }
        },
        fsbuildingData: function () {
            if (this.fsbuildingData) {
                this.buildingsCoordinates = this.fsbuildingData.geolocation;
            }
        },
        fsbuildingDataWalls: function () {
            this.fsbuildingDataWalls.map(facade => this.requestBuildingFacades(this.$route.params.id, facade.wallId));
        },
        fsbuildingDataFacades: function () {
            this.fsbuildingDataFacades.map(facade => this.requestFacadeWindows(this.$route.params.id, facade.wallId, facade.facadeId));
            this.fsbuildingDataFacades.map(facade => this.requestBalconies(this.$route.params.id, facade.wallId, facade.facadeId));
        },
        fsfacadeWindows: function () { }
    },
    computed: {
        tabsSection() {
            if (!(this.activeTab === 'walls_title' || this.activeTab === 'roof_title' || this.activeTab === 'documents')) {
                const section = this.content_structure.tabs.filter((tab) => tab.title === this.activeTab ? tab : null);
                const content = section[0].sections.map(section => section.content);
                return content;
            }
            else
                return null;
        },
        buildingDataExcist() {
            return !Object.keys(this.fsbuildingData).length ? false : true;
        },
        deductedTab() {
            const deductedData = this.deducted_structure.tabs.filter(x => x.title == this.activeTab ? x : null);
            const deductedKeys = deductedData.flatMap(x => x.sections.flatMap(z => z.content));
            return deductedKeys.filter(x => this.getDeductedData(x) > 0 ? x : null);
        }
    },
    methods: {
        generateEnergyBalandeData() {
            this.loadingMessage = 'Step 2/3'
            this.callCloudFunctionApi('generateEnergyCalculationInput', this.$route.params.id)
                .then(generatedEnergyBalanceData => {
                    console.log('generatedEnergyBalanceData', generatedEnergyBalanceData)

                    this.$set(this.fsbuildingData, 'energy_calculation_generated_input', generatedEnergyBalanceData
                    )
                    this.recalculateEnergyBalance()
                })
                .catch((e) => {
                    console.log('error', e)
                })
            },
        saveEnergyBalanceInput(updatedInputs) {
            for (const [key, value] of Object.entries(updatedInputs)) {
                const location = 'energy_calculation_user_input';
                this.updateKey(value, location, key);
            }
        },
        recalculateEnergyBalance() {
            this.loadingMessage = 'Step 3/3'

            this.callCloudFunctionApi("energyBalanceV2", this.$route.params.id)
            .then(() => {
                this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "success" });
                this.isGenerateDataLoading = false;
                this.loadingMessage = ''
                return 'done'
            })
            .catch(() => {
                this.$toasted.show(this.translate("calculation_error"), { position: "bottom-right", duration: 3000, type: "error" });
                this.isGenerateDataLoading = false;

            })
        },
        getLoadingData() {
            return this.fsLoadingUpdate
        },
        start_deducting_data() {
            this.deduct_data(this.$route.params.id);
        },
        getDeductedData(key) {
            key = key.substring(0, key.lastIndexOf("_area"));
            let buildingTotals = this.fsbuildingData.deducted_data_v2?.building_totals
            if(buildingTotals){
                if(buildingTotals[key] || buildingTotals[key] === 0){
                    return buildingTotals[key].toFixed(1)
                }
                return "--"
            }
        },
        getDeductedDataOld(key) {
            // if working for now, need to be updated when we have more data
            if (this.fsbuildingData.deducted_data && this.fsbuildingData.deducted_data.surface_area) {
                if (typeof this.fsbuildingData.deducted_data.surface_area[key] !== 'number') {
                    return '--';
                }
                else {
                    return this.fsbuildingData.deducted_data.surface_area[key].toFixed(1);
                }
            }
        },
        statement(expr, location) {
            if (expr === undefined || this.fsbuildingData[location] === undefined) {
                return false;
            }
            else if (expr === true) {
                return true;
            }
            else if (expr.operator === "equals" && this.fsbuildingData[location][expr.key] === expr.value) {
                return true
            }
            else if (this.fsbuildingData[location] === undefined) {
                return false;
            }
            else if (this.fsbuildingData[location][expr.key] === true) {
                return true;
            }
            return false;
        },
        statementCheck(content) {
            if (this.buildingDataExcist)
                return content.filter(key => {
                    // console.log("==", key)
                    if (!this.content_keys[key.split('.')[1]].statements) {
                        return key.split('.')[1];
                    }
                    else {
                        return this.statement(this.content_keys[key.split('.')[1]].statements[0], key.split('.')[0]) ? key.split('.')[1] : null;
                    }
                });
            else
                return null;
        },
        valueInputGroup(key) {
            if (this.fsbuildingData[key.split('.')[0]])
                return this.fsbuildingData[key.split('.')[0]][key.split('.')[1]];
            else
                return undefined;
        },
        openModal(value, index, component, iconType, roofId, wallId, facadeId, windowId, balconyId, facadeTitle, type) {          
            this.showModal = true;
            this.valueModal = value;
            this.modalIndex = index;
            this.component = component;
            this.modalType = type ? type : 'large';
            this.modalIconType = iconType;
            this.buildingIds = { roofId: roofId, wallId: wallId, facadeId: facadeId, windowId: windowId, balconyId: balconyId };
            this.modalFacadeTitle = facadeTitle;
        },
        updateKey(newValue, location, keyName) {
            this.updateBuildingKey(this.$route.params.id, newValue, location, keyName);
        }
    },
    beforeMount() {
        this.requestBuilding(this.$route.params.id, this.limit);
        this.requestBuildingWalls(this.$route.params.id);
        this.requestDocuments(this.$route.params.id);
        this.getUser()
    },
    components: { EmbodiedCarbonChart }
}
</script>

<style>
    /* .absolute-title-energy{
        position: absolute;
        top: 30px;
        left: 35px;
    } */

    .same-line-energybalance{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 3px;
    }

    .full-page-bar-chart{
        width: 100%;
        display: flex;
        padding: 10px 20px;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    .info-under-graf{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: start;
        align-items: start;
        margin-top: 35px;
        padding: 0px 20px;
        position: relative;
    }

    .loading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 100%;
    }
</style>