<template>
<div class="viewer-user">
    <h3 class="header"> <chillText value="edit_user_info" /></h3>
    <div class="body">
        <div v-for="(key, i) of getContent" :key="i">
            <userGroup
                :title="format(key)"
                :value="value[key]"
                :type="user_keys[key].type"
                :options="user_keys[key].options"
                @update="updateKey"
            />
        </div>
    </div>
    <div class="footer"></div>
</div>
</template>

<script>
    import user_structure from '@/assets/json/user.json'
    import user_keys from '@/assets/json/userKeys.json'
    export default {
        name: "viewerUser",
        props: [ "value" ],
        data() {
            return { 
                user_structure: user_structure,
                user_keys: user_keys
            }
        },
        computed: {
            getName() {
                if(this.value) { return `${this.value.firstName} ${this.value.lastName}` }
                else { return '' }
            },
            getContent() {
                if(this.value) {
                    const content = user_structure.tabs.flatMap(x => {
                        return x.sections.flatMap(c => {
                            return c.content
                        })
                    })
                    return content
                } else {return null}
            }
        },
        methods: {
            updateKey(keyName, newValue) {
                this.updateUser(this.value.id, keyName, newValue)
            },
            format(string) {
                if(string === 'firstName' || string === 'lastName')
                    return string.replace(/[\w]([A-Z])/g, (str) => str[0] + "_" + str[1] ).toLowerCase();
                else
                    return string
            }
        },
        mounted() { 
        }
    }
</script>