<template>
    <div class="sidebar-left">
        <div class="border-bar">
            <div class="sidebar-button" :id="selenium" @click="emitFunction()">
                <chillButton :icon="icon" :value="value" type="icon" class="button left-side" />
            </div>
        </div>
        <div class="handle-filter-box" v-if="filterApplied">
            <div class="handle-filter-box-field" v-if="filterAppliedText">
                <div>
                    <chillText type="title block" value="text_filter_is_applied" />
                </div>
                <div>
                    <chillText type="keyValue" :value="savedTextFilter" />
                </div>
            </div>
            <div class="handle-filter-box-field" v-if="filterAppliedKeys">
                <div>
                    <chillText type="title" value="keys_filter_is_applied" />
                </div>
                <div>
                    <chillText type="keyValue" :value="savedKeyFilter[0].split('.')[1]" />
                    <chillText type="keyValue block" :value="savedKeyFilter[1] " />
                    <chillText type="keyValue block" :value="savedKeyFilter[2]" />
                </div>
            </div>
            <chillButton class="handle-filter-box-field" type="warning inline" value="clean_filter" @on-click="$emit('clean-filter')" />
        </div>
        <div class="sidebar-content-left" v-if="showMap">
            <div class="breadcrum-text" v-if="buildingData.general">
                <div v-for="(bread, i) of content_structure.breadcrumbs" :key="i"> 
                    <chillText :value="buildingData[bread.split('.')[0]][bread.split('.')[1]]" /> 
                </div>
            </div>
            <div class="map-side-box" v-if="buildingData.geolocation && coordinates">
                <div class="map-side-left">
                    <gmapChill v-if="buildingData.geolocation && coordinates" :coordinates="coordinates" :buildingDataWalls="[]" :edit="false" mapCanvasId="left_side_roadmap" />
                </div>
            </div>
            <div class="map-side-box" v-if="activeTab ==='walls_title'">
                <div class="map-side-left">
                    <gmapChill :coordinates="coordinates" :buildingDataWalls="buildingDataWalls" :edit="false" mapCanvasId="left_side_satellite" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import building_structure from '../assets/json/building_structure.json'
    export default {
        name: 'sidebarLeft',
        props: ["edit", "selenium", "coordinates", "buildingData", "buildingDataWalls", "activeTab", "showMap", "value", "icon", "filterApplied", "filterAppliedText", "filterAppliedKeys", "savedKeyFilter", "savedTextFilter"],
        data() {
            return {
                content_structure: building_structure,
            }
        },
        methods: {
            emitFunction() {
                if(this.value === 'back_to_list') { this.$emit('go-back') }
                else if(this.value === 'show_filter' || this.value === 'hide_filter') { this.$emit('toogle-filter') }
            }
        }
    }
</script>