import Vue from 'vue'
import App from './App.vue'
import router from "./router/routes"
import store from "./store/store"

require('@/assets/scss/main.scss')

/*** sections components ***/
import roofs from './components/sections/roofs'
Vue.component('roofs', roofs)

import windows from './components/sections/windows'
Vue.component('windows', windows)

import walls from './components/sections/walls'
Vue.component('walls', walls)

import balconies from './components/sections/balconies'
Vue.component('balconies', balconies)

import documents from './components/sections/documents'
Vue.component('documents', documents)

// added for Yorks webinar
import hardEnergyData from './components/sections/hardEnergyData'
Vue.component('hardEnergyData', hardEnergyData)

//temp charts with fake data
import lineBarChart from './components/sections/lineBarChart'
Vue.component('lineBarChart', lineBarChart)
import dottedLineChart from './components/sections/dottedLineChart'
Vue.component('dottedLineChart', dottedLineChart)


import workInProgress from './components/sections/workInProgress'
Vue.component('workInProgress', workInProgress)

import sidebarLeft from './components/sidebarLeft'
Vue.component('sidebarLeft', sidebarLeft)

import sidebarRight from './components/sidebarRight'
Vue.component('sidebarRight', sidebarRight)

import chillNavbar from './components/chillNavbar'
Vue.component('chillNavbar', chillNavbar)

/*** chill components ***/
import inputGroup from './components/inputGroup'
Vue.component('inputGroup', inputGroup)

import userGroup from './components/userGroup'
Vue.component('userGroup', userGroup)

import chillTextField from './components/chillTextField'
Vue.component('chillTextField', chillTextField)

import chillText from './components/chillText'
Vue.component('chillText', chillText)

import chillButton from './components/chillButton'
Vue.component('chillButton', chillButton)

import chillRemoveButton from './components/chillRemoveButton'
Vue.component('chillRemoveButton', chillRemoveButton)

import chillCheckbox from './components/chillCheckbox'
Vue.component('chillCheckbox', chillCheckbox)

import chillDivider from './components/chillDivider'
Vue.component('chillDivider', chillDivider)

import chillIcon from './components/chillIcon'
Vue.component('chillIcon', chillIcon)

import chillImage from './components/chillImage'
Vue.component('chillImage', chillImage)

import chillSelect from './components/chillSelect'
Vue.component('chillSelect', chillSelect)


import gmapChill from './components/gmap'
Vue.component('gmapChill', gmapChill)

import chillFooter from './components/chillFooter'
Vue.component('chillFooter', chillFooter)

import chillIconSelect from './components/chillIconSelect'
Vue.component('chillIconSelect', chillIconSelect)

import chillSlider from './components/chillSlider'
Vue.component('chillSlider', chillSlider)

/*** modal components ***/
import chillModal from './components/modal/chillModal'
Vue.component('chillModal', chillModal)

import viewerImages from './components/modal/viewerImages'
Vue.component('viewerImages', viewerImages)

import viewerUser from './components/modal/viewerUser'
Vue.component('viewerUser', viewerUser)

import viewerEditData from './components/modal/viewerEditData'
Vue.component('viewerEditData', viewerEditData)

import inputGroupEnergyBalance from './components/inputGroupEnergyBalance'
Vue.component('inputGroupEnergyBalance', inputGroupEnergyBalance)

import viewerIconPicker from './components/modal/viewerIconPicker'
Vue.component('viewerIconPicker', viewerIconPicker)

import viewerAddBuilding from './components/modal/viewerAddBuilding'
Vue.component('viewerAddBuilding', viewerAddBuilding)

import viewerDelete from './components/modal/viewerDelete'
Vue.component('viewerDelete', viewerDelete)

import viewerImagePins from './components/modal/viewerImagePins'
Vue.component('viewerImagePins', viewerImagePins)

import buildingRenderer from './components/BuildingRenderer'
Vue.component('BuildingRenderer', buildingRenderer)

Vue.config.productionTip = false
 
//------------------------------------------------------------------------------
 
import {authUser} from "@/mixins/auth"
Vue.mixin(authUser)
import {readBuilding} from "@/mixins/readBuilding"
Vue.mixin(readBuilding)
import {updateBuilding} from "@/mixins/updateBuilding"
Vue.mixin(updateBuilding)
import {removeBuilding} from "@/mixins/removeBuilding"
Vue.mixin(removeBuilding)
import {createBuilding} from "@/mixins/createBuilding"
Vue.mixin(createBuilding)
import {filterBuildings} from "@/mixins/filterBuildings"
Vue.mixin(filterBuildings)

//------------------------------------------------------------------------------

Vue.prototype.locales = {
    "de": require("./locale/de.json"),
    "en": require("./locale/en.json"),
    "es": require("./locale/es.json"),
    "fr": require("./locale/fr.json"),
    "it": require("./locale/it.json"),
    "nl": require("./locale/nl.json"),
    "sv": require("./locale/sv.json"),
    "dev": require("./locale/dev.json"),
}

import firebase from 'firebase/compat/app';
var firebaseConfig = {
    apiKey: "AIzaSyB_GzM3jarEjGVjnlXS47dqiubRHyo31jY",
    authDomain: "buildingapp-be04d.firebaseapp.com",
    databaseURL: "https://buildingapp-be04d.firebaseio.com",
    projectId: "buildingapp-be04d",
    storageBucket: "buildingapp-be04d.appspot.com",
    messagingSenderId: "788408945185",
    appId: "1:788408945185:web:827cda8763d2674d673160",
    measurementId: "G-7KLC2NDPYB"
  };
firebase.initializeApp(firebaseConfig);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
