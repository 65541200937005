
export const windowKeys = [
    {
        key: 'opening_balustrade',
        source: 'window3x.png',
        helpText: 'window_category_opening_opening_balustrade'
    },
    {
        key: 'french_window',
        source: 'door3x.png',
        helpText: 'window_category_opening_french_window'
    },
    {
        key: 'empty_left',
        source: 'empty_left3x.png',
        helpText: 'window_left_proximity_empty_left'
    },
    {
        key: 'neighbors_left',
        source: 'neighbor-left3x.png',
        helpText: 'window_left_proximity_neighbors_left'
    },
    {
        key: 'concave_left',
        source: 'corner-left3x.png',
        helpText: 'window_left_proximity_concave_left'
    },
    {
        key: 'empty_right',
        source: 'window-right3x.png',
        helpText: 'window_right_proximity_empty_right'
    },
    {
        key: 'neighbors_right',
        source: 'neighbor-right3x.png',
        helpText: 'window_right_proximity_neighbors_right'
    },
    {
        key: 'concave_right',
        source: 'corner-right3x.png',
        helpText: 'window_right_proximity_concave_right'
    },
  ];