<template>
	<div>
        <input v-model="change" type="checkbox" :id="selenium">
        <chillText v-if="textValue" :value="textValue" />
	</div>
</template>

<script>
    export default {
        name: "chillCheckbox",
        props: ["value", "textValue", "selenium"],
        data() {
            return {
                change: this.value
            }
        },
        watch: {
            change: function(newValue) {
                this.$emit("input", newValue)
            }
        }
    }
</script>