<template>
    <div v-if="ifPinImage" class="viewer-images">
        <!-- I can seem to find where this is called. Probably here:viewerImagePins-->
        <div class="main-image">
            <img ref="paintOnImage" class="" :src="imageIndex === 0 ? value.parent[imageIndex] : value.pins[imageIndex - 1].uri" alt="image" >
        </div>
        <div class="image-carousel-box" >
            <span class="carousel-btn" @click="minus()"><chillIcon value="arrow_left" /></span>
            <div class="image-carousel" v-for="(val, i) of objectValue" :key="val">
                <img :class="imageIndex === i ? 'active-image' : ''" :src="val" alt="image" @click="imageIndex = i" >
            </div>
            <div ref="pointer"></div>
            <span class="carousel-btn" @click="plus()"><chillIcon value="arrow_right" /></span>
        </div>
    </div>

    <div v-else class="viewer-images">
        <div class="main-image">
            <img class="" :src="value[imageIndex]" alt="image" >
        </div>
        <div class="image-carousel-box" >
            <span class="carousel-btn" @click="minus()"><chillIcon value="arrow_left" /></span>
            <div class="image-carousel" v-for="(val, i) of value" :key="i+i">
                <img :class="imageIndex === i ? 'active-image' : ''" :src="val" alt="image" @click="imageIndex = i" >
            </div>
            <span class="carousel-btn" @click="plus()"><chillIcon value="arrow_right" /></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "viewerImages",
        props: ["value", "viewerIndex", "modalIconType"],
        data() {
            return {
                imageIndex: 0,
                pinsIndex: 0,
                pins: [],
                ifPinImage: false
            }
        },
        computed: {
            objectValue() {
                let tempList = []
                tempList.push(this.value.parent[0])

                this.value.pins.forEach((x) => {
                    tempList.push(x.uri)
                })
                return tempList
            }
        },
        methods: {
            plus() {
                if(this.ifPinImage && this.imageIndex >= (this.pinsIndex - 1) )
                    this.imageIndex = 0 
                else if(this.imageIndex >= (this.value.length - 1)  )
                   this.imageIndex = 0 
                else
                    this.imageIndex = this.imageIndex + 1
            },
            minus() {
                if(this.ifPinImage && this.imageIndex <= 0)
                     this.imageIndex = (this.pinsIndex - 1)
                else if(this.imageIndex <= 0  )
                   this.imageIndex = (this.value.length - 1)
                else
                    this.imageIndex = this.imageIndex - 1
            }
        },
        mounted() {
            this.imageIndex = this.viewerIndex
            if(Object.keys(this.value).length > 0 && this.value.parent) {
                this.pinsIndex = (this.value.parent?.length + this.value.pins?.length)
                this.ifPinImage = true
            } else {
                this.ifPinImage = false
            }
        }
    }
</script>