import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// import 'firebase/storage'; 

export const removeBuilding = {
	data() {
		return {
            client: localStorage.getItem('client'),
            fsLoadingRemove: false
		}
	},
	methods: {
        removeWall(buildingId, wallId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId)
            database.delete()
            .then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            })
        },
        removeFacade(buildingId, wallId, facadeId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId)
            database.delete()
            .then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            })
        },
        removeWindow(buildingId, wallId, facadeId, windowId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId).collection('windows').doc(windowId)
            database.delete()
            .then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            })
        },
        removeBalcony(buildingId, wallId, facadeId, balconyId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId).collection('balconies').doc(balconyId)
            database.delete()
            .then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            })
        },
        removeDocument(buildingId, documentId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('documents').doc(documentId)
            database.delete()
            .then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            })
        },
        removeRoof(buildingId, roofId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('roofs').doc(roofId)
            database.delete()
            .then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            })
        },
        // removeImageFromDB(imageId, key) {
        //     if(typeof key === 'string') {
        //         let databaseRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id);

        //         function getNestedProperty(obj, keys) {
        //             return keys.reduce((acc, key) => (acc && acc[key] !== 'undefined') ? acc[key] : undefined, obj);
        //         }

        //         databaseRef.get().then(doc => {
        //             if (doc.exists) {
        //                 let buildingData = doc.data();
        //                 let nestedImages = getNestedProperty(buildingData, key.split('.'));
        //                 let indexToRemove = nestedImages.findIndex(image => image === imageId);
                        
        //                 if (indexToRemove !== -1) {
        //                     nestedImages.splice(indexToRemove, 1);
            
        //                     let updateObject = {};
        //                     updateObject[key] = nestedImages;
        //                     return databaseRef.update(updateObject);
        //                 } else {
        //                     console.error("Image not found in array.");
        //                 }
        //             } else {
        //                 console.error("Document not found.");
        //             }
        //         }).then(() => {
        //             console.log("Image removed successfully.");
        //         }).catch(error => {
        //             console.error("Error removing image:", error);
        //         });
        //     } else if (key[1] === 'facades' ) {
        //         let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3])

        //         database.get().then(doc => {
        //             if (doc.exists) {
        //                 let facadeData = doc.data();
        //                 let nestedImages = facadeData?.[key[0]];
        //                 let indexToRemove = nestedImages.findIndex(image => image === imageId);
                        
        //                 if (indexToRemove !== -1) {
        //                     nestedImages.splice(indexToRemove, 1);
        //                     return database.update(key[0], nestedImages);
        //                 } else {
        //                     console.error("Image not found in array.");
        //                 }
        //             } else {
        //                 console.error("Document not found.");
        //             }
        //         }).catch(error => {
        //             console.error("Error removing image:", error);
        //         });
        //     } else if (key[1] === 'windows' ) {
        //         let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]).collection("windows").doc(key[4])

        //         database.get().then(doc => {
        //             if (doc.exists) {
        //                 let windowData = doc.data();
        //                 let nestedImages = windowData?.[key[0]];
        //                 let indexToRemove = nestedImages.findIndex(image => image === imageId);
                        
        //                 if (indexToRemove !== -1) {
        //                     nestedImages.splice(indexToRemove, 1);
        //                     return database.update(key[0], nestedImages);
        //                 } else {
        //                     console.error("Image not found in array.");
        //                 }
        //             } else {
        //                 console.error("Document not found.");
        //             }
        //         }
        //         ).catch(error => {
        //             console.error("Error removing image:", error);
        //         });

        //     } else if (key[1] === 'balconies' ) {
        //         // TODO: remove image here
        //         let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]).collection("balconies").doc(key[4])

        //         database.get().then(doc => {
        //             if (doc.exists) {
        //                 let balconyData = doc.data();
        //                 let nestedImages = balconyData?.[key[0]];
        //                 let indexToRemove = nestedImages.findIndex(image => image === imageId);
                        
        //                 if (indexToRemove !== -1) {
        //                     nestedImages.splice(indexToRemove, 1);
        //                     return database.update(key[0], nestedImages);
        //                 } else {
        //                     console.error("Image not found in array.");
        //                 }
        //             } else {
        //                 console.error("Document not found.");
        //             }
        //         }
        //         ).catch(error => {
        //             console.error("Error removing image:", error);
        //         });
        //     } else {
        //         this.$toasted.show(this.translate("cant_remove_image"), {position:"bottom-right", duration:4000, type:"info"})
        //     }
        // }
        getNestedProperty(obj, keys) {
            return keys.reduce((acc, key) => (acc && acc[key] !== 'undefined') ? acc[key] : undefined, obj);
        },

        removeImageFromDB(imageId, key) {
            const handleError = (error) => console.error("Error removing image:", error);
            const showInfoToast = (message) => this.$toasted.show(this.translate(message), { position: "bottom-right", duration: 4000, type: "info" });
        
            // function getNestedProperty(obj, keys) {
            //     return keys.reduce((acc, key) => (acc && acc[key] !== 'undefined') ? acc[key] : undefined, obj);
            // }

            if(typeof key === 'string') {
                let buildingRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id);
             
                buildingRef.get().then(doc => {
                    if (doc.exists) {
                        let buildingData = doc.data();
                        let nestedImages = this.getNestedProperty(buildingData, key.split('.'));
                        let indexToRemove = nestedImages.findIndex(image => image === imageId);
                        
                        if (indexToRemove !== -1) {
                            nestedImages.splice(indexToRemove, 1);
            
                            let updateObject = {};
                            updateObject[key] = nestedImages;
                            return buildingRef.update(updateObject);
                        } else {
                            console.error("Image not found in array.");
                        }
                    } else {
                        console.error("Document not found.");
                    }
                }).then(() => {
                    console.log("Image removed successfully.");
                }).catch(error => {
                    handleError(error);
                });
            } else if (key[1] === 'facades' || key[1] === 'windows' || key[1] === 'balconies' || key[1] === 'roof') {
                
                let database
                if(key[1] === 'facades') {
                    database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3])
                } else if(key[1] === 'windows') {
                    database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]).collection("windows").doc(key[4])
                } else if(key[1] === 'balconies') {
                    database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]).collection("balconies").doc(key[4])
                } else if(key[1] === 'roof') {
                    database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("roofs").doc(key[2])
                }

                database.get().then(doc => {
                    if (doc.exists) {
                        let imageData = doc.data();
                        let nestedImages = imageData?.[key[0]];
                        let indexToRemove = nestedImages.findIndex(image => image === imageId);
                        
                        if (indexToRemove !== -1) {
                            nestedImages.splice(indexToRemove, 1);
                            return database.update(key[0], nestedImages);
                        } else {
                            console.error("Image not found in array.");
                        }
                    } else {
                        console.error("Document not found.");
                    }
                }
                ).catch(error => {
                    handleError(error);
                });
            } else {
                showInfoToast("cant_remove_image");
            }
        }
        
    }
}


