<template>
    <div v-if="!client_home || client_home == ''" class="no-client">
        <div class="no">
            <div v-if="fsLoadingRead" class="loader-row">
                <div class="loader"></div>
            </div>
        </div>
        <div class="client">
            <chillText  v-if="noClientText" value="not_approved_text" />
        </div>
    </div>
    <div v-else class="home-view">
        <div class="home-container">
            <div class="home-top-left">
                <h3><chillText value="welcome" /> <chillText :value="userName" />!</h3>
                <h4>{{reqistredBuildings}} <chillText value="registered_buildings" /></h4>
                <chillDivider text="actions" />
                <div class="home-actions">
                    <div v-if="fsUserObjekt && fsUserObjekt.role === 'admin'" class="custom-button" @click="addNew()" @close="showModal = false">
                        <chillIcon class="" value="market" />
                        <chillText value="create_new_building" shorten="false" />
                    </div>
                    <div class="custom-button" id="homeEditUser" @click="editUser()">
                        <chillIcon class="" value="account" />
                        <chillText value="edit_user" shorten="false" />
                    </div>
                </div>
            </div>
            <div class="home-top-right">
                <chillDivider text="latest_added_buildings" />
                <div class="home-recent-container">
                    <router-link class="home-recent-column neutral-link" v-for="(building, i) of fsrecentBuildings" :key="building.data+i" :to="'/building/'+building.id" :id="'homeRecent'+i + building.id">
                        <div class="home-recent-img"><chillImage :id="building.id + '_' + i + (building.street_view_image?.[0] ?? 'asd')" :value="building.street_view_image" type="header-or-breadcrums" :no_watch="true" /></div>
                        <div class="home-recent-information">
                            <div>
                                <chillText :value="building.data.general.city" />
                            </div>
                            <div>
                                <chillText :value="building.data.general.address" />
                                <chillText :value="building.data.general.zip" />
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <chillModal v-if="showModal" @close="showModal = false" :component="modalComponent" :value="modalData" type="small" />
    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                userName: '',
                client_home: null,
                limit: 4,
                showModal: false,
                modalData: '',
                modalComponent: ''
            }
        },
        watch: {
            fsUserObjekt: function(newVal) {
                if(this.fsUserObjekt && this.fsUserObjekt.client !== '') {
                    this.$store.commit('changeLang', newVal.language_web)
                    
                    localStorage.setItem( 'client', this.fsUserObjekt.client)
                    this.client_home = this.fsUserObjekt.client;
                    this.userName = this.fsUserObjekt.firstName;
                }
            },
            client_home: function() {
                if(this.client_home) {
                    this.fsLoadingRead = false
                    this.numberOfBuildings()
                    this.fsrecentBuildings = []
                    this.requestRecentData(this.limit)
                    
                }
            },
            fsLoadingRead: function() {
                if(this.fsLoadingRead === true) {
                    setTimeout(() => this.fsLoadingRead = false , 5000)
                }
            }
        },
        computed: {
            noClientText() {
                return (!this.fsLoadingRead && !this.client_home) ? true : false
            }
        },
        methods: {
            addNew() { 
                this.modalData = {}
                this.modalComponent = 'viewerAddBuilding'
                this.showModal = true
                console.log("decide where to use this. atm works, but not doing anything!")
                //this.createNewBuilding("3", "Läraregatan 3", "41133", "Göteborg", "sweden") 
            },
            editUser() {
                this.modalData = this.fsUserObjekt
                this.modalComponent = 'viewerUser'
                this.showModal = true
            }
        },
        mounted() {
            this.fsLoadingRead = true
            this.getUser()
        },
        beforeRouteLeave (to, from, next) {
            if( to.name === 'login' || to.name === 'building' || to.name === 'buildings' ){
                this.closeSnapshotHome()
            }
            setTimeout(function(){ next() }, 10)
        }
    }
</script>