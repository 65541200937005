<template>
    <div class="container">
        <div class="space-between-button">
            <chillText value="input_data" type="default-bold-text grayText" />
            <div v-if="hasGeneratedInputValues" @click="toggleContent" class="toggle-button">
                <chillIcon :value="showContent ? 'farrow_up' : 'farrow_down'"  class="whiteText" />
                <chillText :value="showContent ? 'hide_input_data' : 'show_input_data'" type="whiteText" />
            </div>
        </div>

        <div v-if="hasGeneratedInputValues">
            <div v-show="showContent">
                <div class="position-default">
                    <div class="titles-over-textfields">
                        <chillText value="energy_balance_name" type="default-text grayText" class="split-equal first-child-padding"  />
                        <chillText value="database_value" type="default-text grayText" class="split-equal" />
                        <chillText value="user_input_value" type="default-text grayText" class="split-equal" />
                    </div>
                    <div v-if="energyBalanceGeneratedInput && hasGeneratedInputValues" class="energy-balance">
                        <div v-for="(item, key) in sortedEnergyBalanceInput" :key="key" class="energy-balance-input-item">
                            <inputGroupEnergyBalance
                                :type="item.type"
                                :title="key"
                                :generatedValue="item.value"
                                :userValue="energyBalanceUserInput[key]"
                                :edit="edit"
                                :options="item.options"
                                @update="storeEnergyBalanceInput(key, $event)"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="edit" class="recalculate-div">
                    <button v-if="edit" @click="saveChanges" :disabled="loading">
                        <chillText value="save_and_recalc" type="whiteText" shorten="false" />
                    </button>
                </div>
                <div v-else class="testsson"></div>
            </div>
        </div>
        <!-- If there is no auto generated inputs -->
        <div v-else>
            <chillText value="no_generated_input_data_available" type="default-text grayText" />
        </div>


        <!-- Energy Balance graphs and annual heating -->
        <div class="margin-top">
            <chillText value="energy_balance" type="default-bold-text grayText" />
        </div>

        <div v-if="loadingSave" class="loading-text">
            <div class="loader"></div>
            <chillText value="loading_new_graph" type="grayText" />
        </div>

        <!-- Loading -->
        <div v-if="fsLoadingUpdate" class="loader-row">
            <div class="loader"></div>
        </div>

        <div v-show="!fsLoadingUpdate && !loadingSave">
            <div class="chart-container">
                <div id="chart" ref="chart"></div>
            </div>
            <div class="info-under-graf">
                <div  class="same-line-energybalance">
                    <chillText value="calculated_q_rad" type="default-bold-text" />
                    <p class="default-bold-text">: </p>
                    <div class="same-line-energybalance">
                        <p class="default-bold-text"> </p>
                        <chillText :value="loading ? '-- ' :energyBalanceAnnual?.calculated_q_rad ? energyBalanceAnnual?.calculated_q_rad.toFixed(2).replace(/[.,]00$/, '') : '-- '" type="default-text" />
                         <p class="default-text"> kWh</p>
                    </div>
                </div>
                <div class="same-line-energybalance">
                    <chillText value="calculated_per_m2_net_q_rad" type="default-bold-text" />
                    <p class="default-bold-text">: </p>
                        <div class="same-line-energybalance">
                            <p class="default-bold-text"> </p>
                            <chillText :value="loading ? '-- ' : energyBalanceAnnual?.calculated_per_m2_net_q_rad ? energyBalanceAnnual?.calculated_per_m2_net_q_rad.toFixed(2).replace(/[.,]00$/, '') : '-- '" type="default-text" />
                            <p class="default-text"> kWh/m&sup2;</p>
                        </div>
                </div>
            </div>
            <div class="recalculate-div">
                <button v-if="!edit" @click="makeNewChart" :disabled="loading">
                    <chillText value="function_firebase" type="whiteText" shorten="false" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'lineBarChart',
    props: ['series', 'energyBalanceGeneratedInput', 'energyBalanceUserInput', 'edit', 'title', 'energyBalanceAnnual'],
    watch: {
        series() {
            if (this.chart) this.createChart();
        },
        loadingSave(newValue) {
            if (!newValue) {
                this.$nextTick(() => {
                    if (this.chart) {
                        this.chart.resize();
                    }
                });
            }
        },
    },
    data() {
        return {
            allColors: {
                "q_rad": "#9E3333",
                "q_windows_gain": "#FFAD22",
                "q_internal_gain": "#446461",
                "q_basement_walls": "#0C3D25",
                "q_slab": "#505050",
                "q_roof": "#6B917E",
                "q_walls": "#AA7641",
                "q_windows_loss": "#185D8B",
                "q_vent": "#A0A0A0",
                "q_leak": "#B65454",
            },
            chart: undefined,
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    orient: 'horizontal',
                    bottom: -1,
                    type:'scroll'
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} kWh'
                    }
                },
                series: [],
            },
            fsLoadingUpdate: false,
            loading: false,
            loadingSave: false,
            localEnergyBalanceInput: { ...this.energyBalanceUserInput },
            showContent: false,
        };
    },
    computed: {
        sortedEnergyBalanceInput() {
        const customOrder = [
            "air_exchange_rate",
            "air_tightness",
            "efficiency_heat_exchanger",
            "power_other",
            "solar_gain_reduction_factor",
            "solar_heat_gain_coefficient",
            "temperature_setpoint",
            "thermal_capacity",
            "u_value_roof",
            "u_value_window",
            "u_value_wall",
            "u_value_wall_basement",
            "u_value_slab"
        ];
        const excludeKeys = ["building_data", "epw_file_name"];
        
        const typeMapping = {
            "thermal_capacity": { 
                type: "select", 
                options: ["empty", "very_light", "light", "medium", "heavy", "very_heavy"],
                values: {
                    "empty": null,
                    "very_light": 80000,
                    "light": 110000,
                    "medium": 165000,
                    "heavy": 260000,
                    "very_heavy": 370000
                }
            }
        };

        return Object.keys(this.energyBalanceGeneratedInput)
            .filter(key => !excludeKeys.includes(key) && this.energyBalanceGeneratedInput[key] !== undefined && this.energyBalanceGeneratedInput[key] !== null)
            .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b))
            .reduce((obj, key) => {
                const item = {
                    value: this.energyBalanceGeneratedInput[key],
                    type: typeMapping[key]?.type || "number",
                    options: typeMapping[key]?.options || [],
                    values: typeMapping[key]?.values || null
                };
                
                // Map numeric value to corresponding option string for "thermal_capacity"
                if (key === "thermal_capacity" && item.values) {
                    item.value = Object.keys(item.values).find(option => item.values[option] === item.value);
                }
                
                obj[key] = item;
                return obj;
            }, {});
    },
        hasGeneratedInputValues() {
            return Object.keys(this.energyBalanceGeneratedInput).length > 0;
        }
    },
    methods: {
        toggleContent() {
            this.showContent = !this.showContent;
        },
        start_deduct_data() {
            this.loading = true;
            this.$emit('loading-start');
            this.deduct_data_energy_balance(this.$route.params.id)
                .then(() => {
                    this.createChart();
                    this.$emit('loading-end');
                    this.loading = false;
                })
                .catch((error) => {
                    console.error('Error deducting data:', error);
                    this.$emit('loading-end');
                    this.loading = false;
                });
        },
        createChart() {
            const chartDom = this.$refs.chart;
            if (this.chart) {
                this.chart.dispose();
                this.chart = undefined;
            }
            this.chart = echarts.init(chartDom);

            const template = {
                // name: 'Heating self supplied',
                // type: 'bar',
                // stack: 'x',
                showSymbol: false,
                // data: [2500,4000,5200,5300,500,0,0,0,2000,5100,4000,2000],
                // itemStyle: { color: '#ffb8b8'},
                barWidth: '50%',
                lineStyle: {
                    width: 5,
                },
                tooltip: {
                    trigger: 'item',
                    valueFormatter: value => `${value} kWh`
                },
                series: []
            };

            let data = [];
            const sortingArr = ["q_internal_gain", "q_windows_gain", "q_rad", "q_slab", "q_basement_walls", "q_roof", "q_walls", "q_windows_loss", "q_vent", "q_leak"];
            const xAxis = [{
                type: 'category',
                data: ['month_jan', 'month_feb', 'month_mar', 'month_apr', 'month_may', 'month_jun', 'month_jul', 'month_aug', 'month_sep', 'month_oct', 'month_nov', 'month_dec'].map(this.translate)
            }];

            this.option.xAxis = xAxis;

            for (const sortString of sortingArr) {
                for (const d of this.$props.series) {
                    if (d.name === sortString) {
                        data.push(d);
                    }
                }
            }

            data = data.map(d => ({ ...d, ...{ itemStyle: this.get_item_style(d.name) } }));
            data = data.map(d => ({ ...d, ...template, ...(d.type === "bar" ? { stack: 'x' } : {}), name: this.translate(d.name) }));

            this.option.series = data;
            this.option && this.chart.setOption(this.option);
        },
        get_item_style(name) {
            const color = this.allColors[name] ?? "#fff000";
            return { color: color };
        },
        translate(str) {
            const language = this.$store.state.lang;
            if (typeof str === "number") {
                return str.toString();
            }
            if (!language || language === "dev") {
                return str;
            } else {
                const strLower = str.toLowerCase();
                if (this.locales[language]['ba_web'][strLower]) {
                    return this.locales[language]['ba_web'][strLower];
                }
            }
            return str;
        },
        storeEnergyBalanceInput(key, value) {
            this.$set(this.localEnergyBalanceInput, key, value);
        },
        saveChanges() {
            this.$emit('update-edit', false);
            this.showContent = false;
            this.loadingSave = true;
            this.$emit('update-loading-message', 'Saving user input, step 1/3');
            const finalInput = { ...this.localEnergyBalanceInput };


            for (const key in this.localEnergyBalanceInput) {
                let value = this.localEnergyBalanceInput[key];
                // if (this.sortedEnergyBalanceInput[key] && this.sortedEnergyBalanceInput[key].type === 'select') {
                //     value = value === "empty" ? null : this.sortedEnergyBalanceInput[key].values[value];
                // }
                if(this.sortedEnergyBalanceInput[key] && this.sortedEnergyBalanceInput[key].type === 'select') {
                    // value === "empty" ? null : this.sortedEnergyBalanceInput[key].values[value];
                    value = value === "empty" ? null : this.sortedEnergyBalanceInput[key].values[value];
                 }

                finalInput[key] = value;
            }

            // console.log('Final Input:', finalInput); 

            this.$emit('update-loading-message', 'Save user input, step 2/3');
            this.$emit('save-energy-balance-input', finalInput);
            this.startTimeout();
        },
        makeNewChart(){
            this.loadingSave = true;
            console.log('in makeNewChart')
            const done = this.$emit('recalculate-energy-balance')

            if(done){
                setTimeout(() => {
                    this.loadingSave = false;
                }, 4000)
            }
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async startTimeout() {
            await this.delay(5000);
            console.log('Timeout completed');
            this.makeNewChart()
        }
    },
    mounted() {
        this.createChart();
    },
}
</script>

<style lang="scss" scoped>
#chart {
    width: 100%;
    height: 100%;
}

.container {
    width: 100%;
    height: 100%;
}

.chart-container {
    margin-top: 0rem;
    margin-left: 20px;
    width: 100%;
    height: 600px;
    position: relative;
}

.recalculate-div {
    display: flex;
    justify-content: end;
    align-items: end;
    height: 45px;
}

.position-default {
    position: initial;
    /* margin-top: 8px; */
}

.split-equal { 
    width: 50%;
    box-sizing: border-box;
    /* padding: 8px 0px 8px 4px; */
}

.titles-over-textfields {
    /* border-top: solid #ecedf0 0.5px; */
    border-left: solid #ecedf0 0.5px;
    border-right: solid #ecedf0 0.5px;
    display: flex;
    width: 100%;
    padding: 8px 0px;

}

.first-child-padding {
    box-sizing: border-box;
    padding: 0px 0px 0px 4px;
}

.margin-top {
    margin-top: 40px;
    margin-bottom: -30px;
}

.energy-balance {
    border: solid #ecedf0 0.5px;
    border-bottom: solid transparent 0.5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.energy-balance-input-item {
    border-bottom: solid #ecedf0 0.5px;
    padding: 4px 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

button {
    background-color: #F6911B;
    border: 1px solid #F6911B;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    letter-spacing: 0.7px;
    margin: 20px 20px 0;
    padding: 4px 10px;
    text-align: center;
    width: 25%;
}

.testsson {
    height: 45px;
}

button:hover {
    cursor: pointer;
}

button:active {
    transform: scale(0.98);
}

.space-between-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid #ecedf0 0.5px;
    padding-bottom: 4px;
}

.toggle-button {
    cursor: pointer;
    background-color: #F6911B;
    border-radius: 4px;
    box-sizing: border-box;
    column-gap: 5px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 150px;
    padding: 2px 10px;
}

.loading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
</style>
