<template>
    <div>
        <div v-if="facade_condition_keys" style="width: 100%: height: 100%;">
            <span v-if="edit"><chillText type="title" value="facade_condition_info" /> </span>
            <img :src="getImgUrl(facade_condition_keys.image)" width="100%" height="250px" alt="">
            <div class="slider-boxes slider-box" v-if="edit">
                <chillText class="slider-text" value="100%" type="" />
                <vue-slider ref="slider" class="slider-tool-edit" v-model="facade_condition" @click.native="$emit('update', facade_condition)" @drag-end="$emit('update', facade_condition)" :enable-cross="true" direction="rtl" :min="1" :max="100"></vue-slider>
                <chillText class="slider-text" value="1%" type="" />
            </div>
            <div class="slider-boxes slider-box" v-if="!edit">
                <chillText class="slider-text" value="100%" type="grayText" />
                <vue-slider ref="slider" class="slider-tool" v-model="facade_condition" :disabled="true" :enable-cross="true" direction="rtl" :min="1" :max="100"></vue-slider>
                <chillText class="slider-text" value="1%" type="grayText" />
            </div>
            <div class="slider-boxes">
                <span>
                    <chillText type="title" value="facade_condition_procent" /> 
                    <chillText type="title" value=": " />
                    <chillText type="keyValue" :value="facade_condition" />
                    <chillText type="keyValue" value="%" />
                </span>
            </div>
            <div class="slider-boxes">
                <chillText type="keyValue" :value="facade_condition_keys.text" />
            </div>
            <div class="slider-boxes condition-estimate-years">
                <span>
                    <chillText type="title" value="facade_RSL_description" />
                    <chillText type="title" value=": " />
                    <chillText type="keyValue" :value="facade_condition_keys.RSLlow " /> 
                    <chillText type="keyValue" value=" - " /> 
                    <chillText type="keyValue" :value="facade_condition_keys.RLShigh " />
                    (<chillText type="keyValue" :value="facade_condition_keys.RSLdet " />)
                </span>
            </div>
            <div class="slider-boxes condition-estimate-years">
                <span>
                    <chillText type="title" value="RNM_description" />
                    <chillText type="title" value=": " />
                    <chillText type="keyValue" :value="facade_condition_keys.RNMlow " />
                    <chillText type="keyValue" value=" - " /> 
                    <chillText type="keyValue" :value="facade_condition_keys.RNMhigh " /> 
                </span>
            </div>
        </div>
    </div>
</template>
 

<script>
    import building_keys from '../assets/json/ba_key_structure.json'
    import vueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'
    
    export default {
        name: "chillSlider",
        components: {
            vueSlider
        },
        props: [ "value", "title", "edit", "facadeItem"],
        data() {
            return {
                content_keys: building_keys,
                facade_condition: 100,
                facade_condition_material: null,
                facade_condition_keys: null,
            }
        },
        watch: {
            facade_condition: function() {
                this.getContentKeys()
            },
            facadeItem: function() {
                this.getUsableKeys()
            },
        },
        methods: {
            getUsableKeys() {

                for(let i = 0; i < this.facadeItem.length; i++ ) {
                    this.facade_condition = this.facadeItem[i].facade.facade_condition ? this.facadeItem[i].facade.facade_condition : 100
                    this.facade_condition_material = this.facadeItem[i].facade.outer_material
                }
                this.getContentKeys()
            },
            getContentKeys() {
                if( this.title == 'facade_condition' && this.facade_condition_material) {
                    this.facade_condition_keys = this.content_keys[this.title]['data'][this.facade_condition_material][this.facade_condition]
                }
                // else if( this.title == 'roof' && this.facade_condition_material) {
                //     this.facade_condition_keys = this.content_keys[this.title]['data'][this.facade_condition_material][this.facade_condition]
                // }
                // else if( this.title == 'balcony' && this.facade_condition_material) {
                //     this.facade_condition_keys = this.content_keys[this.title]['data'][this.facade_condition_material][this.facade_condition]
                // }
            },
            getImgUrl(imageName) {
                if(imageName && this.title){
                    try{
                        require(`@/assets/${this.title}/${imageName}.jpeg`)
                        return require(`@/assets/${this.title}/${imageName}.jpeg`)
                    }catch(e){
                        console.log("no jpeg")
                    }

                    try{
                        require(`@/assets/${this.title}/${imageName}.jpg`)
                        return require(`@/assets/${this.title}/${imageName}.jpg`)
                    }catch(e){
                        console.log("no jpg")
                    }

                    try{
                        require(`@/assets/${this.title}/${imageName}.png`)
                        return require(`@/assets/${this.title}/${imageName}.png`)
                    }catch(e){
                        console.log("no png")
                    }
                    //return require(`@/assets/${this.title}/${imageName}.jpeg`)

                }
                else
                    return undefined
            }
        },
        mounted() {
            if( this.facadeItem.length > 0) {
                this.getUsableKeys()
            }
            this.valueSlider = this.value 
        }
    }
</script>



