<template>
    <div class="window-view">
        <div class="window-empty" v-if="edit">
            <div v-if="edit" class="create-new-window" @click="createWindow(wall.wallId, wall.facadeId, facadeWindows.length+1)"><chillText type="whiteText" value="create_new_window" /></div>
        </div>
        <div class="windows-section">
            <div class="window-container" v-for="(window, e) of getFacadeWindows(wall.facadeId)" :key="e">
                <div class="sticky-windows-header">
                    <div :id="'window_header_toggle_'+e" class="window-header" @click="expandShrinkWindow(window)">
                        <span class="expand-left"><chillIcon :value="windowExpandCheck(window.windowId) ? 'zoom_out_circle' : 'zoom_in_circle'" /></span>
                        <span class="text-centred">
                            <chillText value="windows" />: <chillText :value="window.windows.title" />
                        </span>
                        <span class="create-right warning" v-if="edit" @click="modalDelete(window.wallId, window.facadeId, window.windowId)"><chillText value="delete" /></span>
                    </div>
                </div>
                <div class="window-box" v-if="windowExpandCheck(window.windowId)">
                    <div class="window-content-box">
                        <div class="window-box-content" v-for="(contentKeys, i) in windowsSection" :key="i">
                            <div class="column-window-title">
                                <span class="column-window-title-text"><chillText type="grayText" :value="i == 0 ? 'photos' : 'primary_data'"/></span>
                            </div>
                            <div :class="'window-box-column-'+i" class="window-box-column">
                                <div class="window-box-row" v-for="(keyName, ii) of windowsStatementCheck(contentKeys, window)" :key="contentKeys+ii">
                                    <div class="window-row-content">
                                        <inputGroup
                                            :class="showBlock(keyName.split('.')[1]) ? 'show-block' : ''"
                                            :type="content_keys[keyName.split('.')[1]].type"
                                            :title="keyName.split('.')[1]"
                                            :options="content_keys[keyName.split('.')[1]].options"
                                            :category="content_keys[keyName.split('.')[1]].category"
                                            :value="windowsInputGroup(keyName, window)"
                                            :facadeId="window.facadeId"
                                            :wallId="window.wallId"
                                            :windowId="window.windowId"
                                            :edit="edit"
                                            :keyLocation="'windows'"
                                            @update="updateKeyWindow"
                                            @modal="modal"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="york-data-third-column">
                            <div class="column-window-title">
                                <span class="column-window-title-text"><chillText type="grayText" value="deducted_data"/></span>
                            </div>
                            <div class="row-deducted-data" v-for="(key, a) in deductedTab('window_title', wall.wallId, wall.facadeId, window.windowId)" :key="key+a" >
                                <div class="row-deducted-data-line">
                                    <inputGroup
                                        type="text"
                                        :title="key"
                                        :value="getDeductedData(key, wall.wallId, wall.facadeId, window.windowId)"
                                        :edit="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <chillModal
            v-if="showModal"
            component="viewerDelete"
            :value="modalDeleteId"
            modalIndex="window"
            type="small"
            @close="showModal = false"
        />
    </div>
</template>

<script>
    import building_keys from '../../assets/json/ba_key_structure.json'
    import window_structure from '../../assets/json/window_structure.json'
    import deducted_keys_CM2 from '../../assets/json/deducted_keys_AP'
    import deducted_structure_CM2 from '../../assets/json/deducted_structure_AP'
    export default {
        name: 'windows',
        props: [ "facadeWindows", "edit", "wall", "type", "deductWindow", "buildingData"],
        data() {
            return {
                content_keys: building_keys,
                window_structure: window_structure,
                deducted_keys: deducted_keys_CM2,
                deducted_structure: deducted_structure_CM2,
                windowsExpanded: [],
                showModal: false,
                modalDeleteId: '',
            }
        },
        watch:{
            edit: function() {},
        },
        computed: {
            windowsSection() { return this.window_structure.sections.map(section => section.content) },

        },
        methods: {
            showBlock(key) {
                if(key === 'window_category_opening' || key === 'window_left_proximity' || key === 'window_right_proximity')
                    return true
                else false
            },
            deductedTab(key, wallId, facadeId, windowId) {
                const deductedData = this.deducted_structure.tabs.filter(x =>  x.title == key ? x : null )
                const deductedKeys = deductedData.flatMap(x => x.sections.flatMap(z => z.content ) )
                return deductedKeys.filter(x => this.getDeductedData(x, wallId, facadeId, windowId) > 0 ? x : null )
            },
            getDeductedData(key, wallId, facadeId, windowId) {
                key = key.substring(0, key.lastIndexOf("_area"));
                if(this.buildingData?.deducted_data?.window_area?.[wallId]?.[facadeId]?.[windowId]?.[key]) {
                    // if(typeof this.buildingData.deducted_data.window_area[wallId][facadeId][windowId][key] !== 'number') { return '--' }
                    // else { return Math.round(this.buildingData.deducted_data.window_area[wallId][facadeId][windowId][key].toFixed(1) ) }
                    console.log(key, ": ", Math.round(this.buildingData.deducted_data.window_area[wallId][facadeId][windowId][key].toFixed(1) ))
                }
                let windowData = this.buildingData?.deducted_data_v2?.windows?.[windowId]?.[key]
                if(windowData){
                    if(typeof windowData !== "number") return '--'
                    return  Math.round(windowData.toFixed(1) )
                }
            },
            statement(expr, window) {
                if(expr === undefined) {return false}
                else if(window?.windows ){
                    if(expr.operator === "equal"){
                        return window.windows[expr.key] === expr.value
                    }else if(expr.operator === "not equals"){
                        return window.windows[expr.key] !== expr.value
                    }else if(expr.operator === "less than"){
                        return window.windows[expr.key] < expr.value
                    }else if(expr.operator === "greater than"){
                        return window.windows[expr.key] > expr.value
                    }else if(expr.operator === "exists" && window.windows[expr.key]){
                        return true
                    }
                }
                else if(expr.key === true) {return true}

                return false
            },
            windowsStatementCheck(content, window) {
                return content.filter(key => {
                    if(!this.content_keys[key.split('.')[1]].statements) { return key.split('.')[1] }
                    else {
                        return this.statement(this.content_keys[key.split('.')[1]].statements[0], window) ? key.split('.')[1] : null 
                    }
                })
            },
            facadeHaveWindows(wall) {
                const ifWindow = this.facadeWindows.filter(x => {
                    if(x.facadeId === wall.facadeId && x.windows)
                        return true
                    else 
                        return null
                })
                return ifWindow.length  >= 1 ? true : false
            },
            getFacadeWindows(facadeId) {
                return this.facadeWindows.filter(window => facadeId === window.facadeId ? window : null )
            },
            expandShrinkWindow(window) {      
                if( !this.windowsExpanded.includes(window.windowId) )
                    this.windowsExpanded.push(window.windowId)
                else
                    this.windowsExpanded = [...this.windowsExpanded.filter(x => x !== window.windowId)]
            },
            windowExpandCheck(windowId) {
                return this.windowsExpanded.includes(windowId) ? true : false
            },
            windowsInputGroup(key, wallObj) {
                return wallObj ? wallObj.windows[key.split('.')[1]] : undefined
            },
            updateKeyWindow(newValue, keyName, wallId, facadeId, windowId) {
                this.updateWindowKey(this.$route.params.id, newValue, keyName, wallId, facadeId, windowId)
            },
            modalDelete(wallId, facadeId, windowId) {
                this.modalDeleteId = {wallId: wallId, facadeId: facadeId, windowId: windowId}
                this.showModal = true
            },
            modal(image, index, component, iconType, roofId, wallId, facadeId, windowId) {
                this.$emit("modal", image, index, component, iconType, roofId, wallId, facadeId, windowId)
            },
            createWindow(wallId, facadeId, title) {
                this.createNewWindow(this.$route.params.id, wallId, facadeId, title)
            } 
        },
        mounted() {}
    }
</script>