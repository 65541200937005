<template>
    <div @click="$emit(validation === false ? '' : 'on-click')" class="white-bg">
        <chillIcon class="icon" :value="value" />
    </div>
</template>

<script>
export default {
    name: "chillRemoveButton",
    props: ["value", "validation", "selenium"]
    }
</script>

<style>
    .white-bg{
        background-color: white;
        border-radius: 50%;
        height: fit-content;
        width: 22px;
    }

</style>