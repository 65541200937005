import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export const filterBuildings = {
	data() {
		return {
            fsHeaderColumns: false,
            client: localStorage.getItem('client'),
            // snapshotListener
            filterListener: false,
		}
    },
	methods: {
        startHeaderKeysListener() {
            let vm = this
            let ref = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("filter").doc(this.getUserId())
            let listener = ref.onSnapshot((snapshot) => {
                if(snapshot.data() !== null && snapshot.data() && snapshot.data() !== '' && snapshot.data() !== undefined && snapshot.data().columns !== undefined){
                   vm.fsHeaderColumns = snapshot.data().columns
                } else {
                    vm.fsHeaderColumns = []
                }
            }, error => {console.log("requestColumns ERROR: ",error)})
            this.filterListener = listener
        },
        saveHeaderKey(key){
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("filter").doc(this.getUserId())
            key.forEach(key => {
                if(!this.fsHeaderColumns.includes(key)) {
                    database.update({ columns: firebase.firestore.FieldValue.arrayUnion(key) })
                    .then(function() {
                        console.log("column key successfully written!");
                    })
                }
            })
        },

        createNewFilterDoc(){
            console.log("create")
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("filter").doc(this.getUserId())
            database.set({ columns: [ "city", "address" ]}).then(function() { console.log("filter doc created")});
        },

        deleteHeaderKey(key){
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("filter").doc(this.getUserId())
            database.update({ columns: firebase.firestore.FieldValue.arrayRemove(key) })
            .then(function() {
                console.log("key successfully written!");
            }) 
        },
        saveHeaderKeysArray(list) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("filter").doc(this.getUserId())

            database.set({ columns: list })
            .then(function() {
                console.log("column key successfully written!");
            })
        }
    }
}