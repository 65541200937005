import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

export const updateBuilding = {
	data() {
		return {
            client: localStorage.getItem('client'),
            fsLoadingUpdate: false,
            fsProgress: null
		}
	},
	methods: {
        deduct_data(buildingId){ // on the main page!
            this.fsLoadingUpdate = true
            var functions = firebase.app().functions('europe-west3');
            var deduct_data = functions.httpsCallable('deductedDataV2');
            deduct_data({write:true, client:"client-"+this.client, building_id: buildingId})
            .then((result) => {
                console.log("result: ",  result)
                // alert("calucation completed: ", result)
                this.$toasted.show(this.translate("calculation_done"), {position:"bottom-right", duration:3000, type:"success"})
                this.fsLoadingUpdate = false
            }).catch((error) => {
                console.log("error: ", error)
                this.$toasted.show(this.translate("calculation_done"), {position:"bottom-right", duration:3000, type:"info"})
                this.fsLoadingUpdate = false
            });

        },
        deduct_data_emodied(buildingId) {
            this.fsLoadingUpdate = true;
            var functions = firebase.app().functions('europe-west3');
            var deduct_data = functions.httpsCallable('postProcess');
          
            return new Promise((resolve, reject) => {
              deduct_data({ write: true, client: "client-" + this.client, building_id: buildingId })
                .then((result) => {
                  console.log("result: ", result);
                  this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "success" });
                  this.fsLoadingUpdate = false;
                  resolve(result);
                })
                .catch((error) => {
                  console.error("error: ", error);
                  this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "info" });
                  this.fsLoadingUpdate = false;
                  reject(error);
                });
            });
          },
          deduct_data_energy_balance(buildingId) {
            return new Promise((resolve, reject) => {
                this.fsLoadingUpdate = true;
                var functions = firebase.app().functions('europe-west3');
                
                var deduct_data = functions.httpsCallable('deductedDataV2');
                deduct_data({ write: true, client: "client-" + this.client, building_id: buildingId })
                    .then((result) => {
                        console.log("result first: ", result);
                        this.callCloudFunctionApi("energyBalance", buildingId)
                            .then((res) => {
                                console.log("second first: ", res);
                                this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "success" });
                                this.fsLoadingUpdate = false;
                                resolve(res); // Resolve the outer promise with the result of the inner operation
                            })
                            .catch((error) => {
                                console.log("error2: ", error);
                                this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "info" });
                                this.fsLoadingUpdate = false;
                                reject(error); // Reject the outer promise with the error from the inner operation
                            });
                    })
                    .catch((error) => {
                        console.log("error1: ", error);
                        this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "info" });
                        this.fsLoadingUpdate = false;
                        reject(error); // Reject the outer promise with the error from the first operation
                    });
            });
        },
    
        //   deduct_data_energy_balance(buildingId) {
        //     return new Promise((resolve, reject) => {
        //       this.fsLoadingUpdate = true;
        //       var functions = firebase.app().functions('europe-west3');
              
        //       var deduct_data = functions.httpsCallable('deductedDataV2');
        //       deduct_data({ write: true, client: "client-" + this.client, building_id: buildingId })
        //         .then((result) => {
        //           console.log("result first: ", result);

        //         //   console.log({ write: true, building_id: buildingId, client: "client-" + this.client })

        //         fetch('https://europe-west3-buildingapp-be04d.cloudfunctions.net/energyBalance', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //             // body: '{"data": {"client": "client-chillservices", "building_id": "lPc1NjaizloSrWWYFx69", "write": true}}',
        //             body: JSON.stringify({
        //                 'data': {
        //                 'client': `client-${this.client}`,
        //                 'building_id': buildingId,
        //                 'write': true
        //                 }
        //             })
        //         }).then((res) => {
        //                   console.log("second first: ", res);
        //                   this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "success" });
        //                   this.fsLoadingUpdate = false;
        //                   resolve(res); // Resolve the outer promise with the result of the inner operation
        //                 })
        //                 .catch((error) => {
        //                   console.log("error2: ", error);
        //                   this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "info" });
        //                   this.fsLoadingUpdate = false;
        //                   reject(error); // Reject the outer promise with the error from the inner operation
        //                 });
                  
        //         //   var deduct_data2 = functions.httpsCallable('energyBalance');
                  
        //         //   deduct_data2({data:{ write: true, building_id: buildingId, client: "client-" + this.client }})
        //         //     .then((res) => {
        //         //       console.log("second first: ", res);
        //         //       this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "success" });
        //         //       this.fsLoadingUpdate = false;
        //         //       resolve(res); // Resolve the outer promise with the result of the inner operation
        //         //     })
        //         //     .catch((error) => {
        //         //       console.log("error2: ", error);
        //         //       this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "info" });
        //         //       this.fsLoadingUpdate = false;
        //         //       reject(error); // Reject the outer promise with the error from the inner operation
        //         //     });
        //         })
        //         .catch((error) => {
        //           console.log("error1: ", error);
        //           this.$toasted.show(this.translate("calculation_done"), { position: "bottom-right", duration: 3000, type: "info" });
        //           this.fsLoadingUpdate = false;
        //           reject(error); // Reject the outer promise with the error from the first operation
        //         });
        //     });
        //   },
        translate(str) {
            let language = this.$store.state.lang
            if (typeof str == "number") {
                return toString(str)
            } 

            if(!language){
                // this.sleep();
                // language = this.$store.state.lang
                return str
            }
            else if(language === "dev"){
                return str
            }
            else {
                let strLower = str.toLowerCase()
                if ( this.locales[language]['ba_web'][strLower] && this.locales[language][strLower] !== "" )
                    str = this.locales[language]['ba_web'][strLower]
            }
            return str
        },
        updateBuildingKey(buildingId, newValue, location, keyName) {
            console.log('in updateBudilingKey')
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId)
            if(location === 'none') {
                database.update({
                    [keyName] : newValue
                })
                .then(function() {
                    console.log("Document successfully written!");
                })
            } else {
                database.update({
                    [location+'.'+keyName] : newValue
                })
                .then(function() {
                    console.log("Document successfully written!");
                })
            }
        },
        updateWallKey(buildingId, newValue, keyName, wallId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId)
            database.update({
                [keyName]: newValue
            })
            .then(function() {
                console.log("Document successfully written!");
            })
        },
        updateRoofKey(buildingId, newValue, keyName, roofId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('roofs').doc(roofId)
            database.update({
                [keyName]: newValue
            })
            .then(function() {
                console.log("Document successfully written!");
            })
        },
        updateFacadeKey(buildingId, newValue, keyName, wallId, facadeId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId)
            database.update({
                [keyName]: newValue
            })
            .then(function() {
                console.log("Document successfully written!");
            })
        },
        updateWindowKey(buildingId, newValue, keyName, wallId, facadeId, windowId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId).collection('windows').doc(windowId)
            database.update({
                [keyName]: newValue
            })
            .then(function() {
                console.log("Document successfully written!");
            })
        },
        updateBalconyKey(buildingId, newValue, keyName, wallId, facadeId, balconyId) {
            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(buildingId).collection('walls').doc(wallId).collection('facades').doc(facadeId).collection('balconies').doc(balconyId)
            database.update({
                [keyName]: newValue
            })
            .then(function() {
                console.log("Document successfully written!");
            })
        },
        _generateId() {
            let id = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for (let i = 0; i < 20; i++) {
                id += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return id;
        },


        async uploadImage(file , key, old_array) {
            if (!old_array) old_array = [];
            let fileName = this._generateId() + "_" + file.name;
            let storageRef = firebase.storage().ref()
            let uploadTask = storageRef.child('buildingAgent/client-'+this.client+'/desktop_images/'+this.$route.params.id+'/'+fileName).put(file)
        
            return new Promise((resolve, reject) => {
                uploadTask.on('state_changed',
                    (snapshot) => {
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                console.log('Upload is running');
                                break;
                    }
                    },
                    (error) => { 
                        console.log('error while upload: ', error);
                        reject(error);
                    }, 
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                            console.log("UPLAODED COMPLETED! URL:", downloadURL)
                            let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("media")
                            try {
                                const result = await database.add({
                                    uri: downloadURL,
                                    created_at: firebase.firestore.FieldValue.serverTimestamp(),
                                    ref: 'buildingAgent/client-'+this.client+'/desktop_images/'+this.$route.params.id+'/'+fileName,
                                    size: file.size,
                                    type: "image",
                                    created_by: firebase.auth().currentUser.uid,
                                    upladed_from_portal: true
                                });
                                console.log(result.id, key, old_array)
                                if (typeof key === "string"){
                                    let parentDocRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id);
                                    await parentDocRef.update({ [key]: [...old_array, result.id] });
                                } else if (key[1] === "facades"){
                                    let parentDocRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]);
                                    await parentDocRef.update({ [key[0]]: [...old_array, result.id] });
                                } else if (key[1] === "windows"){
                                    let parentDocRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]).collection("windows").doc(key[4]);
                                    await parentDocRef.update({ [key[0]]: [...old_array, result.id] });
                                } else if (key[1] === "balconies"){
                                    let parentDocRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("walls").doc(key[2]).collection("facades").doc(key[3]).collection("balconies").doc(key[4]);
                                    await parentDocRef.update({ [key[0]]: [...old_array, result.id] });
                                } else if (key[1] === "roof"){
                                    let parentDocRef = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("roofs").doc(key[2]);
                                    await parentDocRef.update({ [key[0]]: [...old_array, result.id] });
                                } else {
                                    this.$toasted.show(this.translate("cant_upload_here"), {position:"bottom-right", duration:3000, type:"info"})
                                }
                                resolve();
                            } catch (err) {
                                console.error("Error while updating database:", err);
                                reject(err);
                            }
                        }).catch(error => {
                            console.error("Error while getting download URL:", error);
                            reject(error);
                        });
                    }
                );
            });
        },
        uploadPdfFiles(file, name) {
            let storageRef = firebase.storage().ref()
            let uploadTask = storageRef.child('buildingAgent/client-'+this.client+'/documents/'+this.$route.params.id+'/'+name).put(file)
            this.fsProgress = 0
            uploadTask.on('state_changed',
                (snapshot) => {
                    this.fsProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                    }
                    if(this.fsProgress === 100){
                        setTimeout(() => {
                            this.fsProgress = null
                        }, 4000)
                    }
                },
                (error) => { console.log('error while upload: ', error) }, 
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        let database = firebase.firestore().collection("buildingapp").doc('client-'+this.client).collection("buildings").doc(this.$route.params.id).collection("documents")
                        database.add({
                            name: name,
                            uri: downloadURL,
                            upload_at: firebase.firestore.FieldValue.serverTimestamp(),
                            size: file.size,
                            type: file.type
                        })
                        .then(() => {
                            console.log("column key successfully written!: ", downloadURL)
                        })
                        
                    })
                }
            )
        }
    }
}