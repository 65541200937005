<template>
    <div class="component-button">
        <div v-if="type === 'icon'" :id="selenium" class="icon-button">
            <chillIcon class="icon" :value="icon" />
            <chillText :value="value" shorten="false" />
        </div>
        <div v-else :id="selenium" :class="['button', type, (validation === false ? 'unvalid' : 'valid') ]" @click="$emit(validation === false ? '' : 'on-click')" >
            <chillText :value="value" type="whiteText" shorten="false" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "chillButton",
        props: ["type", "value", "validation", "icon", "selenium"],
    }
</script>