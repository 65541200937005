<template>
    <div class="map-view">
        <div :ref="mapCanvasId" :id="mapCanvasId" >
        </div>
    </div>
</template>

<script>
    export default {
        name: 'gmapChill',
        props: ["coordinates", "buildingDataWalls", "edit", "mapCanvasId"],
        data() {
            return {
                map: null,
                center: { lat: 57.703889, lng: 11.968879 },
                markersCoord: [],
                polyListNew: [],
                globalMarkers: [],
                globalDragMarker: [],
                globalPolyLines: [],
                infoWindowsGlobal: [],
                toBeDeletedId: '',
                allWallMarkers: [],
            }
        },
        watch: {
            buildingDataWalls: function() { this.updatePathList() },
            edit: function() {
                for(let i = 0; i < this.globalMarkers.length; i++){
                    this.globalMarkers[i].setMap(null)
                    this.globalMarkers[i] = null
                    this.globalMarkers.splice(i, 1)
                }
                this.globalMarkers = []
                this.initialize()
            },
            polyListNew: function(newValue, oldValue) {
                if(newValue.length > oldValue.length){
                    this.addNewWall(newValue, oldValue)
                }
                else if(newValue.length < oldValue.length) {
                    for(let i = 0; i < oldValue.length; i++) {
                        let removedWall = this.compareArrays(oldValue[i], newValue)
                        if( removedWall ) {
                            if(this.edit) {
                                let savedIndexofDragMarker = []
                                let polyIndex = i, markerIndex = i
                                for(let j = 0; j < this.globalDragMarker.length; j++) {
                                    // don't understand why I need to check this.globalDragMarker[j] for last index??????????
                                    if( this.globalDragMarker[j] && removedWall[0][3] === this.globalDragMarker[j].wallId) { savedIndexofDragMarker.push(j)  }
                                }
                                this.removeWallItems(polyIndex, markerIndex, savedIndexofDragMarker[0], savedIndexofDragMarker[1])
                            }
                            else {
                                let polyIndex = i, markerIndex = i
                                this.removeWallItems(polyIndex, markerIndex)
                            }
                        } 
                    }
                }
            }
        },
        methods: {
            compareArrays(oldValue, newValue) {
                let newValueId = []
                for(let i = 0; i < newValue.length; i++) {
                    newValueId.push(newValue[i][0][3])
                }
                return !newValueId.includes(oldValue[0][3]) ? oldValue : false
            },
            initialize() {
                let mapSettings
                if(this.mapCanvasId === 'map_canvas') { mapSettings = { center: new window.google.maps.LatLng(this.center.lat, this.center.lng), mapTypeId: 'satellite', tilt: 0, zoom: 19 } }
                else if(this.mapCanvasId === 'left_side_satellite') { mapSettings = {center: new window.google.maps.LatLng(this.center.lat, this.center.lng), mapTypeId: 'satellite', tilt: 0, zoom: 17.3, fullscreenControl: false } }
                else if(this.mapCanvasId === 'left_side_roadmap') { mapSettings = {center: new window.google.maps.LatLng(this.center.lat, this.center.lng), tilt: 0, zoom: 15, fullscreenControl: false } }
                
                this.map = new window.google.maps.Map(this.$refs[this.mapCanvasId], 
                    mapSettings
                )
                this.map.addListener("click", () => {
                    for (let i = 0; i < this.infoWindowsGlobal.length; i++) {
                        this.infoWindowsGlobal[i].close();
                    }
                })
                if(!this.edit && this.mapCanvasId === 'left_side_roadmap') { this.setCenterOnMap()}
                else if(!this.edit) { this.setStaticWalls() }
                else if(this.edit) { this.setDraggableWalls() }
            },
            setCenterOnMap() {
                let pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                let markerImage = {
                    path: pinSVGHole,
                    anchor: new window.google.maps.Point(12,17),
                    fillOpacity: 1,
                    fillColor: '#F6911B',
                    strokeWeight: 2,
                    strokeColor: "white",
                    scale: 1.7,
                }
                let centerMarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this.center.lat, this.center.lng),
                    icon: markerImage,
                    map: this.map,
                    color: 'yellow'
                })
                centerMarker.setMap(this.map)
            },
            addNewWall(newVal, oldVal) {
                function MVCArrayBinder(mvcArray) {
                    this.array_ = mvcArray
                }
                MVCArrayBinder.prototype = new window.google.maps.MVCObject()
                MVCArrayBinder.prototype.get = function(key) {
                    if (!isNaN(parseInt(key))) {
                        return this.array_.getAt(parseInt(key))
                    } else {
                        this.array_.get(key)
                    }
                }
                MVCArrayBinder.prototype.set = function(key, val) {
                    if (!isNaN(parseInt(key))) {
                        this.array_.setAt(parseInt(key), val)
                    } else {
                        this.array_.set(key, val)
                    }
                }
                let newWall = newVal.filter(o1 => {
                    return !oldVal.some(o2 => { 
                        return o1[0][3] === o2[0][3]
                    }) 
                });

                let savedCoordinates = []

                for(let i = 0; i < this.polyListNew.length; i++) {
                    for(let j = 0; j < this.polyListNew[i].length; j++) {
                        if(this.polyListNew[i][j][3] === newWall[0][0][3]) {
                            savedCoordinates.push(new window.google.maps.LatLng(this.polyListNew[i][j][0], this.polyListNew[i][j][1]))
                        }
                    }
                }
                let polyOptions = { strokeColor: 'blue', strokeOpacity: 1.0, strokeWeight: 3, path: savedCoordinates }
                let poly = new window.google.maps.Polyline(polyOptions)
                poly.binder = new MVCArrayBinder(poly.getPath())

                const markers = this.markerPlacer(savedCoordinates, newWall[0])

                markers[0].bindTo('position', poly.binder, (0).toString());
                markers[1].bindTo('position', poly.binder, (1).toString());
                
                poly.setMap(this.map); 
                this.globalPolyLines.push(poly)

                for(let l = 0; l < this.markersCoord.length; l++) {
                    if(this.markersCoord[l].wallId === newWall[0][0][3]) {
                        this.setMarker([this.markersCoord[l].lat, this.markersCoord[l].lng ], this.markersCoord[l].title, this.markersCoord[l].wallId)
                    }
                }

            },
            setStaticWalls() {
                this.globalPolyLines = []
                for(let i = 0; i < this.polyListNew.length; i++) {
                    let polyList = [ new window.google.maps.LatLng(this.polyListNew[i][0][0], this.polyListNew[i][0][1] ), new window.google.maps.LatLng(this.polyListNew[i][1][0], this.polyListNew[i][1][1] ) ]
                    let poly = new window.google.maps.Polyline({strokeColor: '#F6911B', strokeOpacity: 1.0, strokeWeight: 3, path: polyList})

                    poly.setMap(this.map);
                    this.globalPolyLines.push(poly)
                }
                // loop out markers on the map
                this.markersCoord.forEach(x => {
                    this.setMarker([x.lat, x.lng ], x.title, x.wallId)
                })

            },
            setDraggableWalls() {
                this.globalPolyLines = []
                this.globalDragMarker = []
                function MVCArrayBinder(mvcArray) {
                    this.array_ = mvcArray
                }
                MVCArrayBinder.prototype = new window.google.maps.MVCObject()
                MVCArrayBinder.prototype.get = function(key) {
                    if (!isNaN(parseInt(key))) {
                        return this.array_.getAt(parseInt(key))
                    } else {
                        this.array_.get(key)
                    }
                }
                MVCArrayBinder.prototype.set = function(key, val) {
                    if (!isNaN(parseInt(key))) {
                        this.array_.setAt(parseInt(key), val)
                    } else {
                        this.array_.set(key, val)
                    }
                }

                for(let i = 0; i < this.polyListNew.length; i++) {
                    let polyList = [ new window.google.maps.LatLng(this.polyListNew[i][0][0], this.polyListNew[i][0][1] ), new window.google.maps.LatLng(this.polyListNew[i][1][0], this.polyListNew[i][1][1] ) ]
                    let poly = new window.google.maps.Polyline({strokeColor: 'blue', strokeOpacity: 1.0, strokeWeight: 3, path: polyList})

                    poly.binder = new MVCArrayBinder(poly.getPath())

                    const markers = this.markerPlacer(polyList, this.polyListNew[i])
                    markers[0].bindTo('position', poly.binder, (0).toString());
                    markers[1].bindTo('position', poly.binder, (1).toString());
                        
                    poly.setMap(this.map);
                    this.globalPolyLines.push(poly)
                }
                // loop out markers on the map
                this.markersCoord.forEach(x => {
                    this.setMarker([x.lat, x.lng ], x.title, x.wallId)
                })

            },
            getWallByName(title){
                let re;
                this.buildingDataWalls.forEach(wall => {
                    if(wall.wall.title == title){
                        re = wall;
                    }
                });
                return [re];
            },
            stickTo(lat, lng, own){
                let re = [lat,lng];
                this.buildingDataWalls.forEach(wall => {
                    if(wall.wallId == own){
                        return
                    }
                    wall.wall.endCoordinate;
                    wall.wall.startCoordinate;
                    if(this.closeTo(lat,lng,wall.wall.endCoordinate.latitude,wall.wall.endCoordinate.longitude)){
                        re = [wall.wall.endCoordinate.latitude,wall.wall.endCoordinate.longitude]
                    }else if(this.closeTo(lat,lng,wall.wall.startCoordinate.latitude,wall.wall.startCoordinate.longitude)){
                        re = [wall.wall.startCoordinate.latitude,wall.wall.startCoordinate.longitude]
                    }
                });
                return re;
            },
            closeTo(lat,lng,lat2,lng2){
                let a = Math.sqrt( (lat-lat2)*(lat-lat2) + (lng-lng2)*(lng-lng2) )
                return a < 0.00001
            },
            getWallMarkerListPos(name, len){
                let pos;
                for(let i = 0; i< this.allWallMarkers.length; i++){
                    if(this.allWallMarkers[i].name == name && this.allWallMarkers[i].len == len){
                        pos = i;
                    }
                }
                return pos;
            },
            updateNextLocaly(titleWall, endToMove, stringCoordinate){
                let wallMarkerListPos;
                if(endToMove == "end"){
                    wallMarkerListPos = this.getWallMarkerListPos(String.fromCharCode(titleWall.charCodeAt(0) -1), 1);
                }else{
                    wallMarkerListPos = this.getWallMarkerListPos(String.fromCharCode(titleWall.charCodeAt(0) +1), 0);
                }
                let newPos = new window.google.maps.LatLng(JSON.parse(stringCoordinate).lat, JSON.parse(stringCoordinate).lng);

                this.allWallMarkers[wallMarkerListPos].setPosition(newPos);
                this.allWallMarkers[wallMarkerListPos].setVisible(false)
            },
            markerPlacer(event, wallObject, buildingId = this.$route.params.id) {
            // bind markers to each side of polyline
            let savedMarker = []
            for( let i = 0; i < event.length; i++ ) {
                let vm = this
                    let wallMarker = new window.google.maps.Marker({
                        position: event[i],
                        map: this.map,
                        len: i,
                        wallId: wallObject[i][3],
                        name: wallObject[i][2],
                        icon: { path: 'M256,320c-70.688,0-128-57.312-128-128c0-70.687,57.313-128,128-128c70.688,0,128,57.313,128,128C384,262.688,326.688,320,256,320z', fillColor: 'white', fillOpacity: 0.9, scale: 0.040, strokeColor: 'black', strokeWeight: 1, strokeOpacity: 1, anchor: new window.google.maps.Point(200, 200) },
                        draggable: true
                    })
                    vm.allWallMarkers.push(wallMarker);
                    /*
                    *
                    *
                    * inside domListener, Should find coordinate of nearest draggable marker (if there are any) and use them
                    * 
                    * 
                    */

                    window.google.maps.event.addDomListener(wallMarker, 'drag', function (e) {

                        let stringCoordinate = JSON.stringify(e.latLng)
                        let titleWall = wallObject[i][2]
                        if(i === 0){
                            if(titleWall != 'A'){
                                vm.updateNextLocaly(titleWall, "end", stringCoordinate);
                            }

                        }
                    })




                    window.google.maps.event.addDomListener(wallMarker, 'dragend', function (e) {
                        let stringCoordinate = JSON.stringify(e.latLng)
                        let newCoordinateToFb = {latitude: JSON.parse(stringCoordinate).lat, longitude: JSON.parse(stringCoordinate).lng}
                        let titleWall = wallObject[i][2]
                        let wallId = wallObject[i][3]
                        let wallObj =  vm.otherSideCoordinate(titleWall, 'start')


                        // let stick = false;
                        const LESSTHAN = 0.000005
                        if(vm.buildingDataWalls){
                            // let last_title = ""
                            // vm.buildingDataWalls.forEach(w => {
                            //     last_title = w.wall.title > last_title ? w.wall.title : last_title
                            // })
                            vm.buildingDataWalls.forEach(w => {
                                if(w.wall.title === "A"){
                                    let a_lat = w.wall.startCoordinate.latitude
                                    let a_lng = w.wall.startCoordinate.longitude
                                    if(Math.abs(a_lat-newCoordinateToFb.latitude) < LESSTHAN && Math.abs(a_lng-newCoordinateToFb.longitude) < LESSTHAN){
                                        newCoordinateToFb = {latitude:a_lat, longitude:a_lng}
                                        let newPos={lat:a_lat,lng:a_lng}
                                        vm.allWallMarkers[vm.allWallMarkers.length-1].setPosition(newPos)
                                    }
                                }
                                // }else if(w.wall.title === last_title){
                                //     let last_lat = w.wall.endCoordinate.latitude
                                //     let last_lng = w.wall.endCoordinate.longitude
                                //     if(Math.abs(last_lat-newCoordinateToFb.latitude) < LESSTHAN && Math.abs(last_lng-newCoordinateToFb.longitude) < LESSTHAN){
                                //         newCoordinateToFb = {latitude:last_lat, longitude:last_lng}
                                //         let newPos={lat:last_lat,lng:last_lng}
                                //         vm.allWallMarkers[0].setPosition(newPos)
                                        
                                //     }
                                // }
                            })
                        }
                        // STICK TO, BUT DONT RLY WORK!
                        // if(titleWall == 'A' && i == 0){
                        //     let stickTo = vm.stickTo(JSON.parse(stringCoordinate).lat, JSON.parse(stringCoordinate).lng, wallId);
                        //     let startNewCoordinateForMarker = [ stickTo[0], stickTo[1], wallObj[0].wall.endCoordinate.latitude, wallObj[0].wall.endCoordinate.longitude ];
                        //     let newCoordinateToFb2 = {latitude: stickTo[0], longitude: stickTo[1]}
                        //     vm.removePoint(titleWall, startNewCoordinateForMarker, wallObj)
                        //     vm.updateWallKey(buildingId, newCoordinateToFb2, 'startCoordinate', wallId )
                        //     let newStickToPos = new window.google.maps.LatLng(stickTo[0], stickTo[1]);
                        //     wallMarker.setPosition(newStickToPos);

                        // } else if (titleWall == String.fromCharCode(65+(vm.globalDragMarker.length/2)) && i == 1){

                        // }




                        if(i === 0) {
                            if(titleWall != 'A'){
                                let wallBefore = vm.getWallByName(String.fromCharCode(titleWall.charCodeAt(0) -1));
                                let BeforeEndCord = [wallBefore[0].wall.startCoordinate.latitude, wallBefore[0].wall.startCoordinate.longitude,JSON.parse(stringCoordinate).lat, JSON.parse(stringCoordinate).lng]

                                vm.removePoint(wallBefore[0].wall.title, BeforeEndCord, wallBefore)
                                vm.updateWallKey(buildingId, newCoordinateToFb, 'endCoordinate', wallBefore[0].wallId )

                                
                                vm.updateNextLocaly(titleWall, "end", stringCoordinate);
                                
                            }


                            let startNewCoordinateForMarker = [ JSON.parse(stringCoordinate).lat, JSON.parse(stringCoordinate).lng, wallObj[0].wall.endCoordinate.latitude, wallObj[0].wall.endCoordinate.longitude ]

                            vm.removePoint(titleWall, startNewCoordinateForMarker, wallObj)
                            vm.updateWallKey(buildingId, newCoordinateToFb, 'startCoordinate', wallId )
                        }
                        else if(i === 1) {
                            // i === 1 (endCoordinate)
                            // if(titleWall == String.fromCharCode(65+(vm.globalDragMarker.length/2) )){
                            //     let wallAfter = vm.getWallByName(String.fromCharCode(titleWall.charCodeAt(0) +1));
                            //     let BeforeStartCord = [JSON.parse(stringCoordinate).lat, JSON.parse(stringCoordinate).lng, wallAfter[0].wall.endCoordinate.latitude, wallAfter[0].wall.endCoordinate.longitude]

                            //     vm.removePoint(wallAfter[0].wall.title, BeforeStartCord, wallAfter)
                            //     vm.updateWallKey(buildingId, newCoordinateToFb, 'endCoordinate', wallAfter[0].wallId )
                                

                            //     vm.updateNextLocaly(titleWall, "start", stringCoordinate);
                                
                            // }
                            let endNewCoordinateForMarker = [ wallObj[0].wall.startCoordinate.latitude, wallObj[0].wall.startCoordinate.longitude, JSON.parse(stringCoordinate).lat, JSON.parse(stringCoordinate).lng ]
                            
                            vm.removePoint(titleWall, endNewCoordinateForMarker, wallObj)
                            vm.updateWallKey(buildingId, newCoordinateToFb, 'endCoordinate', wallId )
                        }
                        // if(stick){
                        //     // vm.setDraggableWalls()
                        //     // vm.edit()
                        //     console.log(e)
                        // }
                    })
                    this.globalDragMarker.push(wallMarker)
                    savedMarker.push(wallMarker)
                }
                return savedMarker
            },
            otherSideCoordinate(title, pointExcist){
                if(pointExcist === 'end') {
                    return this.buildingDataWalls.filter(x => x.wall.title === title  )
                } 
                else if(pointExcist === 'start') {
                    return this.buildingDataWalls.filter(x => x.wall.title === title )
                }
            },
            removeWallItems(polyIndex, markerIndex, dragMarkerIndexStart = false, dragMarkerIndexEnd = false) {

                if(this.edit){
                    this.globalPolyLines[polyIndex].setMap(null)
                    this.globalPolyLines[polyIndex] = null
                    this.globalPolyLines.splice(polyIndex, 1)

                    this.globalMarkers[markerIndex].setMap(null)
                    this.globalMarkers[markerIndex] = null
                    this.globalMarkers.splice(markerIndex, 1)
                    
                    this.globalDragMarker[dragMarkerIndexStart].setMap(null)
                    this.globalDragMarker[dragMarkerIndexEnd].setMap(null)
                    this.globalDragMarker[dragMarkerIndexStart] = null
                    this.globalDragMarker[dragMarkerIndexEnd] = null
                    this.globalDragMarker.splice(dragMarkerIndexStart, 1)
                    this.globalDragMarker.splice(dragMarkerIndexEnd, 1)
                }
                else if(!this.edit) {
                    this.globalPolyLines[polyIndex].setMap(null)
                    this.globalPolyLines[polyIndex] = null
                    this.globalPolyLines.splice(polyIndex, 1)

                    this.globalMarkers[markerIndex].setMap(null)
                    this.globalMarkers[markerIndex] = null
                    this.globalMarkers.splice(markerIndex, 1)

                }
            },
            removePoint(makerLabel, newPoints, wallObj) {
                for(let i = 0; i < this.globalMarkers.length; i++){
                    if(makerLabel === this.globalMarkers[i].label.text){
                        // variable to get position for marker
                        let points = this.middlePoint(wallObj[0].wallId, makerLabel, newPoints[0], newPoints[1], newPoints[2], newPoints[3])

                        // hide marker
                        this.globalMarkers[i].setMap(null)
                        // remove marker from array
                        this.globalMarkers[i] = null
                        // delete marker object from globalMarkers
                        this.globalMarkers.splice(i, 1)
                        
                        this.setMarker([points.lat, points.lng], makerLabel, wallObj.wallId)
                    }
                }                
            },
            setMarker(points, label, wallId) {
                let vm = this
                let infowindow = new window.google.maps.InfoWindow({ content: `<span onclick="deleteWall()" >remove_wall</span>` });
                this.infoWindowsGlobal.push(infowindow);
                window.deleteWall = () => {
                    vm.removeWall(vm.$route.params.id, vm.toBeDeletedId)
                    vm.toBeDeletedId = null
                    for (var i = 0; i < this.infoWindowsGlobal.length; i++) {
                        this.infoWindowsGlobal[i].close()
                    }
                }
                // position the middle/letters markers on the map
                let scaleSize = this.mapCanvasId === 'left_side_satellite' ? 5 : 10
                let fontSize = this.mapCanvasId === 'left_side_satellite' ? '8px' : '14px'

                let marker = new window.google.maps.Marker({
                    icon: {path: window.google.maps.SymbolPath.CIRCLE, fillColor: '#565760', fillOpacity: 0.9, scale: scaleSize, strokeColor: '#565760', strokeWeight: 1},
                    position: new window.google.maps.LatLng(points[0], points[1]),
                    map: this.map,
                    label: {color: 'white', text: label, fontSize: fontSize},
                    wallId: wallId
                })
                
                marker.addListener("click", () => {
                    if(!this.edit) {
                        this.$emit("marker-click", wallId, label)
                    } else if(this.edit) {
                        for (var i = 0; i < this.infoWindowsGlobal.length; i++) {
                            this.infoWindowsGlobal[i].close()
                        }
                        this.toBeDeletedId = wallId
                        infowindow.open(this.map, marker)
                    }
                });
                this.globalMarkers.push(marker)
                
                marker.setMap(this.map)
            },
            updatePathList() {
                // this.allWallMarkers = [];
                this.markersCoord = []
                this.polyListNew = []
                if(this.buildingDataWalls) {
                    for(let i = 0; i < this.buildingDataWalls.length; i++ ) {
                        let wall = this.buildingDataWalls[i].wall, startCoordinate = this.buildingDataWalls[i].wall.startCoordinate, endCoordinate = this.buildingDataWalls[i].wall.endCoordinate, wallId = this.buildingDataWalls[i].wallId                    
                        this.markersCoord.push(this.middlePoint(wallId, wall.title, startCoordinate.latitude, startCoordinate.longitude, endCoordinate.latitude, endCoordinate.longitude))
                        this.polyListNew.push([ [ startCoordinate.latitude, startCoordinate.longitude, wall.title, wallId ], [ endCoordinate.latitude, endCoordinate.longitude, wall.title, wallId] ])
                    }
                }
            },
            middlePoint(wallId, title, lat1, lng1, lat2, lng2) {    
                //-- Longitude difference
                let dLng = (lng2 - lng1) * Math.PI / 180
                //-- Convert to radians
                lat1 = lat1 * Math.PI / 180
                lat2 = lat2 * Math.PI / 180
                lng1 = lng1 * Math.PI / 180

                let bX = Math.cos(lat2) * Math.cos(dLng)
                let bY = Math.cos(lat2) * Math.sin(dLng)
                let lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY))
                let lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX)

                return { wallId: wallId, title: title, lat: lat3 * (180 / Math.PI), lng: lng3 * (180 / Math.PI) }
            }
        },
        mounted() {
            // set center coordinates for the map
            this.center.lat = this.coordinates.latitude,
            this.center.lng = this.coordinates.longitude

            if(this.buildingDataWalls) {
                for(let i = 0; i < this.buildingDataWalls.length; i++ ) {
                    let wall = this.buildingDataWalls[i].wall, startCoordinate = this.buildingDataWalls[i].wall.startCoordinate, endCoordinate = this.buildingDataWalls[i].wall.endCoordinate, wallId = this.buildingDataWalls[i].wallId                    
                    this.markersCoord.push(this.middlePoint(wallId, wall.title, startCoordinate.latitude, startCoordinate.longitude, endCoordinate.latitude, endCoordinate.longitude))
                    this.polyListNew.push([ [ startCoordinate.latitude, startCoordinate.longitude, wall.title, wallId ], [ endCoordinate.latitude, endCoordinate.longitude, wall.title, wallId] ])
                }
            }
            // call initialize when window is loaded
            window.google.maps.event.addDomListener(window, "load", this.initialize())
        }
    }

</script>

<style scoped>
#map_canvas {
    min-height: 400px;
    color: gray;
}
#left_side_satellite {
    height: 140px;
    color: gray;
}
#left_side_roadmap {
    height: 140px;
    color: gray;
}


</style>
