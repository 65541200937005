<template>
    <div class="login-view">
        <div class="login-container" style="height: 35%;">
            <div class="login-box">
                <div>
                    <h3><chillText value="request_new_password" /></h3>
                    <h6><chillText value="enter_you_email_to_reset_password" /></h6>
                </div>
                <div class="login-fields">
                    <div class="field"> 
                        <chillText value="email" />
                        <input type="email" v-model="email"  id="loginEmail" class="field-text email" />
                        <chillText v-if="message" :value="message"/>
                    </div>
                </div>
                <div class="button-box">
                    <chillButton value="request_new_password" type="primary inline" selenium="loginBtn" :validation="validation"  v-on:on-click="forgotPasswordLocal()" />
                </div>
                <div class="lang-box">
                    <div class="lang-box-content">
                        <chillText value="select_language"  />
                        <chillSelect :value="language" type="fixed" selenium="loginLanguage" :options="['en', 'de', 'fr', 'sv', 'es', 'it', 'nl']" @update="changeLang" />
                    </div>
                    <div class="forgot-password-content disabled">
                        <chillText style="cursor: pointer;" value="back" @click.native="$router.go(-1)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ForgotPasssword',
        data() {
            return {
                email: "",
                language: localStorage.getItem('language_web'),
                message: ""
            }
        },
        computed: {
            validation() {
                return this.email ? true : false
            }
        },
        methods: {
            changeLang(language) { this.$store.commit('changeLang', language) },
            updateBox(event) { this.accepted = event },
            async forgotPasswordLocal() {
                await this.forgotPassword(this.email)
                this.message = "email_has_been_sent_to_you"
            }
        }
    }
</script>
