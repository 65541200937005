<template>
    <div class="documents-view">
        <div v-if="edit" class="add-new-document">
            <div class="add-new-document-content">
                <div class="upload-doc-box">
                    <chillText type="title" value="file_to_upload" />
                    <input ref="inputFile" type="file" name="Upload" accept="application/pdf" @change="storeFile($event)" >
                </div>
                <div class="upload-doc-box">
                    <chillText type="title" value="name_your_file" />
                    <span><input type="text" :disabled="!fileToUpload" class="input-type-file" v-model="costumFileName" /><chillText type="grayText" value=".pdf" /></span>
                </div>
                <div class="upload-doc-box">
                    <chillButton
                        v-if="fsProgress === null"
                        type="primary"
                        :validation="fileToUpload ? true : false && costumFileName !== ''"
                        value="upload_file"
                        @on-click="fileToUpload && costumFileName !== '' ? uploadFile() : ''"
                    />
                    <span v-if="fsProgress !== null" class="progress-procent">
                        <chillText value="uploaded" /> <chillText :value="Math.floor(fsProgress)+'%'" />
                    </span>
                </div>
            </div>
        </div>
        <div v-if="!documents" class="add-new-document">
            <chillText class="add-new-document-content" value="info_no_documents_uploaded" />
        </div>
        <div v-for="(doc, i) in documents" :key="i" class="document-row">
            <div class="document-row-content" @click="readDocument(doc.document.uri)">
                <div class="document-row-column">
                    <span class="document-row-column-box">
                        <img class="" :src="require(`@/assets/img/pdf-icon.png`)" >
                    </span>
                </div>
                <div class="document-row-column">
                    <span class="document-row-column-box"> <chillText :value="doc.document.name" /> </span>
                </div>
            </div>
            <div v-if="edit" class="document-delete" @click="modalDelete(doc.documentId)"> <chillText type="warning" value="delete" /> </div>
        </div>
        <chillModal v-if="showModal"
            component="viewerDelete"
            :value="modalDeleteId"
            modalIndex="document"
            type="small"
            @close="showModal = false"
        />
    </div>
</template>

<script>
    import building_keys from '../../assets/json/ba_key_structure.json'
    import balcony_structure from '../../assets/json/balcony_structure.json'
    export default {
        name: 'documents',
        props: [ "buildingDocuments", "edit" ],
        data() {
            return {
                content_keys: building_keys,
                balcony_structure: balcony_structure,
                fileToUpload: null,
                costumFileName: '',
                showModal: false,
                modalDeleteId: ''
            }
        },
        watch: { 
            fsProgress: function() {
                if(this.fsProgress == 100) {
                    this.$refs.inputFile.value = '';
                    this.costumFileName = ''
                }
            }
        },
        computed: {
            documents() {
                if(this.buildingDocuments.length > 0 )
                    return this.buildingDocuments
                else
                    return null
            }
        },
        methods: {
            readDocument(uri) {
                window.open(uri);
            },
            storeFile(event) {
                this.fileToUpload = event.target.files[0]
                this.costumFileName = event.target.files[0].name
                
            },
            uploadFile() {
                let cleantext = this.costumFileName.replace('.pdf', '')
                this.uploadPdfFiles(this.fileToUpload, cleantext+'.pdf')
            },
            modalDelete(documentId) {
                this.modalDeleteId = {documentId: documentId}
                this.showModal = true
            }
        },
        mounted() { 
        }
    }
</script>