<template>
    <div class="icon-picker">
        <div class="icon-image-box">
            <div v-for="(image, key, i) of images" :key="i" class="icon-image" :class="activeIcon === trim(key) ? 'activeIcon' : ''">
                <span>{{i+1}}</span>
                <img :id="modalIconType+'_icon_'+i" :src="image" style="height: 200px; width: 200px;" @click="activeIcon = trim(key), activeIcon = trim(key)" type="large" />
            </div>
        </div>
        <div class="save">
            <chillButton 
                v-if="modalIconType === 'roof_type_add'"
                @on-click="$emit('close'), $emit('icon-type', activeIcon)"
                value="save"
                :selenium="modalIconType+'_save'"
                type="primary inline"
            />
            <chillButton 
                v-if="modalIconType !== 'roof_type_add'"
                @on-click="updateKey(activeIcon), $emit('close')"
                value="save"
                :selenium="modalIconType+'_save'"
                type="primary inline"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "viewerIconPicker",
        props: [ "value", "viewerIndex", "modalIconType", "buildingIds" ],
        data () {
            return {
                images: {},
                activeIcon: ''
            }   
        },
        watch: {
            activeIcon: function() {
            }
        },
        methods: {
            importAll(r) {
                let imgs = {}
                r.keys().forEach(key => {
                    
                    return (imgs[key] = r(key))
                })
                this.images = imgs

            },
            updateKey(name) {
                let ids = this.buildingIds
                if(ids.windowId) {
                    this.updateWindowKey(this.$route.params.id, name, this.viewerIndex+'_type', ids.wallId, ids.facadeId, ids.windowId)
                }
                else if(ids.balconyId) {
                    this.updateBalconyKey(this.$route.params.id, name, this.viewerIndex+'_type', ids.wallId, ids.facadeId, ids.balconyId)
                }
                else if(ids.facadeId) {
                    this.updateFacadeKey(this.$route.params.id, name, this.viewerIndex+'_type', ids.wallId, ids.facadeId)
                }
                else if(ids.roofId) {
                    this.updateRoofKey(this.$route.params.id, name, this.viewerIndex+'_type', ids.roofId)
                }
            },
            trim(key) {
                if(key) {
                    let first = key.replace('./','')
                    return first.replace('HQ.png', '')
                }
            }
        },
        mounted() {
            if(this.viewerIndex === 'roof') {this.importAll(require.context('@/assets/roof', true, /\.png$/))}
            if(this.viewerIndex === 'window') {this.importAll(require.context('@/assets/window', true, /\.png$/))}
            if(this.viewerIndex === 'balcony') {this.importAll(require.context('@/assets/balcony', true, /\.png$/))}
            
            this.activeIcon = this.trim(this.value)
        }
    }
</script>

<style scoped lang="scss">

</style>