<template>
    <div class="modal-view">
        <div class="modal-wrapper">
            <div v-if="type === 'large'" class="large-modal-container">
                <div id="modalExit" class="corner-button" @click="$emit('close')"><chillIcon class="close-icon"  value="close_filled" /></div>
                <div class="modal-body">
                    <component :is="component" :edit="edit" :value="value" :viewerIndex="modalIndex" :modalIconType="modalIconType" :buildingIds="buildingIds" :modalFacadeTitle="modalFacadeTitle" @close="close" @icon-type="newIconType" />
                </div>
            </div>
            <div v-if="type === 'small'" class="small-modal-container">
                <div id="modalExit" class="corner-button" @click="$emit('close')"><chillIcon class="close-icon"  value="close_filled" /></div>
                <div class="modal-body">
                    <component :is="component" :value="value" :viewerIndex="modalIndex" :modalIconType="modalIconType" :buildingIds="buildingIds" @close="close" />
                </div>
            </div>
        </div>
        <div id="modalOutsideExit" class="modal-background" @click="$emit('close')"></div>
    </div>
</template>

<script>
    export default {
        name: "chillModal",
        props: [ "component", "value", "modalIndex", "type", "modalIconType", "buildingIds", "modalFacadeTitle" , "edit"],
        data() {
            return {

            }
        },
         methods: {
            close() { this.$emit("close") },
            newIconType(iconName) { this.$emit("icon-type", iconName) }
        },
    }
</script>