<template>
    <div id="app">
        <chillNavbar />
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
        mounted() {
            this.authCheck()
        }
    }
</script>