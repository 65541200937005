<template>
    <div class="balcony-view">
        <div class="balcony-empty" v-if="edit">
            <div v-if="edit" class="create-new-balcony" @click="createBalcony(wall.wallId, wall.facadeId, balconyData.length+1)"><chillText type="whiteText" value="create_new_balcony" /></div>
        </div>
        <div class="balcony-section" >
            <div class="balcony-container" v-for="(balcony, e) of getBalconies(wall.facadeId)" :key="e">
                <div class="sticky-balcony-header">
                    <div :id="'balcony-header_toggle_'+e" class="balcony-header" @click="expandShrinkBalconies(balcony)">
                        <span class="expand-left"><chillIcon :value="balconyExpandCheck(balcony.balconyId) ? 'zoom_out_circle' : 'zoom_in_circle'" /></span>
                        <span class="text-centred">
                            <!-- balcony_exist have right translation but I think it should have an independent slug -->
                            <chillText value="balcony_exist" />: <chillText :value="balcony.balconies.title" />
                        </span>
                        <span class="create-right warning" v-if="edit" @click="modalDelete(balcony.wallId, balcony.facadeId, balcony.balconyId )"><chillText value="delete" /></span>
                    </div>
                </div>
                <div class="balcony-box" v-if="balconyExpandCheck(balcony.balconyId)">
                    <div class="balcony-content-box">
                        <div class="balcony-box-content" v-for="(contentKeys, iiiiii) in balconiesSection" :key="iiiiii">
                            <div class="column-balcony-title">
                                <span class="column-balcony-title-text"><chillText type="grayText" :value="iiiiii === 0 ? 'photos' : 'primary_data' "/></span>
                            </div>
                            <div :class="'balcony-box-column-'+iiiiii" class="balcony-box-column">
                                <div class="balcony-box-row" v-for="(keyName, iiiiiii) of balconiesStatementCheck(contentKeys, balcony.facadeId)" :key="contentKeys+iiiiiii">
                                    <div class="balcony-row-content">
                                        <inputGroup
                                            :class="showBlock(keyName.split('.')[1]) ? 'show-block' : ''"
                                            :type="content_keys[keyName.split('.')[1]].type"
                                            :title="keyName.split('.')[1]"
                                            :options="content_keys[keyName.split('.')[1]].options"
                                            :category="content_keys[keyName.split('.')[1]].category"
                                            :value="balconiesInputGroup(keyName, balcony)"
                                            :facadeId="balcony.facadeId"
                                            :wallId="balcony.wallId"
                                            :balconyId="balcony.balconyId"
                                            :edit="edit"
                                            :keyLocation="'balconies'"
                                            @update="updateKeyBalcony"
                                            @modal="modal"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="york-data-third-column">
                            <div class="column-balcony-title">
                                <span class="column-balcony-title-text"><chillText type="grayText" value="deducted_data"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <chillModal
            v-if="showModal"
            component="viewerDelete"
            :value="modalDeleteId"
            modalIndex="balcony"
            type="small"
            @close="showModal = false"
        />
    </div>
</template>

<script>
    import building_keys from '../../assets/json/ba_key_structure.json'
    import balcony_structure from '../../assets/json/balcony_structure.json'
    export default {
        name: 'balconies',
        props: [ "buildingDataFacades", "balconyData", "edit", "wall", "type" ],
        data() {
            return {
                content_keys: building_keys,
                balcony_structure: balcony_structure,
                balconiesExpanded: [],
                showModal: false,
                modalDeleteId: ''
            }
        },
        watch:{
            edit: function() {}
        },
        computed: {
            balconiesSection() { return this.balcony_structure.sections.map(section => section.content) },

        },
        methods: {
            showBlock(key) {
                if(key === 'balcony_position' || key === 'balcony_construction' || key === 'balcony_fixation')
                    return true
                else false
            },
            statementDeep(expr, facadeId) {
                const facade = this.buildingDataFacades.filter(x =>  x.facadeId === facadeId ? x : null )
                return facade[0]['facade'][expr] && facade[0]['facade'][expr] !== 'false' ? true : false
            },
            statement(expr, facadeId) {
                if(expr === undefined) {return false}
                else if(expr === true) {return true}
                else if(this.statementDeep(expr, facadeId)) {return true}

                return false
            },
            balconiesStatementCheck(content, facadeId) {
                return content.filter(key => {
                    if(!this.content_keys[key.split('.')[1]].statements) { return key.split('.')[1] }
                    else { return this.statement(this.content_keys[key.split('.')[1]].statements[0].key, facadeId) ? key.split('.')[1] : null }
                })
            },
            balconyExist(wall) {
                const ifBalcony = this.balconyData.filter(x => {
                    if(x.facadeId === wall.facadeId && x.balconies)
                        return true
                    else 
                        return null
                })
                return ifBalcony.length  >= 1 ? true : false
            },
            getBalconies(facadeId) {
                return this.balconyData.filter(balcony => facadeId === balcony.facadeId ? balcony : null )
            },
            expandShrinkBalconies(balcony) {      
                if( !this.balconiesExpanded.includes(balcony.balconyId) )
                    this.balconiesExpanded.push(balcony.balconyId)
                else
                    this.balconiesExpanded = [...this.balconiesExpanded.filter(x => x !== balcony.balconyId)]
            },
            balconyExpandCheck(balconyId) {
                return this.balconiesExpanded.includes(balconyId) ? true : false
            },
            balconiesInputGroup(key, wallObj) {
                return wallObj ? wallObj.balconies[key.split('.')[1]] : undefined
            },
            updateKeyBalcony(newValue, keyName, wallId, facadeId, balconyId) {
                this.updateBalconyKey(this.$route.params.id, newValue, keyName, wallId, facadeId, balconyId)
            },
            modalDelete(wallId, facadeId, balconyId) {
                this.modalDeleteId = {wallId: wallId, facadeId: facadeId, balconyId: balconyId}
                this.showModal = true
            },
            modal(image, index, component, iconType, roofId, wallId, facadeId, windowId, balconyId) {
                this.$emit("modal", image, index, component, iconType, roofId, wallId, facadeId, windowId, balconyId)
            },
            createBalcony(wallId, facadeId, title) {
                const facade = this.buildingDataFacades.filter(x => x.facadeId === facadeId)
                let balconyExist = facade[0].facade.balcony_exist
                this.createNewBalcony(this.$route.params.id, wallId, facadeId, title, balconyExist)
            } 
        },
        mounted() {}
    }
</script>