<template>
    <div id="3d-building-container"></div>
  </template>
  
  <script>
  import * as THREE from 'three';
  // If you're using Axios for API calls
//   import axios from 'axios';
  
  export default {
    name: 'BuildingRenderer',
    mounted() {
      this.loadBuildingData();
    },
    methods: {
      async loadBuildingData() {
        try {
        //   const response = await axios.get('YOUR_API_URL');
        //   const data = response.data;
        const data = [
      [11.9516620, 57.6998959],
      [11.9518678, 57.6999168],
      [11.9518983, 57.6998304],
      [11.9519607, 57.6996535],
      [11.9519958, 57.6995539],
      [11.9514949, 57.6995030],
      [11.9514935, 57.6995073],
      [11.9514620, 57.6996069],
      [11.9517182, 57.6996327],
      [11.9517095, 57.6996581],
      [11.9517501, 57.6996625],
      [11.9517135, 57.6997675],
      [11.9514125, 57.6997376],
      [11.9514006, 57.6997704],
      [11.9513800, 57.6998271],
      [11.9516766, 57.6998605],
      [11.9516620, 57.6998959]
    ]
          this.renderBuilding(data);
        } catch (error) {
          console.error('Error fetching building data:', error);
        }
      },
      renderBuilding(polygonData) {
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xffffff); // Set a background color

      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 5; // Adjust the camera position

      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.getElementById('3d-building-container').appendChild(renderer.domElement);

      // Add lighting
      const ambientLight = new THREE.AmbientLight(0x404040); // Soft white light
      scene.add(ambientLight);

      // Create building geometry
      const buildingHeight = 50;
      const shape = new THREE.Shape();
      polygonData.forEach(([lng, lat], index) => {
        const x = lng - polygonData[0][0]; // Adjust coordinates to be relative
        const y = lat - polygonData[0][1]; // Adjust coordinates to be relative
        if (index === 0) {
          shape.moveTo(x, y);
        } else {
          shape.lineTo(x, y);
        }
      });

      const extrudeSettings = { depth: buildingHeight, bevelEnabled: false };
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 }); // Bright color for visibility
      const mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      // Rendering loop
      function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }
      animate();
    }
  }
};
</script>

  
  <style lang="scss" scoped>
  #3d-building-container {
    width: 100%;
    height: 100vh;
  }
  </style>
  