export const chart_data = {
    embodied_carbon: [
        {
            name: "embodied_carbon",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
            // { value: 335, name: "concrete" },
            // { value: 310, name: "aluminum" },
            // { value: 234, name: "wood" },
            // { value: 135, name: "glass" },
            // { value: 1548, name: "plastic" },
            ],
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                },
            },
        },
    ],
    energy_gen: [
        {
            name: 'Heating self supplied',
            type: 'bar',
            // stack: 'x',
            data: [2500,4000,5200,5300,500,0,0,0,2000,5100,4000,2000],
            itemStyle: { color: '#ffb8b8'},
            // barWidth: '50%',
            // tooltip: {
            //     valueFormatter: value => {
            //         if(value > 0) return value + 'kWh'
            //     }
            // },
        },
        {
            name: 'Heating external electricity',
            type: 'bar',
            // stack: 'x',
            itemStyle: { color: '#fff7a9'},
            data: [10000,8000,6000,0,0,0,0,0,0,2000,7000,13000],
            // barWidth: '50%',
            // tooltip: {
            //     valueFormatter: value => {
            //         if(value > 0) return value + 'kWh'
            //     }
            // },
        },
        {
            name: 'Heat recovery ground',
            type: 'bar',
            // stack: 'x',
            itemStyle: { color: '#ffcd9a'},
            data: [0,0,0,3000,12000,11000,12500,10500,7500,0,0,0],
            // barWidth: '50%',
            // tooltip: {
            //     valueFormatter: value => {
            //         if(value > 0) return value + 'kWh'
            //     }
            // },
        },
        {
            name: 'Electricity self production (PV)',
            type: 'line',
            itemStyle: { color: '#B28080'},
            // showSymbol: false,
            // lineStyle: {
            //     width:5,
            // },
            data: [1000,1500,2000,2500,4000,3500,4000,3500,3000,2000,1000,500,300],
            // barWidth: '50%',
            // tooltip: {
            //     valueFormatter: value => {
            //         if(value > 0) return value + 'kWh'
            //     }
            // },
        },
        {
            name: 'External electricity',
            type: 'line',
            itemStyle: { color: '#7A7651'},
            // showSymbol: false,
            // lineStyle: {
            //     width:5,
            // },
            data: [4000,3000,2000,0,0,0,0,0,0,500,2500,4000],
            // barWidth: '50%',
            // tooltip: {
            //     valueFormatter: value => {
            //         if(value > 0) return value + 'kWh'
            //     }
            // },
        }
    ],
    balance: [
        {
            name: 'Solar gains',
            type: 'bar',
            stack: 'x',
            itemStyle: { color: '#fff7a9'},
            data: [-1000,-2000,-3500,-4500,-4900,-4800,-4900,-4600,-3000,-2000,-1000,-500],
            barWidth: '50%',
            tooltip: {
                valueFormatter: value => {
                    if(value !== 0) return value + 'kWh'
                }
            },
        },
        {
            name: 'Internal gains',
            type: 'bar',
            stack: 'x',
            data: [-2000,-2000,-2300,-2300,-2400,-2300,-2400,-2300,-2300,-2300,-2300,-2300],
            itemStyle: { color: '#ffcd9a'},
            barWidth: '50%',
            tooltip: {
                valueFormatter: value => {
                    if(value !== 0) return value + 'kWh'
                }
            },
        },
        {
            name: 'Transmission losses',
            type: 'bar',
            stack: 'x',
            data: [14800,13000,12000,8000,5200,4000,3000,3200,4900,8000,11000,14000],
            itemStyle: { color: '#A5A1B3'},
            barWidth: '50%',
            tooltip: {
                valueFormatter: value => {
                    if(value !== 0) return value + 'kWh'
                }
            },
        },
        {
            name: 'Transmission losses',
            type: 'bar',
            stack: 'x',
            data: [4500,4000,4000,3000,2000,1000,800,700,800,1000,2000,3000,4000],
            itemStyle: { color: '#E3DBFF'},
            barWidth: '50%',
            tooltip: {
                valueFormatter: value => {
                    if(value !== 0) return value + 'kWh'
                }
            },
        },
        {
            name: 'Heat Demand',
            type: 'line',
            data: [16000,13000,11000,6000,100,0,0,0,2000,6000,11000,15500],
            itemStyle: { color: '#47454D'},
            showSymbol: false,
            lineStyle: {
                width:5,

            },
            tooltip: {
                valueFormatter: value => {
                    if(value !== 0) return value + 'kWh'
                }
            },
        },
    ],
    decarb_strategy: {
        data: [
            [118, 28, 'Status quo', '2021'], [110, 26, 'New radiators', '2023'], [85, 21, 'Roof insulation', '2023'], [85, 7, 'Ground source heat pump', '2028'], [85, 5, 'Adjustment emission factor electricity', '2035'], [70, 4.5, 'New windows triple glazing', '2035'], [70, 2, 'Ground source heat pump', '2040'], [70, 1, 'Adjustment emission factor electricity', '2050']
        ],
        xAxis: {
            min: 0,
            max: 200,
            inverse: true,
            type: 'value',
        },
        yAxis: {
            position: 'right',
            min: 0,
            max: 50,
            type: 'value',
        },
    },
    decarb_roadmap: {
        step: 'end',
        data: [
            ['2021-01-01', 28, 'Status quo', '2021'], ['2023-01-01', 26, 'New radiators', '2023'], ['2023-01-01', 21, 'Roof insulation', '2023'], ['2023-01-01', 21, 'Roof insulation', '2023'], ['2028-01-01', 7, 'Ground source heat pump', '2028'], ['2035-01-01', 5, 'Adjustment emission factor electricity', '2035'], ['2035-01-01', 4.5, 'New windows triple glazing', '2035'], ['2040-01-01', 2, 'Ground source heat pump', '2040'], ['2050-01-01', 1, 'Adjustment emission factor electricity', '2050']
        ],
        xAxis: {
            type: 'time',
        },
        yAxis: {
            position: 'right',
            min: 0,
            max: 50,
            type: 'value',
        },
    },
    heating_costs: {
        label: {
            show:true,
            position:'top',
            formatter: function (param) {
                return param.data[3];
            },
        },
        data: [
            ['2020-01-01', 6, '', ''],
            ['2021-01-01', 6, '', ''],
            ['2022-01-01', 6, '', ''],
            ['2023-01-01', 5.1, 'New radiators and roof insulation', '2023'],
            ['2024-01-01', 5.1, '', ''],
            ['2025-01-01', 8, 'Ground heat pump and borehole', '2025'],
            ['2026-01-01', 8.5, '', ''],
            ['2027-01-01', 9, '', ''],
            ['2028-01-01', 9.5, '', ''],
            ['2029-01-01', 10, '', ''],
            ['2030-01-01', 10.2, '', ''],
            ['2031-01-01', 10.6, '', ''],
            ['2032-01-01', 10.7, '', ''],
            ['2033-01-01', 10.8, '', ''],
            ['2034-01-01', 10.9, '', ''],
            ['2035-01-01', 9.5, 'New windows triple glazing', '2035'],
            ['2036-01-01', 9.7, '', ''],
            ['2037-01-01', 9.8, '', ''],
            ['2038-01-01', 9.9, '', ''],
            ['2039-01-01', 10, '', ''],
            ['2040-01-01', 8, 'Ground source heat pump', '2040'],
            ['2041-01-01', 7.6, '', ''],
            ['2042-01-01', 7.4, '', ''],
            ['2043-01-01', 7.2, '', ''],
            ['2044-01-01', 7, '', ''],
            ['2045-01-01', 6.8, '', ''],
            ['2046-01-01', 6.6, '', ''],
            ['2047-01-01', 6.4, '', ''],
            ['2048-01-01', 6.2, '', ''],
            ['2049-01-01', 6, '', ''],
            ['2050-01-01', 5.8, '', ''],
        ],
        xAxis: {
            type: 'time',
        },
        yAxis: {
            position: 'right',
            min: 0,
            max: 30,
            type: 'value',
        },
    }
}