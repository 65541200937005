<template>
	<select
        :id="selenium"
		:class="type"
		tabindex="0"
		value="value"
		@change="updateValue($event.target.value)"
	>
		<option
			v-for="(option, o) in emptyOptionsIncluded"
			v-bind:key="o"
			v-bind:value="(typeof option === 'object' ? o : option)"
			v-bind:selected="(typeof option === 'object' ? o : option) === value"
		>
			<span v-if="typeof option === 'object' && option.title">{{ option.title }}</span>
			<chillText :value="option" />
		</option>
	</select>
</template>

<script>
    export default {
        name: "chillSelect",
        props: ["type", "value", "options", "selenium", "title"],
        data() {
            return {
                showDropdown: false,
                loading: false,
                width: 800
            }
        },
        created() {
            window.addEventListener("resize", this.handleResize)
            this.width = window.innerWidth
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize)
        },
        computed: {
            emptyOptionsIncluded() { 
                if(this.title === 'language_web' || this.title === 'number_buildings')
                    return this.options
                else
                    return [...' ', ...this.options] 
            }
        },
        methods: {
            updateValue(event) {
                // select change boolean type to string type, thats why parse here
                if(event == 'true' || event == 'false') 
                    this.$emit('update', JSON.parse(event))
                else
                    this.$emit('update', event)
            }
        },
        mounted() {
        }
    }
</script>