<template>
	<div class="chill-text-field">
		<input
			v-if="edit"
			v-bind:type="type"
			v-bind:value="value"
            @input="validateInput($event.target.value)"
            @blur="input($event.target.value)"
            :id="selenium"
            :class="type"
            class="text-field"
            :placeholder="placeholder"
			min="0"
		/>
		<div v-if="edit">
			<chillText :value="errors" :type="type === 'number' ? 'error-text-red' : ''" />
		</div>
	</div>
</template>

<script>
export default {
	name: "chillTextfield",
	props: [
		"type",
		"edit",
        "value",
        "selenium",
        "placeholder"
	],
	data() {
		return {
			errors: ""
		}
	},
	methods: {
		validateInput(event) {
            if(this.type == 'number') {
				if (event.startsWith('-')) {
                    this.errors = "negative_numbers_not_allowed";
                } else {
                    this.errors = "";
                }
			}
		},
		input(event) {
            if(this.type === 'number') {
                if(event === '') {
                    this.$emit("update", null);
                } else {
                    if (event.startsWith('-')) {
                        event = event.slice(1);
                    }
                    this.$emit("update", JSON.parse(event));
                }
            } else {
                this.$emit("update", event);
            }
        }
    },
    mounted() { }
}
</script>

