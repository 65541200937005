<template>
    <div class="user-group">
        <chillText v-if="title" class="title user-title" :edit="false" :value="title" type="title"/>

        <chillSelect v-if="type === 'select'" :edit="true" :value="value" :type="type" :options="options" :title="title" @update="update" />
        <chillTextField class="text-field" v-else-if="type === 'textField'"  :edit="true" :value="value" @update="update" />
        
        <chillText v-else type="keyValue" :value="value" />

    </div>
</template>

<script>
    export default {
        name: 'inputGroup',
        props: [ "value", "type", "title", "edit", "options" ],
        methods: {
            modal() {
    
            },
            
            update(newValue) {
                this.$emit("update", this.title, newValue)
            }
        },
        mounted() {
        }
    }
</script>