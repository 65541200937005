<template>
    <div class="login-view">
        <div class="login-container">
            <div class="login-box">
                <div>
                    <h3><chillText value="sign_up" /></h3>
                    <h6><chillText value="enter_login_credentials_and_accept_regulations_to_login" /></h6>
                </div>
                <div class="login-fields">
                    <div class="field"> 
                        <chillText value="email" />
                        <input class="field-text" v-model="email" selenium="loginEmail" type="email" edit="false" showTitle="email" />
                    </div>
                    <div class="field">
                        <chillText value="password" />
                        <input class="field-text" v-model="password" selenium="loginPassword" type="password" edit="false" showTitle="password" />
                    </div>
                </div>
                <div class="accept-box">
                    <chillCheckbox class="regulation-box" v-model="accepted" selenium="loginRegulations" textValue="" />
                    <a class="hyper-link" href="https://capsa-building.com/terms-of-service/" ><chillText value="accept_regulations" /></a>
                </div>
                <div class="button-box">
                    <chillButton value="sign_up" type="primary inline" selenium="loginBtn" :validation="validation" @on-click="signup()" />
                </div>
                <div class="lang-box">
                    <div class="lang-box-content">
                        <chillText value="select_language"  />
                        <chillSelect :value="language" type="fixed" selenium="loginLanguage" :options="['en', 'de', 'fr', 'sv', 'es', 'it', 'nl']" @update="changeLang" />
                    </div>
                    <div class="lang-box-signup">
                        <router-link selenium="signinBtn" class="home-recent-column neutral-link" to="/login/">
                            <chillButton value="login" type="secondary inline" />
                        </router-link>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Signup',
        data() {
            return {
                email: "",
                password: "",
                accepted: false,
                language: localStorage.getItem('language_web')
            }
        },
        computed: {
            validation() {
                return (this.password && this.email && this.accepted) ? true : false
            }
        },
        methods: {
            changeLang(language) { this.$store.commit('changeLang', language) }
        }
    }
</script>