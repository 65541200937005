<template>
    <div class="walls-view">
        <div class="map-facades-container">
            <div class="map-view">
                <div class="map">
                    <gmapChill :coordinates="buildingsCoordinates" :buildingDataWalls="buildingDataWalls" :edit="edit" @marker-click="setActiveWall" mapCanvasId="map_canvas" />
                </div>
                <div class="edit-wall-btn" v-if="edit">
                    <chillButton class="add-icon-btn" :value="editWalls ? 'edit_mode' : 'add_new_wall'" type="primary inline" @on-click="addNewWall()" />
                </div>
            </div>
            <div class="facades">
                <!-- <div class="facade-title" v-if="focusedWallId"><chillText value="deducted_data_in_wall" /> <chillText :value="focusedWallId.title"/> </div>
                 <div v-if="loadingUpdate" class="loader-row">
                    <div class="loader"></div>
                </div>
                <div class="deducted-data-walls" v-if="!loadingUpdate">
                    <div class="row-deducted-data" v-for="(key, p) in deductedTab('walls_title')" :key="p" >
                        <div class="row-deducted-data-line">
                            <inputGroup
                                type="text"
                                :title="key"
                                :value="getDeductedData(key, focusedWallId.wallId)"
                                :edit="false"
                            />
                        </div>
                    </div>
                    <div class="row-deducted-data" v-if="focusedWallId && deductedTab('walls_title').length === 0">
                        <chillText class="row-deducted-data-line" type="title" value="no_data_has_been_deducted" />
                    </div>
                </div> -->
                
                <div class="facade-box-header">
                    <span v-if="focusedWallId"><chillText value="facades_in_walls" /> <chillText :value="focusedWallId.title"/> </span>
                    <div class="create-new-facade facade-header-text" v-if="edit && focusedWallId" @click="createFacde()">
                        <chillText value="create_new_facade" />
                    </div>
                    <div v-if="buildingDataWalls.length == 0">
                        <chillText v-if="!edit" value="help_text_add_wall_not_edit" />
                        <chillText v-if="edit" value="help_text_add_new_wall" />
                    </div>
                    <div v-if="!focusedWallId && buildingDataWalls.length > 0">
                        <chillText value="help_text_no_active_wall" />
                    </div>
                </div>
                <div class="facades-list" v-for="(facade, a) of getWallFacades" :key="a">
                    <div :id="'facade_list_'+a" :class="facadeExpanded[0] === facade.facadeId ? 'active-facade' : '' " class="facade-list-box" @click="setActiveFacade(facade, getWallName(facade.facade.title))">
                        <div class="facade-list-text">
                            <span><chillText value="facade_name" />: {{facade.facade.title}}</span>
                            <!-- <span class="facade-item"><chillText value="created_at" />: <chillText :value="toDate(facade.facade.created_at)" /> </span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="walls-box-container" v-for="(wall, index) of getWallFacades" :key="wall.facadeId+index">
            <div class="sticky-walls-header">
                <div 
                    @click="expandShrinkFacade(wall)"
                    class="facade-header" 
                    :id="'facade_header_toggle_'+index"
                >
                    <span class="expand-left" ><chillIcon :value="expandCheck(wall.facadeId) ? 'zoom_out_circle' : 'zoom_in_circle'" /></span>
                    <div class="facade-header-text"><chillText value="facade_name" />: <chillText :value="getWallFacadeName(wall, index)" /></div>
                    <span class="create-right warning" v-if="edit" @click="modalDelete($route.params.id, wall.wallId, wall.facadeId )"><chillText value="delete" /></span>
                </div>
            </div>
            <div :class="expandCheck(wall.facadeId) ? '' : 'shrink'" class="facade-content-row"  v-if="wallHaveFacade(wall.facadeId)">
                <div class="facade-content-box">
                    <div class="walls-section" v-for="(content, iiii) in wallsSection" :key="iiii">
                        <div class="column-wall-title">
                            <span class="column-wall-title-text"><chillText type="grayText" :value="iiii == 0 ? 'photos' : 'primary_data'"/></span>
                        </div>
                        <div :class="'walls-container-'+iiii" class="walls-container">
                            <div class="facade-first-second-column" v-for="(key, iiiii) of wallsStatementCheck(content, wall.facadeId)" :key="key+iiiii">
                                <div class="facade-row">
                                    <inputGroup
                                        v-if="content_keys[key.split('.')[1]].type !== 'subcategory'"
                                        :type="content_keys[key.split('.')[1]].type"
                                        :title="key.split('.')[1]"
                                        :options="content_keys[key.split('.')[1]].options"
                                        :value="wallsInputGroup(key, wall)"
                                        :keyLocation="key.split('.')[0]"
                                        :edit="edit"
                                        :facadeItem="getCurrentFacade(wall.facadeId)"
                                        :facadeId="wall.facadeId"
                                        :wallId="wall.wallId"
                                        @modal="modal"
                                        @update="updateKey"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="york-data-third-column-walls">
                        <div class="column-wall-title">
                            <span class="column-wall-title-text"><chillText type="grayText" value="deducted_data"/></span>
                        </div>
                        <chillButton v-if="edit && !loadingUpdate" class="fbfunctionbutton" icon="edit" value="function_firebase" type="primary long inline" @on-click="$emit('deduct')"  />
                        <div v-if="loadingUpdate" class="loader-row">
                            <div class="loader"></div>
                        </div>
                        <div v-if="!loadingUpdate">
                            <div class="row-deducted-data" v-for="(key, p) in deductedTab('facade_title', wall.facadeId)" :key="p" >
                                <div class="row-deducted-data-line">
                                    <inputGroup
                                        type="text"
                                        :title="key"
                                        :value="getDeductedData(key, wall.facadeId)"
                                        :edit="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="(facade, a) of facadeData" :key="a">
                <windows
                    v-if="wall.facadeId === facade.facadeId && expandCheck(facade.facadeId)"
                    :facadeWindows="facadeWindows"
                    :edit="edit"
                    :wall="wall"
                    :deductWindow="deductWindowData"
                    :buildingData="buildingData"
                    @modal="modal"
                />
                <balconies
                    v-if="wall.facadeId === facade.facadeId && expandCheck(facade.facadeId)"
                    :buildingDataFacades="buildingDataFacades"
                    :balconyData="balconyData"
                    :edit="edit"
                    :wall="wall"
                    @modal="modal"
                />
            </div>
        </div>
            <chillModal
                v-if="showModal"
                component="viewerDelete"
                :value="modalDeleteId"
                modalIndex="wall"
                type="small"
                @close="showModal = false"
            />
    </div>
</template>

<script>
    import building_keys from '../../assets/json/ba_key_structure.json'
    import walls_structure from '../../assets/json/walls_structure.json'
    import deducted_keys_CM2 from '../../assets/json/deducted_keys_AP'
    import deducted_structure_CM2 from '../../assets/json/deducted_structure_AP'
    import moment from "moment"

    export default {
        name: 'walls',
        props: [ "buildingData", "buildingDataWalls", "buildingDataFacades", "facadeWindows", "balconyData", "edit", "loadingUpdate" ],
        data() {
            return {
                content_keys: building_keys,
                walls_structure: walls_structure,
                deducted_keys: deducted_keys_CM2,
                deducted_structure: deducted_structure_CM2,
                focusedWallId: null,
                facadeExpanded: [],
                editWalls: false,
                centerCoordinates: { lat: 0, lng: 0 },
                latestAddedWall: null,
                showModal: false,
                modalDeleteId: '',
                activeFacadeTitle: ''
            }
        },
        watch:{
            edit: function() {
                if (this.edit === false)
                    this.editWalls = false
            },
            buildingDataWalls: function() {
                if(this.buildingDataWalls.length > 0)
                    this.latestAddedWall = this.buildingDataWalls[this.buildingDataWalls.length - 1].wall
            }
        },
        computed: {
            deductWindowData() {
                return this.buildingData.deducted_data && this.buildingData.deducted_data.window_area ? this.buildingData.deducted_data.window_area : null
            },
            wallsSection() { return this.walls_structure.sections.map(section => section.content) },
            buildingsCoordinates() { return this.buildingData.geolocation },
            facadeData() { return this.buildingDataFacades.filter(facade => facade ) },
            getWallFacades() {
                return this.buildingDataFacades.filter(facade => {
                    if( this.focusedWallId && this.focusedWallId.wallId === facade.wallId ) { return facade }
                    else { return null }
                })
            },
        },
        methods: {
            deductedTab(key, facadeId) {
                if(this.focusedWallId) {
                    const deductedData = this.deducted_structure.tabs.filter(x =>  x.title == key ? x : null )
                    const deductedKeys =  deductedData.flatMap(x => x.sections.flatMap(z => z.content ) )
                    return deductedKeys.filter(x => this.getDeductedData(x, facadeId) > 0 ? x : null )
                }
                else { return null }
            },
            getDeductedData(key, facadeId) {
                key = key.substring(0, key.lastIndexOf("_area"));
                if(facadeId){
                    let d = this.buildingData?.deducted_data_v2?.facades?.[facadeId] 
                    if(d && d[key] && typeof d[key] === "number"){
                        return d[key]
                    }
                    return "--"
                }
                // if(this.buildingData.deducted_data.wall_area[this.focusedWallId.wallId][key] && typeof this.buildingData.deducted_data.wall_area[this.focusedWallId.wallId][key] == 'number') {
                //     return ( this.buildingData.deducted_data.wall_area[this.focusedWallId.wallId][key].toFixed(1) )
                // }
                // else if(this.buildingData.deducted_data.facade_area[this.focusedWallId.wallId][facadeId] && this.buildingData.deducted_data.facade_area[this.focusedWallId.wallId][facadeId][key]) {                        
                //     return  this.buildingData.deducted_data.facade_area[this.focusedWallId.wallId][facadeId][key].toFixed(1)
                // }
            },
            getDeductedData_old(key, facadeId) {
                // if working for now, need to be updated when we have more data
                if(this.buildingData.deducted_data && this.buildingData.deducted_data.wall_area) {
                    console.log()
                    if(this.buildingData.deducted_data.wall_area[this.focusedWallId.wallId][key] && typeof this.buildingData.deducted_data.wall_area[this.focusedWallId.wallId][key] == 'number') {
                        return ( this.buildingData.deducted_data.wall_area[this.focusedWallId.wallId][key].toFixed(1) )
                    }
                    else if(this.buildingData.deducted_data.facade_area[this.focusedWallId.wallId][facadeId] && this.buildingData.deducted_data.facade_area[this.focusedWallId.wallId][facadeId][key]) {                        
                        return  this.buildingData.deducted_data.facade_area[this.focusedWallId.wallId][facadeId][key].toFixed(1)
                    }
                }
            },
            addNewWall() {
                let startCoordinate = {}
                let endCoordinate = this.latestAddedWall ?  { latitude: (this.latestAddedWall.endCoordinate.latitude + 0.0001), longitude: this.latestAddedWall.endCoordinate.longitude } : { latitude: (this.centerCoordinates.lat + 0.0001), longitude: this.centerCoordinates.lng }

                let title = String.fromCharCode(65 + this.buildingDataWalls.length)
                
                
                if(this.buildingDataWalls.length > 0) { startCoordinate = {latitude: this.latestAddedWall.endCoordinate.latitude, longitude: this.latestAddedWall.endCoordinate.longitude} }
                else { 
                    startCoordinate = { latitude: this.centerCoordinates.lat, longitude: this.centerCoordinates.lng } 
                }

                if(title && startCoordinate && endCoordinate)
                    this.createNewWall(this.$route.params.id, title, startCoordinate, endCoordinate)
            },
            statementDeep(expr, facadeId) {
                const facade = this.buildingDataFacades.filter(x => x.facadeId === facadeId ? x : null )
                return facade[0]['facade'][expr] && facade[0]['facade'][expr] !== 'false' ? true : false
            },
            statement(expr, facadeId) {
                if(expr === undefined) {return false}
                else if(expr === true) {return true}
                else if(this.statementDeep(expr, facadeId)) {return true}

                return false
            },
            wallsStatementCheck(content, facadeId) {
                return content.filter(key => {
                    if(key.split('.')[1] === 'subcategory' && key.split('.')[0] === 'balconies') {
                        return this.statementDeep('balcony_exist', facadeId) ? true : false
                    } else if(key.split('.')[1] === 'subcategory') {
                        return 'subcategory'
                    } else {
                        if(!this.content_keys[key.split('.')[1]].statements) { return key.split('.')[1] }
                        else { return this.statement(this.content_keys[key.split('.')[1]].statements[0].key, facadeId) ? key.split('.')[1] : null }
                    }
                })
            },
            wallsInputGroup(key, wallObj) {
                return wallObj ? wallObj.facade[key.split('.')[1]] : undefined
            },
            toDate(utcSeconds) { return utcSeconds ? moment.unix(utcSeconds.seconds).format('LLLL') : undefined },
            setActiveWall(wallId, wallTitle) { 
                this.focusedWallId = {wallId: wallId, title: wallTitle}
            },
            setActiveFacade(wall, wallTitle) { 
                this.facadeExpanded = [...[]]
                this.focusedWallId = {wallId: wall.wallId, title: wallTitle}
                this.expandShrinkFacade(wall)
            },
            getWallFacadeName(wall, index) {
                const response = this.buildingDataFacades.filter(facade => {
                    if(facade && facade.wallId === wall.wallId)
                        return facade
                    else
                        return undefined
                })
                let facadeName = response[0] ? response[index].facade.title : ''
                return facadeName
            },
            getWallName(facadeWall) {
                return facadeWall.charAt(0)
            },
            wallHaveFacade(facadeId) {
                if(this.expandCheck(facadeId)) {
                    const ifFacade = this.buildingDataFacades.filter(x => {
                        if(x.facadeId === facadeId)
                            return true
                        else 
                            return null
                    })
                    return ifFacade.length >= 1 ? true : false
                } else
                    return false
            },
            modalDelete(wallId, facadeId) {
                this.modalDeleteId = {wallId: wallId, facadeId: facadeId}
                this.showModal = true
            },
            modal(image, index, component, iconType, roofId, wallId, facadeId, windowId, balconyId) {
                this.$emit("modal", image, index, component, iconType, roofId, wallId, facadeId, windowId, balconyId, this.activeFacadeTitle)
            },
            expandShrinkFacade(wall) {
                // set active facade title for modal pins
                this.activeFacadeTitle = wall.facade.title

                if( !this.facadeExpanded.includes(wall.facadeId) )
                    this.facadeExpanded.push(wall.facadeId)
                else
                    this.facadeExpanded = [...this.facadeExpanded.filter(x => x !== wall.facadeId)]
            },
            expandCheck(facadeId) {
                return this.facadeExpanded.includes(facadeId) ? true : false
            },
            updateKey(newValue, keyName, wallId, facadeId) {
                this.updateFacadeKey(this.$route.params.id, newValue, keyName, wallId, facadeId)
            },
            createFacde() {
                const facades = this.buildingDataFacades.filter(facade => this.focusedWallId && this.focusedWallId.wallId === facade.wallId ? facade : null )
                this.createNewFacade(this.$route.params.id, this.focusedWallId.wallId, this.focusedWallId.title+(facades.length+1))
            },
            getCurrentFacade(facadeId) {
                return this.buildingDataFacades.filter(x => x.facadeId === facadeId ? x : null )
            },
        },
        mounted() {
            this.centerCoordinates = { lat: this.buildingData.geolocation.latitude, lng: this.buildingData.geolocation.longitude }
            if(this.buildingDataWalls.length > 0) 
                this.latestAddedWall = this.buildingDataWalls[this.buildingDataWalls.length - 1].wall
        }
    }
</script>