<template>
    <div class="chill-navbar">
        <div class="navbar-container">
            <div class="navbar-left">
                <div class="menu-logo">
                    <div class="logo-cont">
                        <router-link  v-if="!($route.name === 'login' || $route.name === 'signup' || $route.name === 'delete')" id="navBarHome" class="neutral-link" to="/">
                            <img class="logo" src="@/assets/img/CAPSA_logo.png" alt="building-logo">
                        </router-link>
                        <img v-if="($route.name === 'login' || $route.name === 'signup' || $route.name === 'delete')" class="logo" src="@/assets/img/CAPSA_logo.png" alt="building-logo">
                    </div>
                    <div class="version-name"><chillText value="version" />: {{version.version}}</div>
                </div>
                <div class="menu-links-box" v-if="!($route.name === 'login' || $route.name === 'signup' || $route.name === 'delete')">
                    <div :class="$route.name === 'home' ? 'buildings-active-border' : ''" class="menu-links">
                        <router-link class="neutral-link" id="navBarBuildingsHome" to="/">
                            <chillText :class="$route.name === 'home' ? 'buildings-active' : ''" class="route-btn" value="home" textType="firebase_keys" />
                        </router-link>
                    </div>
                    <div :class="$route.name === 'buildings' || $route.name === 'building' ? 'buildings-active-border' : ''" class="menu-links">
                        <router-link class="neutral-link" id="navBarBuildingsBuildings" to="/buildings">
                            <chillText :class="$route.name === 'buildings' ? 'buildings-active' : ''" class="route-btn" value="buildings" textType="firebase_keys" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-float-btn" v-if="!($route.name === 'login' || $route.name === 'signup')">
            <span class="neutral-link" id="navBarLogout" @click="logout()">
                <chillIcon class="marg-text" value="exit"  />
                <!-- <chillText class="marg-text" value="logout" textType="button" /> -->
            </span>

        </div>
    </div>
</template>

<script>
    import version_json from '../../version.json'
    export default {
        name: 'chillNavbar',
        data(){
            return {
                version: version_json
            }
        }
    }
</script>