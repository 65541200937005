import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
// import Settings from "../views/Settings.vue"
import Buildings from "../views/Buildings.vue"
import Building from "../views/Building.vue"
import Login from "../views/Login.vue"
import Signup from "../views/Signup.vue"
import ForgotPasssword from "../views/ForgotPassword.vue"
import DeleteAccount from "../views/DeleteAccount.vue"

import firebase from 'firebase/compat/app';

import Toasted from 'vue-toasted';
 
Vue.use(Toasted)

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "home",
        component: Home,
        meta: { requiresAuth: true }
	},
    {
		path: "/delete",
		name: "delete",
        component: DeleteAccount,
        meta: { requiresAuth: false }
	},
	{
		path: "/buildings",
		name: "buildings",
        component: Buildings,
        meta: { requiresAuth: true }
	},
	{
		path: "/building/:id/:tab?",
		name: "building",
        component: Building,
        meta: { requiresAuth: true }
    },
    // {
	// 	path: "/settings",
	// 	name: "settings",
    //     component: Settings,
    //     meta: { requiresAuth: true }
    // },
    {
		path: "/login",
		name: "login",
        component: Login,
        meta: { requiresAuth: false }
	},
    {
        path: "/signup",
        name: "signup",
        component: Signup,
        meta: { requiresAuth: false }
    },
    {
        path: "/forgot",
        name: "ForgotPasssword",
        component: ForgotPasssword,
        meta: { requiresAuth: false }
    }
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) { next({ path: '/login' }) }
            else { next() }
        })
    }
    else if (!to.matched.some(record => record.meta.requiresAuth)) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user && to.name ==='login') { next({ path: '/' }) }
            else { next() }
        })
    }
    else { next() }
});

export default router