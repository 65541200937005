
export const balconyKeys = [
  {
    key: 'projecting',
    source: 'pos1_projecting.png',
    helpText: 'balcony_position_projecting'
  },
  {
    key: 'partly_integrated',
    source: 'pos2_party-integrated.png',
    helpText: 'balcony_position_partly_integrated'
  },
  {
    key: 'fully_integrated',
    source: 'pos3_fully-integrated.png',
    helpText: 'balcony_position_fully_integrated'
  },
  {
    key: 'concrete_bonded',
    source: 'cons1_concrete-bonded.png',
    helpText: 'balcony_construction_concrete_bonded'
  },
  {
    key: 'cement_slab',
    source: 'cons2_cement-slab.png',
    helpText: 'balcony_construction_cement_slab'
  },
  {
    key: 'light_weight',
    source: 'cons3_light-weight.png',
    helpText: 'balcony_construction_light_weight'
  },
  {
    key: 'cantilever_slab',
    source: 'fix1_cantilever-slab.png',
    helpText: 'balcony_fixation_cantilever_slab'
  },
  {
    key: 'console',
    source: 'fix2_console.png',
    helpText: 'balcony_fixation_console'
  },
  {
    key: 'exterior_support',
    source: 'fix3_exterior-support.png',
    helpText: 'balcony_fixation_exterior_support'
  },
  {
    key: 'free_standing',
    source: 'fix4_free-standing.png',
    helpText: 'balcony_fixation_free_standing'
  }
];
