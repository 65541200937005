<template>
	<i v-if="type === 'material'" :class="type" class="material-chill-icons">{{ value }}</i>
	<i v-else :class="type" class="chill-icons">{{ value }}</i>
</template>

<script>
    export default {
        name: "chillIcon",
        props: {
            value: String,
            type: String
        }
    }
</script>