<template>
<div class="chart-container" ref="container">
    <div :ref="id" class="chart"></div>
</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'lineBarChart',
    props: ['series', 'label', 'id'],
    data(){
        return {
            chart: undefined,
            chartId: null,
            option: {
                title: {
                    text: this.$props.label,
                    left: 'left'
                },
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    top: '8%',
                    bottom: '12%'
                },
                xAxis: {},
                yAxis: {},
                series: [
                    {
                        type: 'line',
                        label: {
                            show:true,
                            position:'right',
                            formatter: function (param) {
                                return param.data[3];
                            },
                        },
                        symbolSize: 14,
                        itemStyle: {
                            color: 'orange',
                            borderWidth:4
                        },
                        lineStyle: {
                            width:3,
                            type: 'dashed'
                        },
                        data: [],
                        tooltip: {
                            show: true,
                            formatter: function (param) {
                                if(param.data[2]) return param.data[2];
                            },
                            position: 'right',
                            fontSize: '16px'
                        },
                        emphasis: {
                            scale: 1.5,
                        },
                    }
                ]
            }
        }
    },
    methods: {
        createChart(){
            let chartDom = this.$refs[this.$props.id]
            this.chart = echarts.init(chartDom)
            let data = []
            this.$props.series.data.forEach(e => {
                data.push(e)
            })
            this.option.series[0].data = data
            this.option.xAxis = this.$props.series.xAxis
            this.option.yAxis = this.$props.series.yAxis;
            (this.$props.series.step) ? this.option.series[0].step = this.$props.series.step : '';
            (this.$props.series.label) ? this.option.series[0].label = this.$props.series.label : ''
            this.option && this.chart.setOption(this.option);
        }
    },
    mounted(){
        this.createChart()
    },
}
</script>

<style lang="scss" scoped>
.chart {
    width:100%;
    height:100%;
}

.chart-container {
    margin-top:2rem;
    width:100%;
    height:600px;
    position:relative;
    .info-container {
        position:absolute;
        top:15%;
        left:15%;
        height:100px;
    }
}
</style>