<template>
    <div class="chill-footer">
        <div class="chill-footer-content">
            <div class="footer-item">
                <span>
                    <chillText type="grayText" value="capsa_is_a_brand_of_chillservices" /> <br />
                    <chillText type="grayText" value="for_more_info_please_visit_our" /> <a selenium="linkToHomePage" class="hyperlink" target="_blank" href="https://capsa-building.com/" ><chillText value="website" /></a> <br />
                    <chillText type="grayText" value="all_rights_reserved" /> <chillText type="grayText" value="2024" />
                </span>
            </div>
            <div class="footer-item">
                <img class="logo" src="@/assets/img/logo_haus_york-removebg-preview.png" alt="building-logo">
                <!-- <chillText class="text-logo" value="capsa" /> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'chillFooter',
        data() {
            return {
            }
        },

    }
</script>