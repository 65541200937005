<template>
	<span :class="type ? type : ''">{{ format(value) }}</span>
</template>

<script>
    import moment from "moment"

    import sv from "@/locale/sv.json"
    import en from "@/locale/en.json"
    import de from "@/locale/de.json"
    import es from "@/locale/es.json"
    import nl from "@/locale/nl.json"
    import it from "@/locale/it.json"
    import fr from "@/locale/fr.json"
    import dev from "@/locale/dev.json"

    export default {
        name: "chillText",
        props: ["value", "shorten", "title", "type", "textType"],
        data() {
            return {
                translations: {
                    sv: sv,
                    en: en,
                    de: de,
                    es: es,
                    nl: nl,
                    it: it,
                    fr: fr,
                    dev:dev
                },
                width: 800,
                textShorten: false,
                // langKeys: ["firebase_keys", "firebase_keys_info", "language", "firebase_options", "header", "register", "balcony", "facade", "window", "button", "distance", "drawer_menu", "form", "waiting_approval", "error", "wall", "network"   ]
            }
        },
        created() {
            window.addEventListener("resize", this.handleResize)
            this.width = window.innerWidth
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize)
        },
		watch: {
			
		},
        methods: {
            format(str) {
                let language = this.$store.state.lang ? this.$store.state.lang : 'en'
                if(language === "dev"){
                    return str
                }
                if (this.width < 767.98 )
                    this.textShorten = 24

                if (typeof str == "number") {
                    if (str > 1000000000000 && str < 2000000000000) {
                        if (this.shorten && this.shorten == "time_ago")
                            str = moment(str)
                                .locale(language)
                                .fromNow()
                        else if (
                            (this.shorten && this.shorten < 18) ||
                            this.width < 767.98
                        )
                            str = moment(str)
                                .locale(language)
                                .format("L")
                        else
                            str = moment(str)
                                .locale(language)
                                .format("LLLL")
                    }
                }
                else {
                    if (str === undefined && this.type == 'boolean') {str = "not_entered_yet"}
                    else if (str === undefined) {str = "not_entered_yet"}
                    if (str === false) str = "false"
                    if (str === true) str = "true"
                    if (str === null) str = "---"

                    if (typeof str === 'object') str = "undefined"

                    let strLower = str//.toLowerCase()

                    if ( this.translations[language]['ba_web'][strLower] && this.translations[language]['ba_web'][strLower] !== "" )
                        str = this.translations[language]['ba_web'][strLower]
                    
                    else if ( this.translations[language][strLower] && this.translations[language][strLower] !== "" )
                        str = this.translations[language][strLower]

                    else if ( this.textType && this.translations[language][this.textType][strLower] && this.translations[language][this.typeText][strLower] !== "" )
                        str = this.translations[language][this.textType][strLower]

                    else if ( this.translations[language]['firebase_keys'][strLower] && this.translations[language]['firebase_keys'][strLower] !== "" )
                        str = this.translations[language]['firebase_keys'][strLower]

                    else if ( this.translations[language]['firebase_keys_info'][strLower] && this.translations[language]['firebase_keys_info'][strLower] !== "" )
                        str = this.translations[language]['firebase_keys_info'][strLower]

                    else if ( this.translations[language]['language'][strLower] && this.translations[language]['language'][strLower] !== "" && isNaN(strLower) )
                        str = this.translations[language]['language'][strLower]

                    else if ( this.translations[language]['firebase_options'][strLower] && this.translations[language]['firebase_options'][strLower] !== "" )
                        str = this.translations[language]['firebase_options'][strLower]

                    else if ( this.translations[language]['header'][strLower] && this.translations[language]['header'][strLower] !== "" )
                        str = this.translations[language]['header'][strLower]

                    else if ( this.translations[language]['register'][strLower] && this.translations[language]['register'][strLower] !== "" )
                        str = this.translations[language]['register'][strLower]

                    else if ( this.translations[language]['button'][strLower] && this.translations[language]['button'][strLower] !== "" )
                        str = this.translations[language]['button'][strLower]

                    else if ( this.translations[language]['form'][strLower] && this.translations[language]['form'][strLower] !== "" )
                        str = this.translations[language]['form'][strLower]

                    else if ( this.translations.en[strLower] && this.translations.en[strLower] !== "" )
                        str = this.translations.en[strLower]


                    if (str.length > this.textShorten)
                        str = str.substring(0, this.textShorten - 2) + "..."
                }
                return str
            }
        },
        mounted() {
            this.textShorten = this.shorten
        }
    }
</script>
