<template>
    <div class="input-group-enery-balance">
        <chillText v-if="title" class="title" :edit="edit" :value="title" type="title"  />
        <chillText :value="generatedValue" class="generated-value" type="keyValue" :class="{'line-through': isDifferent}"  />

        <chillTextField v-if="edit && type === 'text'" :edit="edit" :value="userValue" :type="type" @update="update" />
        <chillSelect v-else-if="edit && type === 'select'" :edit="edit" :value="userValue" :type="type" :options="options" @update="update" class="keyValue" />
        <chillTextField v-else-if="edit && type === 'number'" :edit="edit" :value="userValue" :type="type" @update="update" />
        <chillText v-else :value="userValue" type="keyValue" />

    </div>
</template>

<script>
    export default {
        name: 'inputGroupEnergyBalance',
        props: ["value", "generatedValue", "userValue", "type", "title", "edit", "options", "values", "category", "keyLocation"],
        computed: {
            isDifferent() {
                return this.userValue != null  && this.userValue !== '' && this.generatedValue !== this.userValue;
            }
        },
        methods: {
            update(newValue) {
                this.$emit("update", newValue, this.keyLocation, this.title)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input-group-enery-balance {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 33px;
        width: 100%;
        box-sizing: border-box;
    }
    .keyValue, .chill-text-field, .title, .generated-value {
        width: 50%;
        padding-left: 4px;
        box-sizing: border-box;
    }

    .generated-value {
        color: rgb(145, 145, 145);
        font-weight: lighter;
    }

    .line-through {
        text-decoration: line-through;
    }

    > :last-child {
        padding-left: 10px;
    }
</style>
