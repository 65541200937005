import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const authUser = {
	data() {
		return {
			auth: false,
			user: {},
            userLanguage: this.findUserLang(),
            fsLoadingAuth: false,
            fsUserObjekt: null
		}
    },
    watch: {
        user: function(newVal, oldVal) {
            if(oldVal && (newVal.language && oldVal.language) && newVal.language_web !== oldVal.language_web) {
				this.userLanguage = newVal.language_web
			}
        },
    },
	methods: {
        findUserLang() {
            if(localStorage.getItem('language_web') ) { return localStorage.getItem('language_web') }
            else if(this.user && this.user.language_web) {
				localStorage.setItem( 'language_web', this.user.language_web )
				return this.user.language_web
			}
            else { return 'en' }
        },
		login() {
            var vm = this
            firebase
                .auth()
                .signInWithEmailAndPassword(vm.email, vm.password)
                .then(response => {
                    return response.user.uid
                }).then(userId => {
                    firebase.firestore().collection('buildingapp').doc('core').collection('users').doc(userId).get().then((user) => {
                        // if(user.data().client == ""){
                        //     throw {code: "no_client"}
                        // }
                        localStorage.setItem( 'language_web', user.data().language_web ? user.data().language_web : 'en')
                        localStorage.setItem( 'client', user.data().client)
                        localStorage.setItem( 'firstName', user.data().firstName)
                    })
                })
                .catch((error) => {
                    console.log("error",error.code)
                    if(error.code == "auth/wrong-password"){
                        alert("wrong username or password")
                    }
                    vm.error = error
                })
		},
        async forgotPassword(email){
            await firebase.auth().sendPasswordResetEmail(email)
        },
        async request_account_delete(email){
            let database = firebase.firestore().collection("request_delete")
            await database.add({
                email: email,
                requested_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
            return true
        },
		logout() {
            var vm = this
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear()
                    location.reload();
                })
                .catch(error => {
                    vm.error = error
                })
        },
        setLanguage: function(val) {
            this.userLanguage = val.language_web
            localStorage.setItem( 'language_web', val.language_web )
		},
		authCheck() {
            var vm = this
			firebase.auth().onAuthStateChanged(function(user) {
				if (user !== null) {
					vm.readUser()
					vm.auth = true
				} else {
					vm.user = false
					vm.auth = false
				}
			})
        },
        signup(){
            var vm = this
            firebase.auth().createUserWithEmailAndPassword(
                vm.email,
                vm.password
            ).then(response => {
                console.log("uid:", response.user.uid)
                console.log("email:", response.user.email)
                // TODO TRY OUT!
                firebase.firestore().collection('buildingapp').doc('core').collection('users').doc(response.user.uid).set({
                    client: "",
                    email: response.user.email,
                    firstName: "",
                    lastName: "",
                    id: response.user.uid
                })
            })
        },
        getUserId(uid = firebase.auth().currentUser.uid) {
            return uid
        },
        getUser() {
            firebase.firestore().collection("buildingapp").doc("core").collection("users").doc(this.getUserId()).onSnapshot(user => {
                this.fsUserObjekt = user.data()
            })
        },
        updateUser(userId, keyName, newValue) {
            let database = firebase.firestore().collection("buildingapp").doc("core").collection("users").doc(userId)
            database.update({
                [keyName]: newValue
            })
            .then(function() {
                localStorage.setItem( 'language_web', newValue)
                console.log("Document successfully written!");
            })
        },
        readUser: function() {
			var vm = this
            let database = firebase.firestore().collection("buildingapp").doc("core").collection("users").doc(this.getUserId())
            database.onSnapshot(snapshot => {
                if(snapshot.data() !== null || !snapshot.data() || snapshot.data() !== '' || snapshot.data() !== undefined)
					vm.user = snapshot.data()
					vm.setLanguage(vm.user)
				})
        }
    }
}
